<template>
  <div class="ubossHtmlContent" >

    <div class="text-center">
      <a role="button" class="btn btn-primary" href="#" @click="showDialog()">{{ propsWebPart.Title }}</a>
    </div>

    <DLGContactMe ref="dlgEditor" v-on:returnOk="dlgEditorReturnOK"/>


  </div>

</template>

<script>


import DLGContactMe from "@/pages/featureOnePage/webParts/callToAction/DLGContactMe.vue";

export default {
  components: {DLGContactMe},
  props: {
    webPart: {},
  },
  data: function() {
    return {
      /** @type {JWebPartHtml} */
      propsWebPart:this.webPart
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    showDialog(){
      // this.$refs.dlgEditor.callbackItem=item;
      // this.$refs.dlgEditor.htmlContent=item.HelpContent.Content;
      this.$refs.dlgEditor.showModal();
    },
    dlgEditorReturnOK(){
      // item.HelpContent.Content=this.$refs.dlgEditor.htmlContent;
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
