export default {
    /* View in fullscreen */
    openFullscreen() {
        var elem = document.documentElement;

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    },
    /* Close fullscreen */
    closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    },
    isInFullScreen(){
        if( (screen.availHeight || screen.height-30) <= window.innerHeight) {
            // browser is almost certainly fullscreen
        }
    },
    rotateBrowser(degrees){

        if (degrees===0) {
            // document.body.setAttribute( "style", "-webkit-transform: rotate(0deg);");
        }else{
            document.body.setAttribute( "style", "-webkit-transform: rotate(-"+degrees+"deg);");
        }

        // document.body.setAttribute( "style", "-webkit-transform: rotate(0deg);");
        // document.body.setAttribute( "style", "-webkit-transform: rotate(-90deg);");
        // document.body.setAttribute( "style", "-webkit-transform: rotate(-180deg);");
        // document.body.setAttribute( "style", "-webkit-transform: rotate(-270deg);");

        //firefox
        // javascript: document.body.setAttribute( "style", "-moz-transform: rotate(-90deg);");
        //opera
        // javascript: document.body.setAttribute( "style", "-o-transform: rotate(-90deg);");
    }
}
