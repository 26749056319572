<template>
  <div>
    <h1>mode 1</h1>
    <material-picker v-model="colors" />
    <h1>mode 2</h1>
    <compact-picker v-model="colors" />
    <h1>mode 3</h1>
    <swatches-picker v-model="colors" />
    <h1>mode 4</h1>
    <slider-picker v-model="colors" />
    <h1>mode 5</h1>
    <sketch-picker v-model="colors" />
    <h1>mode 6</h1>
    <chrome-picker v-model="colors" />
    <h1>mode 7</h1>
    <photoshop-picker v-model="colors" />

    <h1>mode 8</h1>
    <sketch-picker
        @input="updateValue"
        :value="colors"
        :preset-colors="[
    '#f00', '#00ff00', '#00ff0055', 'rgb(201, 76, 76)', 'rgba(0,0,255,1)', 'hsl(89, 43%, 51%)', 'hsla(89, 43%, 51%, 0.6)'
  ]"
    ></sketch-picker>

    <h1>mode 9</h1>
    <compact-picker
        @input="updateValue"
        :value="colors"
        :palette="[
    '#f00', '#00ff00', '#00ff0055', 'rgb(201, 76, 76)', 'rgba(0,0,255,1)', 'hsl(89, 43%, 51%)', 'hsla(89, 43%, 51%, 0.6)'
  ]"
    ></compact-picker>
  </div>
</template>

<script>
/**

 **/

import { Material } from 'vue-color'
import { Compact } from 'vue-color'
import { Swatches } from 'vue-color'
import { Slider } from 'vue-color'
import { Sketch } from 'vue-color'
import { Chrome } from 'vue-color'
import { Photoshop } from 'vue-color'


export default {
  components: {
    'material-picker': Material,
    'compact-picker': Compact,
    'swatches-picker': Swatches,
    'slider-picker': Slider,
    'sketch-picker': Sketch,
    'chrome-picker': Chrome,
    'photoshop-picker': Photoshop
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      propsValue: this.value,
      formData: {
        name: "",
      },
      colors:"",
      updateValue:"",
      color:{
        hex: '#194d33',
        hex8: '#194D33A8',
        hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
        hsv: { h: 150, s: 0.66, v: 0.30, a: 1 },
        rgba: { r: 25, g: 77, b: 51, a: 1 },
        a: 1
      }
    };
  },
  watch: {
    propsValue(newVal) {
      //this.('input', newVal);
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.propsValue = newVal
    }
  },
  methods: {
    //給予value屬性預設值
    initValue() {
      const isMyObjectEmpty = !Object.keys(this.value).length;

      if (isMyObjectEmpty) {
        let template = {...this.datasDemo};
        this.value = template;
      }
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    // this.initValue()
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
