<template>
  <div class="" >
    <div class="form-group row">
      <label for="" class="col-sm-2">表單連結</label>
      <div class="col-sm-10">
        <input type="text" class="form-control"  placeholder="" v-model="formLink">
      </div>
    </div>
  </div>

</template>

<script>

export default {
  components: {},
  props:["webPart"],
  data: function() {
    return {
      formLink:"",
    };
  },
  watch: {
    formLink: function () {
      this.webPart.formLink=this.formLink;

      let htmlTemplate=`<iframe frameborder="0" height="846" marginheight="0" marginwidth="0" src="${this.formLink}" width="100%">載入中&amp;hellip;</iframe>`;

      this.webPart.PartHtmlContent=htmlTemplate;
    },
  },
  methods: {
    async loadData(){
      this.formLink=this.webPart.formLink;
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    setting(){
      this.$refs.dialog.showModal();
    },
    blockWebPartSelectorReturnOk(){

    }
  },
  mounted() {
    this.loadData()
  },
}
</script>
