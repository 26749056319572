<template>
  <div class="utubePage" style="">
    <div style="width:97%;margin: 0 auto; ">
<!--    <div style="width:2500px;">-->
    <Splide :options="options" @splide:active="slide_active" @splide:inactive="slide_inactive" @splide:move="slide_move" @splide:moved="slide_moved">
      <!--        <SplideSlide data-splide-interval="6000">-->
      <!--&lt;!&ndash;          <div id="player"></div>&ndash;&gt;-->
      <!--&lt;!&ndash;          <youtube :video-id="hVmNVv83FEs" :player-vars="{autoplay: 1}" ></youtube>&ndash;&gt;-->
      <!--&lt;!&ndash;          <youtube :video-id="hVmNVv83FEs" player-width="100%" player-height="1000" :player-vars="{autoplay: 1}" ></youtube>&ndash;&gt;-->
      <!--&lt;!&ndash;          <youtube :video-id="yAmQJsCW1Bc" player-width="100%" player-height="1000" :player-vars="videoOptions" @ready="ready" @playing="playing"></youtube>&ndash;&gt;-->
      <!--          <youtube  video-id="hVmNVv83FEs"  :player-vars="videoOptions" @ready="ready" @playing="playing"></youtube>-->
      <!--          <img v-if="!showVideo" class="splideImage" src="https://via.placeholder.com/1920x1090" >-->
      <!--        </SplideSlide>-->
      <!--        <SplideSlide data-splide-interval="6000">-->
      <!--          <img class="splideImage" src="https://via.placeholder.com/1920x1080" >-->
      <!--        </SplideSlide>-->
      <!--                  <SplideSlide data-splide-interval="6000" v-for="(item,index) in this.formData.MediaList" :key="index">-->
      <!--                    <img class="splideImage" src="https://via.placeholder.com/1920x1080" >-->
      <!--                  </SplideSlide>-->

      <SplideSlide :data-splide-interval="item.PlaySettingImagePlaySec * 1000" v-for="(item,index) in this.formData.MediaList.Items" :key="index" >
        <img v-if="item.MediaType===$u.UTubeMediaTypeEnum_ImageUpload" class="splideImage" :src="toDownloadLink(item.MediaUrl)"  >
        <img v-else-if="item.MediaType===$u.UTubeMediaTypeEnum_ImageUrl" class="splideImage" :src="item.MediaUrl" >
        <youtube v-else-if="item.MediaType===$u.UTubeMediaTypeEnum_VideoUrl"  :ref="'youtubePlayer'+index" :video-id="getIdFromURL(item.MediaUrl)"  :playerVars="videoOptions" @ready="ready" @playing="playing" fitParent="true" ></youtube>
<!--        &lt;!&ndash;            <img class="splideImage" :src="item.MediaUrl" >&ndash;&gt;-->
<!--        <span v-if="item.MediaType===$u.UTubeMediaTypeEnum_ImageUpload">-->
<!--                <img class="splideImage" :src="toDownloadLink(item.MediaUrl)"  >-->
<!--            </span>-->
<!--        <span v-else-if="item.MediaType===$u.UTubeMediaTypeEnum_ImageUrl">-->
<!--                <img class="splideImage" :src="item.MediaUrl" >-->
<!--            </span>-->
<!--        <span v-else-if="item.MediaType===$u.UTubeMediaTypeEnum_VideoUrl" style="width: 100%">-->
<!--                &lt;!&ndash;          <youtube :video-id="hVmNVv83FEs" :player-vars="{autoplay: 1}" ></youtube>&ndash;&gt;-->
<!--          &lt;!&ndash;          <youtube :video-id="hVmNVv83FEs" player-width="100%" player-height="1000" :player-vars="{autoplay: 1}" ></youtube>&ndash;&gt;-->
<!--          &lt;!&ndash;          <youtube :video-id="yAmQJsCW1Bc" player-width="100%" player-height="1000" :player-vars="videoOptions" @ready="ready" @playing="playing"></youtube>&ndash;&gt;-->
<!--                <youtube  :ref="'youtubePlayer'+index" :video-id="getIdFromURL(item.MediaUrl)"  :playerVars="videoOptions" @ready="ready" @playing="playing" fitParent="true" ></youtube>-->
<!--          &lt;!&ndash;              <img v-if="!showVideo" class="splideImage" src="https://via.placeholder.com/1920x1090" >&ndash;&gt;-->
<!--            </span>-->
<!--        <span v-else>-->
<!--              XX:{{item.MediaType}}-->
<!--            </span>-->
      </SplideSlide>

    </Splide>
    </div>
    <MyMarquee :speed="formData.MarqueeListUp.PlaySpeed" class="topBar" v-if="formData.MarqueeListUp.Items.length>0">
      <div class="marqueeContent">
        <!--          <span v-for="n in 10">  &lt;!&ndash;eslint-disable-line&ndash;&gt;-->
        <div style="height:auto;display:inline-block;" v-for="(item,index) in formData.MarqueeListUp.Items" :style="item.MarqueeStyleCSS" :key="index">
          {{item.MarqueeText}}
        </div>
        <!--          </span>-->
        <!--          <div style="height:auto;font-size:30px;display:inline-block;">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</div>-->
        <!--          <div style="height:auto;font-size:40px;background-color:red;display:inline-block;">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</div>-->
        <!--          <div style="height:auto;font-size:50px;display:inline-block;">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</div>-->
      </div>
    </MyMarquee>

    <div class="marqueeContent osdBar demotext" style="font-size:80px;color:rgb(0,255,0);text-align: right" >
      osdxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    </div>

    <MyMarquee :speed="formData.MarqueeListDown.PlaySpeed" class="bottomBar" v-if="formData.MarqueeListDown.Items.length>0">
      <div class="marqueeContent">

        <!--          <span v-for="n in 10">  &lt;!&ndash;eslint-disable-line&ndash;&gt;-->
        <div style="height:auto;display:inline-block;" v-for="(item,index) in formData.MarqueeListDown.Items" :style="item.MarqueeStyleCSS" :key="index">
          {{item.MarqueeText}}
        </div>
        <!--          </span>-->


        <!--          <div style="height:auto;font-size:30px;display:inline-block;">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</div>-->
        <!--          <div style="height:auto;font-size:40px;background-color:red;display:inline-block;">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</div>-->
        <!--          <div style="height:auto;font-size:50px;display:inline-block;">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</div>-->
      </div>
    </MyMarquee>

  </div>
</template>

<script>
import MyMarquee from "@/pages/commonComponent/MyMarquee";
import { Splide,SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
// import { Video } from '@splidejs/splide-extension-video';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
import $ from "jquery"
import _ from "lodash"
/**

 **/

// import MarqueeText from 'vue-marquee-text-component'
// import { VTextMarquee } from 'vue-text-marquee';

import apiUBossUTube from "@/pages/featureOnePage/util/apiUBossUTube";
import utilBrowser from "@/pages/common/utilBrowser";
import { getIdFromURL } from 'vue-youtube-embed'
import apiServerUtil from "@/pages/common/apiServerUtil";

export default {
  components: {MyMarquee,Splide,SplideSlide},
  // components: {VTextMarquee},
  //name: "utube",
  // props: [""],
  data: function () {
    return {
      systemId: "",
      showVideo:false,
      dataReady: false,
      slideLoopCount:0,
      videos : [ '3GNQL3alB-Y', 'xLJ2QQDrN9k', 'cdz__ojQOuU', 'oS6N_ZBFDZE' ],
      videoOptions:{
        autoplay: 1,
        loop:1
      },
      options: {
        rewind: true,
        // width: 1300,
        // width: '3840',
        width: '110%',
        // fixedWidth: '3840px',
        // autoWidth:true,
        gap: '3rem',
        perPage: 1,
        perMove: 1,
        // type: 'loop',
        // type: 'slide',
        type: 'fade',
        autoplay: true,
        pauseOnHover: false,
        resetProgress: false,
        focus: 'center',
        pagination:false,
        interval:10000,
        // arrows      : 'slider',
      },
      optionsRef: {
        // rewind: true,
        width: 1300,
        gap: '3rem',
        perPage: 3,
        perMove: 1,
        type: 'loop',
        autoplay: true,
        pauseOnHover: false,
        resetProgress: false,
        focus: 'center',
        fixedWidth: '400px',
        // arrows      : 'slider',
        breakpoints: {
          '1440': {
            width: 900,
            perPage: 3,
            gap: '2rem'
          },
          '900': {
            width: 600,
            perPage: 2,
            gap: '2rem'
          },
          '600': {
            width: 400,
            perPage: 1,
            gap: '2rem'
          },
          '480': {
            width: 360,
            perPage: 1,
            gap: '1rem'
          }
        }
      },
      slideCard: [
        {
          imgSrc: "https://via.placeholder.com/1080",
          link:"https://docs.google.com/forms/d/e/1FAIpQLScb_JVac9x9zR9_LieNde1ap6VT9Messef4RYMzouvkKDdhuA/viewform"
        },
        {
          imgSrc: "https://via.placeholder.com/1080",
          link:"https://docs.google.com/forms/d/e/1FAIpQLScb_JVac9x9zR9_LieNde1ap6VT9Messef4RYMzouvkKDdhuA/viewform"
        },

        // {
        //   imgSrc: require('../assets/images/配配飲_LINE最新消息400k.jpg'),
        //   link:"#"
        // },
        // {
        //   imgSrc: require('../assets/images/配配飲_註冊會員400k.jpg'),
        //   link:"#"
        // },
      ],
      formData: {

      },
      formDataDemo: {
        SystemId: "",
        Name: "",
        RotateAngle:0,
        MediaList:{
          SystemId:"",
          MediaPlayType:2,
          Items:[
            {
              SystemId: "",
              OrderKey:"",
              IsEnable:true,
              MediaType:1,
              MediaUrl:"https://via.placeholder.com/1920x1080",
              PlaySettingImagePlaySec:10,
              PlaySettingVideoStartMin:0,
              PlaySettingVideoStartSec:0,
              PlaySettingVideoEndMin:0,
              PlaySettingVideoEndSec:0,
              Memo:"",
            },
            {
              SystemId: "",
              OrderKey:"",
              IsEnable:true,
              MediaType:3,
              MediaUrl:"https://www.youtube.com/watch?v=SMEY0viUSCc",
              PlaySettingImagePlaySec:50,
              PlaySettingVideoStartMin:0,
              PlaySettingVideoStartSec:0,
              PlaySettingVideoEndMin:0,
              PlaySettingVideoEndSec:0,
              Memo:"",
            },
            {
              SystemId: "",
              OrderKey:"",
              IsEnable:true,
              MediaType:3,
              MediaUrl:"https://www.youtube.com/watch?v=kM0Hla7GjVA",
              PlaySettingImagePlaySec:50,
              PlaySettingVideoStartMin:0,
              PlaySettingVideoStartSec:0,
              PlaySettingVideoEndMin:0,
              PlaySettingVideoEndSec:0,
              Memo:"",
            },
            // {
            //   SystemId: "",
            //   OrderKey:"",
            //   IsEnable:true,
            //   MediaType:1,
            //   MediaUrl:"https://via.placeholder.com/1920x1079",
            //   PlaySettingImagePlaySec:0,
            //   PlaySettingVideoStartMin:0,
            //   PlaySettingVideoStartSec:0,
            //   PlaySettingVideoEndMin:0,
            //   PlaySettingVideoEndSec:0,
            //   Memo:"",
            // },
            // {
            //   SystemId: "",
            //   OrderKey:"",
            //   IsEnable:true,
            //   MediaType:1,
            //   MediaUrl:"https://via.placeholder.com/1920x1078",
            //   PlaySettingImagePlaySec:0,
            //   PlaySettingVideoStartMin:0,
            //   PlaySettingVideoStartSec:0,
            //   PlaySettingVideoEndMin:0,
            //   PlaySettingVideoEndSec:0,
            //   Memo:"",
            // },
          ],
        },
        MarqueeListUp:{
          SystemId:"",
          MediaPlayType:1,
          Items:[
            {
              SystemId: "",
              OrderKey:"",
              IsEnable:true,
              MarqueeText:"1010 特惠xxxxxxxxxxxxxxxxxxxx",
              MarqueeStyleCSS:"",
              Memo:""
            },
          ]
        },
        MarqueeListDown:{
          SystemId:"",
          MediaPlayType:1,
          Items:[
            {
              SystemId: "",
              OrderKey:"",
              IsEnable:true,
              MarqueeText:"1010 特惠xxxxxxxxxxxxxxxxxxxx",
              MarqueeStyleCSS:"",
              Memo:""
            },
            {
              SystemId: "",
              OrderKey:"",
              IsEnable:true,
              MarqueeText:"2020 特惠yyyyyyyyyyyyyyyyyyyy",
              MarqueeStyleCSS:"",
              Memo:""
            },
            {
              SystemId: "",
              OrderKey:"",
              IsEnable:true,
              MarqueeText:"2030 特惠zzzzzzzzzz",
              MarqueeStyleCSS:"",
              Memo:""
            },
          ]
        },
      },
    };
  },
  methods: {
    async loadData() {
      // debugger // eslint-disable-line
      // this.player.loadVideoById({videoId:"47NRaBVxgVM",
      //   startSeconds:"30",
      //   endSeconds:"45"});



      let data=await apiUBossUTube.api_UTubeChannel_Get(this.systemId)

      this.formData=data

      // utilBrowser.openFullscreen();
      utilBrowser.rotateBrowser(this.formData.RotateAngle);

    },
    ready (event) { // eslint-disable-line
      // debugger // eslint-disable-line
      // this.player = event.target
      // this.player.loadVideoBUrl({mediaContentUrl:"https://www.youtube.com/watch?v=47NRaBVxgVM",
      //   startSeconds:"30",
      //   endSeconds:"45"});

      // this.player.setPlaybackQuality("hd720");
      // this.player.playVideo();
      // this.playFullscreen();
      // this.player.seekTo(30);
      console.log("ready");
    },
    playing (event) { // eslint-disable-line
      // The player is playing a video.
      // this.showVideo=true;
      console.log("playing");
    },
    change () {
      // when you change the value, the player will also change.
      // If you would like to change `playerVars`, please change it before you change `videoId`.
      // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
      // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
      // this.videoId = 'another video id'
      console.log("change");
    },
    stop () {
      // this.player.stopVideo()
    },
    pause () {
      // this.player.pauseVideo()
    },
    playFullscreen (){
      this.player.playVideo();//won't work on mobile

      let iframe=$('#youtube-player-3');

      var requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen;
      if (requestFullScreen) {
        requestFullScreen.bind(iframe)();
      }
    },
    slide_active(slideObject){
      console.log("active " +slideObject );

      let that=this;
      this.formData.MediaList.Items.forEach((item,index)=>{
        if (item.MediaType===that.$u.UTubeMediaTypeEnum_VideoUrl){
          if (index===slideObject.index){
            let youtubePlayer= that.$refs['youtubePlayer' +index]
            if (youtubePlayer!==undefined){
              console.log("play video");
              youtubePlayer[0].player.seekTo(0, true)
              youtubePlayer[0].player.playVideo()
            }
          }else{
            let youtubePlayer= that.$refs['youtubePlayer' +index]
            if (youtubePlayer!==undefined){
              console.log("pause video");
              youtubePlayer[0].player.pauseVideo()
            }
          }
        }
      })
    },
    slide_inactive(slideObject){
      console.log("inactive " +slideObject );
    },
    slide_move(slideObject,newIndex,prevIndex,destIndex){
      console.log("move " +slideObject + "  " +newIndex + " " + prevIndex  + " "+destIndex);
    },
    slide_moved(slideObject,newIndex,prevIndex,destIndex) {
      console.log("moved " + slideObject + "  " + newIndex + " " + prevIndex + " " + destIndex);
      console.log("moved " + this.slideLoopCount);

      let iRefreshLoopCount = 2;

      if (iRefreshLoopCount > 0) {
        if (destIndex === slideObject.length - 1) {

          //-------------打亂廣告內容--------------------------
          if (this.formData.MediaList.MediaPlayType === this.$u.UTubeMediaPlayTypeEnum_Sequence) {
            // eslint-disable-line
          } else if (this.formData.MediaList.MediaPlayType === this.$u.UTubeMediaPlayTypeEnum_Random)
            this.formData.MediaList.Items = _.shuffle(this.formData.MediaList.Items);
          console.log("rnd");
        } else {
          // eslint-disable-line
        }
        //---------------------------------------

        this.slideLoopCount = this.slideLoopCount + 1;
      }

      if (this.slideLoopCount === iRefreshLoopCount) {

        //--------------重新下載頻道內容-------------------------
        console.log("refresh");

        //---------------------------------------

        this.slideLoopCount = 0;
      }
    },
    getIdFromURL(url) {
      let videoId = getIdFromURL(url)

      return videoId;
    },
    toDownloadLink(imageFileUrl){
      return apiServerUtil.toDownloadLink(imageFileUrl);
    },
    btnTest1(){
      utilBrowser.openFullscreen();
    }
  },
  created() {
    switch (this.$route.name) {
      case "channel1":
        this.systemId = "9894a515-fb04-4045-af1a-44e0008638e0";
        break;
      case "channel2":
        this.systemId = "2d6229a9-5784-4dd2-ab6c-78adc06ecb11";
        break;
      case "channel3":
        this.systemId = "941dc53f-0d40-49ae-bc11-13bd292d7a10";
        break;
      case "channel4":
        this.systemId = "96f470f2-0beb-4c84-a705-a85ec5571faf";
        break;
      case "channel5":
        this.systemId = "e3ece0fe-7ce1-4d9b-a894-77a3245ea6ef";
        break;
      default:
        this.systemId = this.$route.query.id
        break;
    }
    this.loadData();
  },
  mounted() {

  }
}
</script>

<style scoped>
.utubePage{
  /*margin-top:-52px !important;  !*  因為body上的path-content有設定padding-top:52 這裡抵消回去*!*/
  /*background-color: black;*/
  /*position: relative;*/
  /*width: 100%;*/
  /*height: 0;*/
  /*padding-bottom: 56.25%;*/
}
.topBar{
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
}
.osdBar{
  position: fixed;
  top: 75px;  /*hardcode*/
  right: 0px;
  width: 100%;
}
.marqueeContent{
  height: 75px;/*hardcode*/
  display:inline-block;
  line-height: 75px;
  text-align: center;
}
/*會隨著影片或圖片的高度調整*/
.bottomBarSticky{
  position: sticky;
  bottom: 0px;
  right: 0px;
  width: 100%;
}
.bottomBar{
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  /*height:52px;*/
}

.splideImage{
  /*width: 100%;*/
  width:100%;
  /*height:auto;*/
  /*max-width:100%;*/
  /*max-height:100%;*/
}

.demotext {
  /*color: #FFFFFF;*/
  /*background: #FFFFFF;*/
  text-shadow: 1px 1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, -1px -1px 0 #000000, 1px 0px 0 #000000, 0px 1px 0 #000000, -1px 0px 0 #000000, 0px -1px 0 #000000;
  /*color: #FFFFFF;*/
  /*background: #FFFFFF;*/
}



iframe {
  width: 100%;
  max-width: 650px; /* Also helpful. Optional. */
}
</style>
