<template>

  <div id="slideshow">
      <splide :options="options">
        <splide-slide
            v-for="(image, index) in propsWebPart.PartImages"
            :key="index"
        >
          <!--        class="flex justify-center items-center rounded-3xl overflow-hidden bg-white shadow-xl mb-10"-->
          <a :href="image.LinkUrl" :target="image.LinkTarget" >
            <img :src="toDownloadLink(false,image.ImageUrl)" />
          </a>
        </splide-slide>
      </splide>

<!--    &lt;!&ndash;mobile&ndash;&gt;-->
<!--    <div class="d-block d-sm-none">-->
<!--      <splide :options="options">-->
<!--        <splide-slide-->
<!--            v-for="(image, index) in propsWebPart.PartImages"-->
<!--            :key="index"-->
<!--        >-->
<!--  &lt;!&ndash;        class="flex justify-center items-center rounded-3xl overflow-hidden bg-white shadow-xl mb-10"&ndash;&gt;-->
<!--          <a :href="image.LinkUrl" :target="image.LinkTarget" >-->
<!--            <img :src="toDownloadLink(true,image.ImageUrl)" />-->
<!--          </a>-->
<!--        </splide-slide>-->
<!--      </splide>-->
<!--    </div>-->

<!--    &lt;!&ndash;desktop&ndash;&gt;-->
<!--    <div class="d-none d-sm-block">-->
<!--      <splide :options="options">-->
<!--        <splide-slide-->
<!--            v-for="(image, index) in propsWebPart.PartImages"-->
<!--            :key="index"-->
<!--        >-->
<!--          &lt;!&ndash;        class="flex justify-center items-center rounded-3xl overflow-hidden bg-white shadow-xl mb-10"&ndash;&gt;-->
<!--          <a :href="image.LinkUrl" :target="image.LinkTarget" >-->
<!--            <img :src="toDownloadLink(false,image.ImageUrl)" />-->
<!--          </a>-->
<!--        </splide-slide>-->
<!--      </splide>-->
<!--    </div>-->
<!--    &lt;!&ndash;mobile&ndash;&gt;-->
<!--    <div class="d-block d-sm-none">-->
<!--      <b-carousel-->
<!--          ref="carousel"-->
<!--          :interval="5000"-->
<!--          controls-->
<!--          indicators-->
<!--          background="#ababab"-->
<!--          img-width="1024"-->
<!--          style="text-shadow: 1px 1px 2px #333;"-->
<!--      >-->
<!--        <b-carousel-slide v-for="(image,index) in propsWebPart.PartImages" :key="index" :img-src="toDownloadLink(true,image.ImageUrl)"  :data-interval="image.Interval.makeSureNumber(5) * 1000"></b-carousel-slide>-->
<!--      </b-carousel>-->

<!--    </div>-->

<!--    &lt;!&ndash;desktop&ndash;&gt;-->
<!--    <div class="d-none d-sm-block">-->
<!--      <b-carousel-->
<!--          ref="carousel"-->
<!--          :interval="5000"-->
<!--          controls-->
<!--          indicators-->
<!--          background="#ababab"-->
<!--          img-width="1024"-->
<!--          style="text-shadow: 1px 1px 2px #333;" @sliding-end="slidingEnd"-->
<!--      >-->
<!--        <b-carousel-slide v-for="(image,index) in propsWebPart.PartImages" :key="index" :img-src="toDownloadLink(false,image.ImageUrl)" :data-interval="image.Interval.makeSureNumber(5) * 1000"></b-carousel-slide>-->
<!--      </b-carousel>-->

<!--    </div>-->


<!--    <div id="carousel2_indicator" class="carousel slide carousel-fade" data-ride="carousel">-->
<!--      <div class="carousel-inner">-->
<!--          <div class="carousel-item"  v-for="(image,index) in propsWebPart.PartImages" :key="index">-->
<!--            <img class="d-block w-100" :src="image.ImageUrl" :alt="index">-->
<!--          </div>-->

<!--      </div>-->

<!--&lt;!&ndash;      <div class="carousel-inner">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="carousel-item">&ndash;&gt;-->
<!--&lt;!&ndash;          <img class="d-block w-100" src="http://172.104.81.182:8080/ubossOnePage/_image_s/紹瑄一頁是廣告_前半-01_02_01.gif" alt="First slide">&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="carousel-item active">&ndash;&gt;-->
<!--&lt;!&ndash;          <img class="d-block w-100" src="http://172.104.81.182:8080/ubossOnePage/_image_s/紹瑄一頁是廣告_前半-01_02_02.gif" alt="Second slide">&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>      &ndash;&gt;-->
<!--      <a class="carousel-control-prev" href="#carousel2_indicator" role="button" data-slide="prev">-->
<!--        <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
<!--        <span class="sr-only">Previous</span>-->
<!--      </a>-->
<!--      <a class="carousel-control-next" href="#carousel2_indicator" role="button" data-slide="next">-->
<!--        <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
<!--        <span class="sr-only">Next</span>-->
<!--      </a>-->
<!--    </div>-->

  </div>

</template>

<script>

//QQ move to js
String.prototype.makeSureString = function(defaultValue)
{
  if (this===null){
    if (defaultValue===undefined){
      return "";
    }else{
      return defaultValue;
    }
  }
}

String.prototype.makeSureNumber = function(defaultValue)
{
  let value=this+"";

  if (isNaN(value) || value===""){
    if (defaultValue===undefined){
      return 0;
    }else{
      return defaultValue;
    }
  }else{
    return new Number(value)
  }
  //
  // if (this===null){
  //     if (defaultValue===undefined){
  //         return 0;
  //     }else{
  //         return defaultValue;
  //     }
  // }else{
  //     if (isNaN(this)){
  //         if (defaultValue===undefined){
  //             return 0;
  //         }else{
  //             return defaultValue;
  //         }
  //     }else{
  //         return new Number(this)
  //     }
  // }
}

// import util from "@/pages/common/util";
//
// import HelloWorld from '@/components/HelloWorld.vue'
//



import { Splide, SplideSlide } from '@splidejs/vue-splide'
import apiServerUtil from "@/pages/common/apiServerUtil";

export default {
  components: {Splide,SplideSlide},
  props: {
    webPart: {},
  },
  data: function() {
    return {
      /** @type {JWebPartSliderImage3} */
      propsWebPart: this.webPart,
      showThis:false,
      layoutType:1,
      formData:{
        name:"Sophie Wang",
      },
      submitted: false,
      options: {
        // rewind: true,
        width: 1300,
        gap: '3rem',
        perPage: 3,
        perMove: 1,
        type: 'loop',
        autoplay: true,
        pauseOnHover: false,
        resetProgress: false,
        focus: 'center',
        fixedWidth: '400px',
        // arrows      : 'slider',
        breakpoints: {
          '1440': {
            width: 900,
            perPage: 3,
            gap: '2rem'
          },
          '900': {
            width: 600,
            perPage: 2,
            gap: '2rem'
          },
          '600': {
            width: 400,
            perPage: 1,
            gap: '2rem'
          },
          '480': {
            width: 360,
            perPage: 1,
            gap: '1rem'
          }
        }
      },
      slideCard: [
        {
          imgSrc: "/img/配配飲_註冊會員400k.3b867f7d.jpg",
          link:"https://docs.google.com/forms/d/e/1FAIpQLScb_JVac9x9zR9_LieNde1ap6VT9Messef4RYMzouvkKDdhuA/viewform"
        },
        {
          imgSrc: "/img/配配飲_加盟說明會400k.8a84e74d.jpg",
          link:"https://docs.google.com/forms/d/e/1FAIpQLScb_JVac9x9zR9_LieNde1ap6VT9Messef4RYMzouvkKDdhuA/viewform"
        },

        // {
        //   imgSrc: require('../assets/images/配配飲_LINE最新消息400k.jpg'),
        //   link:"#"
        // },
        // {
        //   imgSrc: require('../assets/images/配配飲_註冊會員400k.jpg'),
        //   link:"#"
        // },
      ]
    };
  },
  methods: {
    async loadData(){
      // let data = onePageUtil.webPartSliderImage_Load(this.areaId,this.blockId);
      //
      // if (data){
      //   this.formData.url=data.content
      // }


    },
    toDownloadLink(isMobile,imageFileUrl){
      return apiServerUtil.getPicLinkOnePage_OrgSize(isMobile,imageFileUrl);
    },
    slidingEnd(slide){
      try {
        this.$refs.carousel.interval = this.$refs.carousel.slides[slide].dataset.interval
      } catch (e) {
        console.log(e);
      }

      // this.$refs.carousel.interval=this.$refs.carousel.interval+1000;
      // console.log(slide);
      // console.log(this.$refs.carousel.interval);
    }
  },
  created() {
  },
  mounted() {
    this.loadData()

  },
}
</script>
