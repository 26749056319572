<template>
  <span>



      <k-pop boundary="viewport" with-arrow theme="clean" ref="kpop">
        <a class="btn btn-primary" slot="trigger" ><i class="fa fa-ellipsis-v"></i></a>

        <template #default="{hide}">
          <a class="btn btn-primary" @click="OpenDialog(field)" ><i class="far fa fa-cog"></i></a>
          <a class="btn btn-danger" @click="DataItem_Remove(field)"><i class="far fa-trash-alt"></i></a>
          <a class="btn btn-primary" @click="DataItem_MoveUp(field)"><i class="fa fa-angle-up"></i> </a>
          <a class="btn btn-primary" @click="DataItem_MoveDown(field)"><i class="fa fa-angle-down"></i> </a>

          <small @click="hide" style="position:absolute;top:0;right:0;"><span class="fa fa-times-circle"></span></small>
        </template>

      </k-pop>

    <FormFieldDesignerDialog ref="formDeisngerDialog" v-on:returnOk="formDeisngerDialogReturnOk" v-on:closeModel="formDeisngerDialogCloseModel"></FormFieldDesignerDialog>

  </span>
</template>

<script>

import arrayUtil from "@/pages/common/arrayUtil";

import FormFieldDesignerDialog from "./formField/FormFieldDesignerDialog";

export default {
  //name: "WebPartFormDesignerToolbar",
  props: ["webPart","field"],
  components:{ FormFieldDesignerDialog},
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    DataItem_Setting(item){
      arrayUtil.orderMove(false,item.Id,this.webPart.Fields,"Id","OrderKey")
    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.Id,this.webPart.Fields,"Id","OrderKey")
      this.$refs.kpop.hide();
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.Id,this.webPart.Fields,"Id","OrderKey")
      this.$refs.kpop.hide();
    },

    // DataItem_Insert(currentItem){
    //   let newItem={
    //     Id:util.uuid(),
    //     Year:0,
    //     Moneth:0,
    //     Type:"",
    //     Rate:0,
    //     OrderKey:0,
    //   }
    //
    //   arrayUtil.orderInsert(newItem,currentItem,this.webPart.Fields,"Id","OrderKey")
    // },
    DataItem_Remove(item){
      this.webPart.Fields= this.webPart.Fields.filter(s => s.Id !==item.Id);
      this.$refs.kpop.hide();
    },
    OpenDialog(item){
      this.$refs.formDeisngerDialog.formItem=item;
      this.$refs.formDeisngerDialog.showModal()
    },
    formDeisngerDialogReturnOk(){
      this.$refs.kpop.hide();
    },
    formDeisngerDialogCloseModel(){
      this.$refs.kpop.hide();
    }
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>
.btn{
  color: white !important;
  margin: 5px;
  /*width: 40px;*/
}
</style>
