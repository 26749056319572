import Vue from 'vue'
import VueRouter from 'vue-router'
// import Index from '../views/Index.vue'
import Index from '../views/Index.vue'
import Index2 from '../views/Index2.vue'
import IndexOnePagePro from '../views/IndexOnePagePro.vue'
import IndexForm from '../views/IndexForm.vue'
import ShoppingCart from '../views/ShoppingCart.vue'
import Login from '../views/Login.vue'
import AccountIndex from '../../featureAccounting/AccountIndex.vue'
import AccountIndexM from '../../featureAccounting/AccountIndexM.vue'
import AccountingDataInput from '../../featureAccounting/Accounting/DataInput.vue'
import AccountingIndex from '../../featureAccounting/Accounting/Index.vue'
import Message from '../views/Message.vue'
import Utube from '../views/utube/utube.vue'
import Test from '../views/Test.vue'
import Test2 from '../views/Test2.vue'
import Test3 from '../views/Test3.vue'
import Test4 from '../views/Test4.vue'
import LoginPassCode from '../views/LoginPassCode.vue'
import UDMList from '../views/udm/List.vue'
import OnePagePreview from "@/pages/featureOnePage/pages/OnePagePreview.vue";


Vue.use(VueRouter)

  const routes = [
  {
    path: '/Goto',
    name: 'Goto',
    component: IndexForm,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/Message',
    name: 'Message',
    component: Message,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/',
    name: 'Root',
    component: Index,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/Index',
    name: 'Index',
    component: Index,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/Index2',
    name: 'Index2',
    component: Index2,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/IndexOnePagePro',
    name: 'IndexOnePagePro',
    component: IndexOnePagePro,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/IndexForm',
    name: 'IndexForm',
    component: IndexForm,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/ShoppingCart',
    name: 'ShoppingCart',
    component: ShoppingCart,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/AccountIndex',
    name: 'AccountIndex',
    component: AccountIndex,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    }
  },
  {
    path: '/AccountIndexM',
    name: 'AccountIndexM',
    component: AccountIndexM,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    },
  },
  {
    path: '/AccountingIndex',
    name: 'AccountingIndex',
    component: AccountingIndex,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    },
  },
  {
    path: '/AccountingDataInput',
    name: 'AccountingDataInput',
    component: AccountingDataInput,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    },
  },
  //------------------------------------
  {
    path: '/Utube',
    name: 'Utube',
    component: Utube,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    },
  },
  {
    path: '/channel1',
    name: 'channel1',
    component: Utube,
    meta: {
      requiresAuth: false,
      roles: ['USER']
    },
  },
  {
    path: '/channel2',
    name: 'channel2',
    component: Utube,
    meta: {
      requiresAuth: false,
      roles: ['USER']
    },
  },
  {
    path: '/channel3',
    name: 'channel3',
    component: Utube,
    meta: {
      requiresAuth: false,
      roles: ['USER']
    },
  },
  {
    path: '/channel4',
    name: 'channel4',
    component: Utube,
    meta: {
      requiresAuth: false,
      roles: ['USER']
    },
  },
  {
    path: '/channel5',
    name: 'channel5',
    component: Utube,
    meta: {
      requiresAuth: false,
      roles: ['USER']
    },
  },
  //------------------------------------
  {
    path: '/Test',
    name: 'Test',
    component: Test,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    },
  },
  {
    path: '/Test2',
    name: 'Test2',
    component: Test2,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    },
  },
  {
    path: '/Test3',
    name: 'Test3',
    component: Test3,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    },
  },
  {
    path: '/Test4',
    name: 'Test4',
    component: Test4,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    },
  },
  {
    path: '/LoginPassCode',
    name: 'LoginPassCode',
    component: LoginPassCode,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    },
  },
  {
    path: '/UDMList',
    name: 'UDMList',
    component: UDMList,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    },
  },
  {
    path: '/ugs/a',
    name: 'UDMList',
    component: UDMList,
    meta: {
      requiresAuth: true,
      roles: ['USER']
    },
  },
    {
      path: '/OnePagePreview',
      name: 'OnePagePreview',
      component: OnePagePreview,
      meta: {
        requiresAuth: true,
        roles: ['USER']
      }
    },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
//
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     if (!session.getAccUid()) {
//       next({ name: 'Login' })
//     } else {
//       next() // go to wherever I'm going
//     }
//   } else {
//     next() // does not require auth, make sure to always call next()!
//   }
// })

//
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (session.isLogined()) {
//       // if (!to.meta.roles) {
//       //   return next()
//       // }
//       // if (to.meta.roles.includes(store.state.user.rol)) {
//       //   switch (store.state.user.rol) {
//       //     case 'USER':
//       //       next({
//       //         name: 'UserHome'
//       //       })
//       //       break;
//       //     case 'ADMIN':
//       //       next({
//       //         name: 'AccountsHome'
//       //       })
//       //       break;
//       //     default:
//       //       next({
//       //         name: 'UserHome'
//       //       })
//       //   }
//       // }
//     } else {
//       next()
//     }
//
//     // this route requires auth, check if logged in
//     // if true, redirect to main page.
//     // if (store.getters.isUserLoggedIn) {
//     //   if (!to.meta.roles) {
//     //     return next()
//     //   }
//     //   if (to.meta.roles.includes(store.state.user.rol)) {
//     //     switch (store.state.user.rol) {
//     //       case 'USER':
//     //         next({
//     //           name: 'UserHome'
//     //         })
//     //         break;
//     //       case 'ADMIN':
//     //         next({
//     //           name: 'AccountsHome'
//     //         })
//     //         break;
//     //       default:
//     //         next({
//     //           name: 'UserHome'
//     //         })
//     //     }
//     //   }
//     // } else {
//     //   next()
//     // }
//   }
// })
