<template>
  <div class="container">

    <div class="scrollmenu">
      <span v-for="(catalog,index) in accCatalog" :key="index">
      <button class="btn btn-lg btn-primary btn_catalog Catalog" type="button" @click="selectCatalog(catalog)" >{{ catalog.Text }}</button>
      </span>
    </div>


    <div class="card">
      <h5 class="card-header">科目</h5>
      <div class="card-body">
        <h5 class="card-title"></h5>
        <p class="card-text"></p>

        <div class="form-group row">
          <label for="" class="col-sm-2">科目</label>
          <div class="col-sm-10">
            <select v-model="formData.accItem">
              <option></option>
              <option v-for="(item,index) in accItemsFiltered" :key="index" :value="item">{{item.Text}}</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="" class="col-sm-2">票號</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" placeholder="" v-model="formData.InvoiceNo">
          </div>
        </div>

        <div class="form-group row">
          <label for="" class="col-sm-2">金額</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" placeholder="" v-model="formData.Amount">
          </div>
        </div>

        <div class="form-group row">
          <label for="" class="col-sm-2">備註</label>
          <div class="col-sm-10">
            <textarea class="form-control" id="txtMemo" name="txtMemo" rows="3" v-model="formData.Memo"></textarea>
          </div>
        </div>


        <a class="btn btn-primary" @click="save"><i class="fa fa-save"></i> 儲存</a>
      </div>
    </div>
    <div class="card">
      <h5 class="card-header">日記帳</h5>
      <div class="card-body">
        <v-app id="inspire" class="myGridPanel">
          <v-data-table
              ref="grid"
              :headers="headers"
              :items="gridDatas"
              item-key="Id"
              :items-per-page="5"
              class="elevation-1"
              show-select
              :single-select=false
              :footer-props="gridFooterProps"
              v-model="gridMeta.selectedRecord"
          >
            <!--        v-model="selectedRecord"-->
            <!--            <template v-slot:item.TITLE="{item}">-->
            <!--              {{item.TITLE|fmtTitle}}-->
            <!--            </template>-->
            <!--            <template v-slot:item.UPDATE_DATE="{item}">-->
            <!--              {{item.UPDATE_DATE|fmtDate}}-->
            <!--            </template>-->
            <!--            <template v-slot:item.ORDERKEY="{item}">-->
            <!--              <input type="text" class="form-control"  name="name" placeholder="" v-model.lazy="item.ORDERKEY" v-numeric-or-empty @change="updateOrderKey(item,item.ORDERKEY)" style="width: 80px">-->
            <!--            </template>-->
            <!--            <template v-slot:item.STATUS="{item}">-->
            <!--              <a href="#" v-if="item.STATUS==='1'" @click="updateStatus(item,'0')"><v-icon>far fa-circle</v-icon></a>-->
            <!--              <a href="#" v-if="item.STATUS!=='1'" @click="updateStatus(item,'1')"><v-icon>fas fa-times</v-icon></a>-->
            <!--            </template>-->
            <template v-slot:item.actions="{ item }">
              <v-btn color="primary" @click="gridEdit(item)">編輯</v-btn>
              <v-btn color="danger" @click="gridDelete(item)">刪除</v-btn>
            </template>

          </v-data-table>
        </v-app>
      </div>
    </div>


  </div>
</template>

<script>

import * as acc from "@/pages/featureOnePage/util/Accounting";
import * as dec from "../../common/declare";

export default {
  //name: "DataInput",
  // props: [""],
  data: function () {
    return {
      id: "",
      formData: {

      },
      formDataDemo: {
        accItem: undefined,
        InvoiceNo: "",
        Amount: "",
        Memo: "",
      },
      headers: [
        { text: '操作', value: 'actions', sortable: false  },
        { text: '分類1', value: 'CatalogId',width: "120px" },
        { text: '分類2', value: 'SubCatalogId',width: "120px" },
        { text: '科目', value: 'Text',width: "120px" },
        { text: '票號', value: 'InvoiceNo',width: "120px" },
        { text: '金額', value: 'Amount',width: "120px" },
        { text: '備註', value: 'Memo',width: "240px" },
      ],
      gridFooterProps: dec.GridFooterProps,
      gridMeta:{
        selectedRecord:[]
      },
      gridDatas: [ ],

      accCatalog:acc.AccountingCatalog,
      accItems:acc.AccountingItem,

      currentSelectCatalog:undefined,
    };
  },
  computed:{
    accItemsFiltered() {
      if (this.currentSelectCatalog){
        return this.accItems.filter(s=>s.CatalogId===this.currentSelectCatalog.Id)
      }else{
        return this.accItems;
      }
    },
  },
  methods: {
    selectCatalog: function (catalog) {
      this.currentSelectCatalog=catalog
    },
    async loadData() {
      this.gridDatas=acc.AccountingDiaryDemo;

      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    save(){
      let newItem=    {
            CatalogId:this.formData.accItem.CatalogId,
            SubCatalogId:this.formData.accItem.SubCatalogId,
            ItemId:this.formData.accItem.ItemId,
            Text:this.formData.accItem.Text,

            InvoiceNo:this.formData.InvoiceNo,
            Amount:this.formData.Amount,
            Memo:this.formData.Memo,
          }

      acc.AccountingDiaryDemo.push(newItem)

    },
    gridEdit(item){
      console.log(item);
    },
    gridDelete(item){
      console.log(item);
    }
  },
  mounted() {


    this.loadData()
  },
}
</script>

<style scoped>
body{
  background-color: lawngreen;
}
.btn{
  /*color: white !important;*/
  margin: 5px;
  width: 100px;
}

.scrollmenu {
  /*background-color: #333;*/
  overflow: auto;
  white-space: nowrap;
}

.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  /*padding: 14px;*/
  text-decoration: none;
}

.scrollmenu a:hover {
  background-color: #777;
}
</style>
