import util from "@/pages/common/util";

export const DB_WebPartDataDemo = []

export const DebugOnePage = false

export default {
    getFirstPageByLang(onePagePro,lang) {
        if (lang){
            let pages=this.getPageByPage(onePagePro,lang);

            if (pages.length>0){
                return pages.OnePageSystemId
            }
        }else{
            if (onePagePro.OnePagePro.PageLists.length>0){
                if (onePagePro.OnePagePro.PageLists[0].Pages.length>0){
                    console.log("a:"+onePagePro.OnePagePro.PageLists[0].Pages[0].OnePageSystemId);
                    return onePagePro.OnePagePro.PageLists[0].Pages[0].OnePageSystemId;
                }
            }
        }
    },

    getPageByPage(onePagePro,currrentLang){
        let arrResult=onePagePro.OnePagePro.PageLists.filter(s => s.LangCode===currrentLang);

        if (arrResult.length===1){
            return arrResult[0].Pages;
        }else{
            console.log("error...");
            throw new Error("lenght must 1");
        }
    },
    getPageByOnePageSystemId(onePagePro,onePageSystemId){

        for (let pageList of onePagePro.OnePagePro.PageLists) {
            for (let page of pageList.Pages) {
                if (page.OnePageSystemId===onePageSystemId){
                    return page.OnePage;
                }
            }
        }

        throw new Error("lenght must 1");
    },
    AddNewPage(vue,onePagePro,currrentLang,newOnePage){
        let pageList=onePagePro.OnePagePro.PageLists.filter(s => s.LangCode===currrentLang);

        let page={
            OnePage:newOnePage,
            OnePageSystemId:util.uuid(),
            OrderKey:"",
            Title:"New",
            IsTempSystemId:true,
        }

        if (pageList.length>0){
            pageList[0].Pages.push(page);

            // const found = webPartsAll.findIndex(s => s.Id ===afterId);
            // pageList.splice(0,1, page);
        }else{
            let newPageList={
                LangCode:currrentLang,
                Pages:[page]
            }
            onePagePro.OnePagePro.PageLists.push(newPageList);
        }


    }

}