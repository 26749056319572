<template>
  <div class="" >

    <div class="card-deck mb-3 text-center">
      <div class="card mb-4 box-shadow">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Free</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">$0 <small class="text-muted">/ mo</small></h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>10 users included</li>
            <li>2 GB of storage</li>
            <li>Email support</li>
            <li>Help center access</li>
          </ul>
          <button type="button" class="btn btn-lg btn-block btn-outline-primary">Sign up for free</button>
        </div>
      </div>
    </div>


<!--    <div class="card-deck mb-3 text-center">-->
<!--      <div class="card mb-4 box-shadow">-->
<!--        <div class="card-header">-->
<!--          <h4 class="my-0 font-weight-normal">Free</h4>-->
<!--        </div>-->
<!--        <div class="card-body">-->
<!--          <h1 class="card-title pricing-card-title">$0 <small class="text-muted">/ mo</small></h1>-->
<!--          <ul class="list-unstyled mt-3 mb-4">-->
<!--            <li>10 users included</li>-->
<!--            <li>2 GB of storage</li>-->
<!--            <li>Email support</li>-->
<!--            <li>Help center access</li>-->
<!--          </ul>-->
<!--          <button type="button" class="btn btn-lg btn-block btn-outline-primary">Sign up for free</button>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card mb-4 box-shadow">-->
<!--        <div class="card-header">-->
<!--          <h4 class="my-0 font-weight-normal">Pro</h4>-->
<!--        </div>-->
<!--        <div class="card-body">-->
<!--          <h1 class="card-title pricing-card-title">$15 <small class="text-muted">/ mo</small></h1>-->
<!--          <ul class="list-unstyled mt-3 mb-4">-->
<!--            <li>20 users included</li>-->
<!--            <li>10 GB of storage</li>-->
<!--            <li>Priority email support</li>-->
<!--            <li>Help center access</li>-->
<!--          </ul>-->
<!--          <button type="button" class="btn btn-lg btn-block btn-primary">Get started</button>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card mb-4 box-shadow">-->
<!--        <div class="card-header">-->
<!--          <h4 class="my-0 font-weight-normal">Enterprise</h4>-->
<!--        </div>-->
<!--        <div class="card-body">-->
<!--          <h1 class="card-title pricing-card-title">$29 <small class="text-muted">/ mo</small></h1>-->
<!--          <ul class="list-unstyled mt-3 mb-4">-->
<!--            <li>30 users included</li>-->
<!--            <li>15 GB of storage</li>-->
<!--            <li>Phone and email support</li>-->
<!--            <li>Help center access</li>-->
<!--          </ul>-->
<!--          <button type="button" class="btn btn-lg btn-block btn-primary">Contact us</button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>

</template>

<script>


import apiServerUtil from "@/pages/common/apiServerUtil";

export default {
  props:["webPart"],
  data: function() {
    return {
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    toDownloadLink(isMobile,imageFileUrl){
      return apiServerUtil.getPicLinkOnePage(isMobile,imageFileUrl);
    }
  },
  mounted() {
    this.loadData()
  },
}

</script>

<style scoped>
/*.crop {*/
/*  width: 600px;*/
/*  height: 200px;*/
/*  overflow: hidden;*/
/*  !*     margin: 10px; *!*/
/*  position: relative;*/
/*}*/
/*.crop img {*/
/*  position: absolute;*/
/*  left: -1000%;*/
/*  right: -1000%;*/
/*  top: -1000%;*/
/*  bottom: -1000%;*/
/*  margin: auto;*/
/*  min-height: 100%;*/
/*  min-width: 100%;*/
/*}*/
</style>
