<template>
  <div class="d-flex justify-content-center" >
    <a role="button" class="btn btn-block btn-lg btn-primary" href="#">送出</a>
  </div>

</template>

<script>
import {JWebPartFormSubmit} from "@/pages/featureOnePage/util/model/JOnePageWebParts";

export default {
  components: {},
  props: {
    webPart: JWebPartFormSubmit,
  },
  data: function() {
    return {
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    setting(){
      this.$refs.dialog.showModal();
    },
    blockWebPartSelectorReturnOk(){

    }
  },
  mounted() {
    this.loadData()
  },
}
</script>
