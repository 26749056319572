<template>
  <div class="form-inline">
      <input type="text" class="form-control" placeholder="" aria-label="" id="copyInput" v-model="propsValue">
      <button class="btn btn-outline-secondary" type="button" ref="copyButton"  data-clipboard-target="#copyInput">複製</button>
  </div>
</template>

<script>
/**
 <MyInputCopyTextbox v-model="formLink"></MyInputCopyTextbox>
 **/

import Clipboard from 'clipboard';  // eslint-disable-line



export default {
  //name: "MyInputCopyTextbox",
  props: ["value"],
  data: function () {
    return {
      propsValue:this.value,
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    },
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    initClipboard() {
      const clipboard = new Clipboard(this.$refs.copyButton);
      clipboard.on('success', (e) => {  // eslint-disable-line
        console.log('複製成功')
      });
      clipboard.on('error', (e) => {// eslint-disable-line
        alert('複製失敗，請手動複製')
      });
    }
  },
  updated() {
    this.initClipboard();
  },
}
</script>

<style scoped>

</style>
