<template>
  <div>

  </div>
</template>

<script>

export default {
  //name: "Index.vue",
  // props: [""],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>

</style>