<template>
  <div class="" >
    <b-modal ref="my-modal" hide-footer title="設定" size="lg">
      <div class="d-block text-center">
        <!--        <h3>Hello From My Modal!</h3>-->
      </div>
      <div class="form-group row">
        <label for="" class="col-sm-2">表單連結</label>
        <div class="col-sm-8">
          <MyInputCopyTextbox v-model="formLink"></MyInputCopyTextbox>
        </div>
      </div>

      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>
  </div>

</template>

<script>
/**
 <ShareForm ref="shareForm" :page-id="this.pageId"></ShareForm>

 this.$refs.shareForm.showModal();
 */
import ubossHostManager from "@/pages/commonUBoss/ubossHostManager";
import MyInputCopyTextbox from "@/pages/commonComponent/MyInputCopyTextbox.vue";

export default {
  components: {MyInputCopyTextbox},
  props:["pageId"],
  data: function() {
    return {
      showThis:false,
      formLink:"",
    };
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')

      this.$emit('returnOk');
    },
    async loadData(){
      let ubossHost=new ubossHostManager();

      let url=ubossHost.formShowFormUrl(this.pageId);
      this.formLink=url;
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
