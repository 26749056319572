<template>

  <div class="" >
<!--    &lt;!&ndash;mobile&ndash;&gt;-->
<!--    <div class="d-block d-sm-none">-->
<!--      <b-carousel-->
<!--          ref="carousel"-->
<!--          :interval="5000"-->
<!--          controls-->
<!--          indicators-->
<!--          background="#ababab"-->
<!--          img-width="1024"-->
<!--          style="text-shadow: 1px 1px 2px #333;"-->
<!--      >-->
<!--        <b-carousel-slide v-for="(image,index) in propsWebPart.PartImages" :key="index" :img-src="toDownloadLink(true,image.ImageUrl)"  :data-interval="image.Interval.makeSureNumber(5) * 1000"></b-carousel-slide>-->
<!--      </b-carousel>-->

<!--    </div>-->

<!--    &lt;!&ndash;desktop&ndash;&gt;-->
<!--    <div class="d-none d-sm-block">-->
<!--      <b-carousel-->
<!--          ref="carousel"-->
<!--          :interval="5000"-->
<!--          controls-->
<!--          indicators-->
<!--          background="#ababab"-->
<!--          img-width="1024"-->
<!--          style="text-shadow: 1px 1px 2px #333;" @sliding-end="slidingEnd"-->
<!--      >-->
<!--        <b-carousel-slide v-for="(image,index) in propsWebPart.PartImages" :key="index" :img-src="toDownloadLink(false,image.ImageUrl)" :data-interval="image.Interval.makeSureNumber(5) * 1000"></b-carousel-slide>-->
<!--      </b-carousel>-->

<!--    </div>-->

    <carousel :autoplay="true" :nav="false" :dots="true" :center="false" :items="1" :loop="true" :responsive="responsive" :margin="10" :autoWidth="true">
      <a v-for="(image,index) in propsWebPart.PartImages" :key="index" :href="image.LinkUrl ? image.LinkUrl :'#'" :target="image.LinkTarget" ><img  :src="toDownloadLink(false,image.ImageUrl)" style="width:400px"></a>
<!--      <img src="/img/C02.jpg" style="width:400px">-->
<!--      <img src="/img/C03.jpg" style="width:400px">-->
<!--      <img src="/img/C02.jpg" style="width:400px">-->
<!--      <img src="/img/C02.jpg" style="width:400px">-->
<!--      <img src="/img/C02.jpg" style="width:400px">-->
<!--      <img src="/img/C02.jpg" style="width:400px">-->
<!--      <img src="/img/C02.jpg" style="width:400px">-->
<!--      <img src="/img/C02.jpg" style="width:400px">-->
<!--      <img src="/img/C02.jpg" style="width:400px">-->
<!--      <img src="/img/C02.jpg" style="width:400px">-->
<!--      <img src="/img/C02.jpg" style="width:400px">-->
<!--      <img src="/img/C02.jpg" style="width:400px">-->
<!--      <img src="/img/C02.jpg" style="width:400px">-->
      <!--      <img src="https://placeimg.com/200/200/any?1">-->

      <!--      <img src="https://placeimg.com/200/200/any?2">-->

      <!--      <img src="https://placeimg.com/200/200/any?3">-->

      <!--      <img src="https://placeimg.com/200/200/any?4">-->

    </carousel>
<!--    <VueSlickCarousel v-bind="settings">-->
<!--      <span v-for="(image,index) in propsWebPart.PartImages" :key="index">-->
<!--          <img :src="toDownloadLink(false,image.ImageUrl)" style="width:100px">-->
<!--      </span>-->
<!--    </VueSlickCarousel>-->


<!--    <div id="carousel2_indicator" class="carousel slide carousel-fade" data-ride="carousel">-->
<!--      <div class="carousel-inner">-->
<!--          <div class="carousel-item"  v-for="(image,index) in propsWebPart.PartImages" :key="index">-->
<!--            <img class="d-block w-100" :src="image.ImageUrl" :alt="index">-->
<!--          </div>-->

<!--      </div>-->

<!--&lt;!&ndash;      <div class="carousel-inner">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="carousel-item">&ndash;&gt;-->
<!--&lt;!&ndash;          <img class="d-block w-100" src="http://172.104.81.182:8080/ubossOnePage/_image_s/紹瑄一頁是廣告_前半-01_02_01.gif" alt="First slide">&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="carousel-item active">&ndash;&gt;-->
<!--&lt;!&ndash;          <img class="d-block w-100" src="http://172.104.81.182:8080/ubossOnePage/_image_s/紹瑄一頁是廣告_前半-01_02_02.gif" alt="Second slide">&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>      &ndash;&gt;-->
<!--      <a class="carousel-control-prev" href="#carousel2_indicator" role="button" data-slide="prev">-->
<!--        <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
<!--        <span class="sr-only">Previous</span>-->
<!--      </a>-->
<!--      <a class="carousel-control-next" href="#carousel2_indicator" role="button" data-slide="next">-->
<!--        <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
<!--        <span class="sr-only">Next</span>-->
<!--      </a>-->
<!--    </div>-->

  </div>

</template>

<script>

//QQ move to js
String.prototype.makeSureString = function(defaultValue)
{
  if (this===null){
    if (defaultValue===undefined){
      return "";
    }else{
      return defaultValue;
    }
  }
}

String.prototype.makeSureNumber = function(defaultValue)
{
  let value=this+"";

  if (isNaN(value) || value===""){
    if (defaultValue===undefined){
      return 0;
    }else{
      return defaultValue;
    }
  }else{
    return new Number(value)
  }
  //
  // if (this===null){
  //     if (defaultValue===undefined){
  //         return 0;
  //     }else{
  //         return defaultValue;
  //     }
  // }else{
  //     if (isNaN(this)){
  //         if (defaultValue===undefined){
  //             return 0;
  //         }else{
  //             return defaultValue;
  //         }
  //     }else{
  //         return new Number(this)
  //     }
  // }
}

// import util from "@/pages/common/util";
//
// import HelloWorld from '@/components/HelloWorld.vue'
//


import carousel from 'vue-owl-carousel'
import apiServerUtil from "@/pages/common/apiServerUtil";

export default {
  components: { carousel },
  props: {
    webPart: {},
  },
  // components: {
  //   HelloWorld
  // }

  data: function() {
    return {
      /** @type {JWebPartSliderImage2} */
      propsWebPart: this.webPart,
      showThis:false,
      layoutType:1,
      formData:{
        name:"Sophie Wang",
      },
      submitted: false,
      settings:{
        "centerMode": true,
        "centerPadding": "20px",
        "focusOnSelect": true,
        "infinite": true,
        "slidesToShow": 3,
        "autoplaySpeed": 500
      }
    };
  },
  methods: {
    async loadData(){
      // let data = onePageUtil.webPartSliderImage_Load(this.areaId,this.blockId);
      //
      // if (data){
      //   this.formData.url=data.content
      // }


    },
    toDownloadLink(isMobile,imageFileUrl){
      return apiServerUtil.getPicLinkOnePage_OrgSize(isMobile,imageFileUrl);
    },
    slidingEnd(slide){
      try {
        this.$refs.carousel.interval = this.$refs.carousel.slides[slide].dataset.interval
      } catch (e) {
        console.log(e);
      }

      // this.$refs.carousel.interval=this.$refs.carousel.interval+1000;
      // console.log(slide);
      // console.log(this.$refs.carousel.interval);
    }
  },
  created() {
  },
  mounted() {
    this.loadData()

  },
}
</script>
