<template>
  <div class="" >
    <b-modal ref="my-modal" hide-footer title="設定" size="xl">
      <div class="d-block text-center">
        <!--        <h3>Hello From My Modal!</h3>-->
      </div>

      <div class="form-group row">
        <label for="" class="col-sm-2">網頁內容</label>
        <div class="col-sm-10">
          <MyCkEditor ref="myCkEditor" v-model="Footer.HtmlContent"  height="400" width="100%" :file-upload-list-lite_is-show="true" :file-upload-list-lite_owner-system-id="pageId"  :file-upload-list-lite_file-use-memo="this.$u.UBossFileUseType_OnePageFooter"></MyCkEditor>
        </div>
      </div>
      <b-button class="mt-2" variant="btn btn-primary" block @click="toggleModal()">儲存</b-button>
      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>
  </div>

</template>

<script>
import MyCkEditor from "@/pages/commonComponent/MyCkEditor.vue";
export default {
  props:["Footer","pageId"],
  components:{MyCkEditor},
  data: function() {
    return {
      showThis:false,
    };
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')

      this.$emit('returnOk');
    },
    async loadData(){

    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
