<template>
  <div style="" id="" class="video">

<!--    style="width:100%;height:100%;"-->
<!--    :playerVars="videoOptions"-->
    <youtube  ref="youtube" video-id="SMEY0viUSCc"    class=""  fitParent="true"></youtube>
<!--    <youtube video-id="BBJa32lCaaY" width="100%" />-->

<!--    <youtube2  ref="youtube" video-id="SMEY0viUSCc"   width="99%"  ></youtube2>-->
<!--    <youtube2 video-id="BBJa32lCaaY" width="100%" />-->
    <a role="button" class="btn btn-primary" @click="btnTest1">送出</a>
    <a role="button" class="btn btn-primary" @click="btnTest2">送出2</a>
    <a role="button" class="btn btn-primary" @click="btnTest3">送出3</a>
    <a role="button" class="btn btn-primary" @click="btnTest4">送出4</a>

    <h1>11</h1>
    <div class="bottomBar"> button test</div>

    <h2>FAB</h2>

    <vue-fab mainBtnColor="#2b2b2b">
      <fab-item @clickItem="clickItem" :idx="0" title="add"  />
      <fab-item @clickItem="clickItem" :idx="1" title="https"  />
      <fab-item @clickItem="clickItem" :idx="2" title="edit"  />
    </vue-fab>
<!--    <vue-fab mainBtnColor="#3599DB">-->
<!--      <fab-item @clickItem="clickItem" :idx="0" title="add" icon="add" />-->
<!--      <fab-item @clickItem="clickItem" :idx="1" title="https" icon="https" />-->
<!--      <fab-item @clickItem="clickItem" :idx="2" title="edit" icon="edit" />-->
<!--    </vue-fab>-->

<!--    <vue-fab icon="icon-jia"-->
<!--             fabItemAnimate="default"-->
<!--             mainBtnColor="#16C2C2">-->
<!--      <fab-item @clickItem="clickItem" icon="icon-Rxing" :idx="0" title="iconfont.cn">-->
<!--      </fab-item>-->
<!--      <fab-item @clickItem="clickItem" icon="icon-huanyihuan1" :idx="1" title="交换">-->
<!--      </fab-item>-->
<!--      <fab-item @clickItem="clickItem" icon="icon-fangda1" :idx="2" title="聚焦">-->
<!--      </fab-item>-->
<!--    </vue-fab>-->
<!--    <vue-fab-->
<!--        :mainBtnColor="mainBtnColor">-->
<!--      <fab-item-->
<!--          v-for="(item, idx) in menu"-->
<!--          :idx="idx"-->
<!--          :title="item.title"-->
<!--          :color="item.color"-->
<!--          :icon="item.icon"-->
<!--          @clickItem="clickItem" />-->
<!--    </vue-fab>-->
  </div>
</template>

<script>

import utilBrowser from "@/pages/common/utilBrowser";

export default {
  name: 'MyComponent',
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
      videoOptions:{
        autoplay: 1,
        loop:1
      },
      // responsive:{
      //   600:{
      //     items:3
      //   }
      // }
      menu: [
        {
          icon: 'done',
          title: 'good job!',
          color: '#ff9900'
        },
        {
          icon: 'toc',
          title: '',
          color: '#999'
        }
      ],
      mainBtnColor: '#3eaf7c'
    };
  },
  methods: {
    btnTest1(){
      let player= this.$refs.youtube.player
      player.seekTo(0, true)
      player.playVideo()
    },
    btnTest2(){
      let player= this.$refs.youtube.player
      player.pauseVideo()
    },
    btnTest3(){
      utilBrowser.openFullscreen();
    },
    btnTest4(){
      utilBrowser.rotateBrowser(90)
    },
    btnTest5(){

    },
    btnTest6(){

    },
    clickItem(){

    }
  }
}
</script>

<style scoped>
/*#iframe_block{*/
/*  position: fixed;*/
/*  left:-100px;*/
/*  top:140px;*/
/*  right:0;*/
/*  bottom:0;*/

/*  border:none;*/
/*}*/
/*iframe{*/
/*  width:100%;*/
/*  height: 100%;*/
/*  border:none;*/
/*}*/

/*.bottomBar{*/
/*  position: fixed;*/
/*  bottom: 0;*/
/*  width: 100%;*/
/*}*/

.video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
iframe {
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  /*width: 100%;*/
  /*height: 100%;*/
}
</style>
