<template>

  <div :style="cssVars">
    <div class="d-block d-sm-none">

      <!--    mobile-->

      <div class="header scrollmenu hidden-md-up" :class="style.classApply">

<!--       ------------------------------------- -->
<!--        <span v-if="isDesignTime()">-->
<!--          <a class="btn btn-primary" @click="headerSetting"><i class="fa fa-cog"></i> </a>-->
<!--        </span>-->
<!--        <span v-if="OnePage.Header.LogoUrl">-->
<!--          <img :src="toDownloadLink(OnePage.Header.LogoUrl)" style="height: 100%"/>-->
<!--        </span>-->
<!--        <span v-for="(webPart,index) in this.OnePage.WebParts" :key="'p'+index">-->
<!--          <span v-if="webPart.Title">-->
<!--            <a class=""  :href="webPartUrl(webPart)" >{{webPart.Title}}</a>-->
<!--            <span v-if="isDesignTime()">-->
<!--              <a class="btn btn-primary" @click="moveLeft(webPart)" style="padding: 1px;margin:1px"><i class="fa fa-arrow-left"></i>  </a>-->
<!--              <a class="btn btn-primary" @click="moveRight(webPart)" style="padding: 1px;margin:1px"><i class="fa fa-arrow-right"></i>  </a>-->
<!--            </span>-->
<!--            <span class="scrollmenuDiv">|</span>-->
<!--          </span>-->
<!--        </span>-->
<!--        <span v-for="(item,index) in OnePage.Header.MenuLinks" :key="index">-->
<!--          <a class=""  :href="item.LinkUrl" :target="item.LinkTarget">{{item.LinkText}}</a>-->
<!--          <span class="scrollmenuDiv">|</span>-->
<!--        </span>-->
        <!--       ------------------------------------- -->

      </div>

    </div>

    <div class="d-none d-sm-block ">

      <!--    desktop-->
      <div class="header scrollmenu d-flex justify-content-center hidden-md-down" :class="style.classApply">
        <!--       ------------------------------------- -->
        <span v-if="isDesignTime()">
          <a class="btn btn-primary" @click="headerSetting"><i class="fa fa-cog"></i> </a>
        </span>
<!--        <span v-if="OnePage.Header.LogoUrl">-->
<!--          <img :src="toDownloadLink(OnePage.Header.LogoUrl)" style="height: 100%"/>-->
<!--        </span>-->
        <span v-for="(menu,index) in this.currentPages" :key="'m'+index">
          <span v-if="menu.Title">
            <a class="" :class="isActiveMenuClass(menu)" type="button"  @click="menuClick(menu)" >{{menu.Title}}</a>
            <span v-if="isDesignTime()">
              <a class="btn btn-primary" @click="moveLeft(menu)" style="padding: 1px;margin:1px"><i class="fa fa-arrow-left"></i>  </a>
              <a class="btn btn-primary" @click="moveRight(menu)" style="padding: 1px;margin:1px"><i class="fa fa-arrow-right"></i>  </a>
            </span>
            <span class="scrollmenuDiv">|</span>
          </span>
        </span>

        <select class="form-control aselect languageBar" v-model="currentLangCode">
          <option v-for="(langCode,index) in this.getAll_LangCode()" :key="index" :value="langCode" >{{langCode}}</option>
        </select>

        <button class="btn btn-primary" @click="addNewPage" >add</button>
<!--        <span v-for="(item,index) in OnePage.Header.MenuLinks" :key="index">-->
<!--          <a class=""  :href="item.LinkUrl" :target="item.LinkTarget">{{item.LinkText}}</a>-->
<!--          <span class="scrollmenuDiv">|</span>-->
<!--        </span>-->
        <!--       ------------------------------------- -->

      </div>
    </div>



  </div>
</template>

<script>
import session from "../../common/session";
import DesignTimeSupport from "@/pages/featureOnePage/base/DesignTimeSupport.vue";
// import $ from "jquery"
// import "bootstrap"
// import HelloWorld from '@/components/HelloWorld.vue'
import arrayUtil from "@/pages/common/arrayUtil";
import apiServerUtil from "@/pages/common/apiServerUtil";
import onePageProUtil from "@/pages/featureOnePage/util/onePageProUtil";
import apiUBossOnePage from "@/pages/featureOnePage/util/apiUBossOnePage";

export default {
  components: {},
  props:["onePagePro"],
  mixins: [DesignTimeSupport],
  data: function() {
    return {
      showDialogLogin: false,
      // DB_OnePage:this.$onePageUtil.DB_OnePage,
      sectionData:{},
      cssStyle:{
        FontColor: '',
        BackgroundColor: '',
        BackgroundImageUrl: '',
      },
      style:{
        classApply:"",
      },
      propsValue:this.value,
      currentLangCode:"",
      currentPages:[],
      currentActiveOnePageSystemId:"",
    };
  },
  computed: {
    cssVars() {
      return {
        '--fontColor': this.cssStyle.FontColor ,
        '--bgColor': this.cssStyle.BackgroundColor ,
        '--bgImageUrl': this.cssStyle.BackgroundImageUrl ,
      }
    },

  },
  watch:{
    OnePage(value){
      this.cssStyle.FontColor=value.Header.FontColor;
      this.cssStyle.BackgroundColor=value.Header.BackgroundColor;
      this.cssStyle.BackgroundImageUrl=value.Header.BackgroundImageUrl;
    },
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    },
    currentLangCode(newValue){
      this.showPageByLangCode(newValue)
    },
    // currentActiveOnePageSystemId(newValue){
    //   return this.isActiveMenuClass(newValue);
    // }
  },
  methods: {
    isDesignTime(){
      return this.timeType=== this.$onePageUtil.OnePage_TimeType_Design;
    },
    test:function(){
    },
    showLogin: function () {
      this.showDialogLogin = true;
    },
    isLogined: function() {
      return session.isLogined();
    },
    goto_shopCart(){
      this.$router.push({ name: 'ShoppingCart', query: { ID: 0 }})
    },
    headerSetting(){
      this.$refs.headerSetting.showModal();
    },
    moveLeft(item){
      arrayUtil.orderMove(false,item.Id,this.OnePage.WebParts,"Id","OrderKey")
    },
    moveRight(item){
      arrayUtil.orderMove(true,item.Id,this.OnePage.WebParts,"Id","OrderKey")
    },
    // dlgLogin:function(){
    //   this.$bvModel.show('modal_user_login')
    // }
    menuClick(menu){
      console.log("click:" +menu.OnePageSystemId);
      this.propsValue=menu.OnePageSystemId;
      this.currentActiveOnePageSystemId=menu.OnePageSystemId;
      this.$emit('menuClick',menu.OnePageSystemId)
    },
    isActiveMenuClass(menu){
    // isActiveMenuClass(menuOnePageSystemId){
      console.log("change");
      if (this.currentActiveOnePageSystemId===menu.OnePageSystemId){
        return "activeMenu";
      }

      return "";
    },
    async loadData(){
      //PRO
      // if (this.type==="this.$u.UBossOnePageHeaderType_OnePagePro"){
        this.style.classApply="fixed-top";
      // }else if (this.type==="2"){
      //   this.style.classApply="";
      // }
      //
      // this.cssStyle.FontColor=this.OnePage.Header.FontColor;
      // this.cssStyle.BackgroundColor=this.OnePage.Header.BackgroundColor;
      // this.cssStyle.BackgroundImageUrl=this.OnePage.Header.BackgroundImageUrl;
    },
    toDownloadLink(imageFileUrl){
      return apiServerUtil.toDownloadLink(imageFileUrl);
    },
    getAll_LangCode(){
      let langCode=['zh-TW','en-US'];

      return langCode;
    },
    showPageByLangCode(langCode){


      let pages=onePageProUtil.getPageByPage(this.onePagePro,langCode);

      if (pages.length>0){
        this.menuClick(pages[0]);
      }

      this.currentPages= pages;
    },
    async addNewPage(){
      let newPage=await apiUBossOnePage.api_OnePage_Init();


      onePageProUtil.AddNewPage(this,this.onePagePro,this.currentLangCode,newPage)

      this.showPageByLangCode(this.currentLangCode)
    },
    async Clone(){
      // await apiUBossOnePage.api_OnePage_ClonePage(this.OnePageId,JSON.stringify(this.OnePage))
    },
  },
  mounted() {
    // this.DB_OnePage=this.$onePageUtil.DB_OnePage
    this.loadData()

    this.currentLangCode="zh-TW";
  }

  // components: {
  //   HelloWorld
  // }
}
</script>

<style scoped>
.header{
  background-image: var(--bgImageUrl);
  /*height: 100px;*/
  background-repeat:no-repeat;
  background-position: center;
  /*background-size:contain;*/
}
/*.navOnePage{*/
/*  background-color:#a044ff !important;*/
/*  color: white;*/
/*  height: 70px;*/
/*  font-size: large;*/
/*}*/

.scrollmenu {

  background-color: var(--bgColor);
  overflow: auto;
  white-space: nowrap;
  padding: 5px;
  height: 52px;
}

.scrollmenu a {
  /*background-color: #a044ff !important;*/
  display: inline-block;
  color: var(--fontColor);
  text-align: center;
  /*padding: 14px;*/
  text-decoration: none;
  /*border-left: 2px solid #a044ff;*/
  /*border-right: 2px solid white;*/
  font-size: 15px;
  padding-left:  10px;
  padding-right:  10px;
  margin: 10px;
}

.scrollmenu a:hover {
  background-color: #777;

}
.scrollmenuDiv{
  color: white;
}
.languageBar {
  /*position: absolute;*/
  /*right: 0px;*/
  /*width: 100px;*/
  /*padding: 10px;*/
}
.activeMenu{
  background-color:green !important;
}
</style>
