<template>

  <div id="wrapper">
<!--      <COM_Header></COM_Header>-->
      <router-view/>
<!--      <COM_Footer></COM_Footer>-->
  </div>


<!--  <div id="wrapper">-->
<!--    <div v-if="showMenu" >-->
<!--      <COM_Menu></COM_Menu>-->
<!--    </div>-->


<!--  &lt;!&ndash; Content Wrapper &ndash;&gt;-->
<!--  <div id="content-wrapper" class="d-flex flex-column">-->

<!--    &lt;!&ndash; Main Content &ndash;&gt;-->
<!--    <div id="content">-->
<!--      <COM_Header></COM_Header>-->
<!--      <router-view/>-->
<!--    </div>-->
<!--    <COM_Footer></COM_Footer>-->
<!--  </div>-->
<!--  &lt;!&ndash; End of Content Wrapper &ndash;&gt;-->
<!--  </div>-->

</template>

<style>

[v-cloak]{
  display: none;
}


.mySearchPanel{
  max-width: 1600px;
}

.mySearchPanel_toolbar{
  max-width: 1600px;
}
.myFormContainer{
  max-width: 1024px;
}

.myGridPanel{
  max-width: 1600px;
}

.mybutton{
  color: white !important;
  margin: 10px;
}

.errorAdm{
  border-color:red;
}

.message{
  border-color:red;
  color: red;
}
.require{
  color: red;
}

.myInput_Date{
  max-width: 185px;
  padding: 0px;
    display: block !important;
}
.myInput_DateTime{
  max-width: 230px;
  padding: 0px;
    display: block !important;
}

.sidebar{
  width: 18em !important;
}

select{
  appearance: menulist !important;
}

</style>


<script>

export default {
  name: '',
  components: { },
  // components: {
  //   HelloWorld
  // }
  data: function() {
    return {
      showMenu:true,
      // layout1: true,
      // layout2: false,
    };
  },
  methods: {
  },
  created() {
    // if (this.$route.name==="Login"){
    //   this.showMenu=false
    // }
    // if (this.$route.name==="indexS"){
    //   this.showMenu=false
    // }

  },
  mounted () {

    // if (this.$route.name==="MyWorkspace"){
    //   this.layout1=false;
    //   this.layout2=true;
    // }else{
    //   this.layout1=true;
    //   this.layout2=false;
    // }


  },
}
</script>
