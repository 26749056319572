<template>
  <div :style="cssVars" class="backgroundSetting">


<!--    <nav class="navbar navbar-expand-lg text-uppercase fixed-top" id="mainNav" style="">-->
<!--      <div class="container">-->
<!--        <a class="navbar-brand js-scroll-trigger" href="#page-top">title</a>-->
<!--        <button class="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">-->
<!--          Menu-->
<!--          <i class="fas fa-bars"></i>-->
<!--        </button>-->
<!--        <div class="collapse navbar-collapse" id="navbarResponsive">-->
<!--          <ul class="navbar-nav ml-auto" >-->
<!--            <li class="nav-item mx-0 mx-lg-1" v-for="page in OnePageAdv.Groups" v-bind:key="page.SystemId">-->
<!--              <a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" @click="gotoPage(page.SystemId)">{{page.Name}}</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </nav>-->

    <div id="layoutSidenav">

      <div id="layoutSidenav_content">
        <COM_Header v-if="OnePage.Header" :header="OnePage.Header" :common="OnePage.Common" :web-parts="OnePage.WebParts" :one-page-adv="OnePageAdv" :page-id="OnePageId" :type="this.$u.UBossOnePageHeaderType_OnePage"></COM_Header>

        <BlockListView2 v-if="OnePage.Header" area-id="area1" :page-id="OnePageId" :web-parts="OnePage.WebParts" v-on:Save="Save" v-on:Preview="Preview" :page-mode="this.$u.UBossPageMode_OnePage"></BlockListView2>

        <COM_Footer v-if="OnePage.Header" :footer="OnePage.Footer" :page-id="OnePageId" ></COM_Footer>
      </div>
    </div>
  </div>

</template>

<script>
import COM_Header from "@/pages/featureOnePage/ubossOnePage/COM_Header";
import BlockListView2 from "../../featureOnePage/ubossOnePage/BlockListView2";
import DesignTimeSupport from "@/pages/featureOnePage/base/DesignTimeSupport";
import COM_Footer from "../../featureOnePage/ubossOnePage/COM_Footer";
import apiServerUtil from "@/pages/common/apiServerUtil";
import apiUBossOnePage from "@/pages/featureOnePage/util/apiUBossOnePage";
import util from "@/pages/common/util";


export default {
  components: {
    COM_Footer,
    BlockListView2, COM_Header},
  mixins: [DesignTimeSupport],
  metaInfo: function() {
    //CC
    if (this.OnePage.Common){
      return {
        title: this.OnePage.Common.SiteTitle,
        link: [
          { rel: 'icon', href: this.toDownloadLink(this.OnePage.Common.FavIcon) }
        ],
        meta: [
          { charset: 'utf-8' },
          { property: 'og:title', content: this.OnePage.Common.SiteTitle},
          { property: 'og:description', content: this.OnePage.Common.SiteTitle},
        ]
      }
    }else{
      return {}
    }

  },
  data: function() {
    return {
      OnePage:{},
      OnePageId:"",
      OnePageAdv:{},
    };
  },
  computed:{
    cssVars() {
      if (this.OnePage.Common){
        return {
          '--BackgroundColor': this.OnePage.Common.BackgroundColor ,
          '--BackgroundImageUrl': "url('" + this.toDownloadLink(this.OnePage.Common.BackgroundImageUrl) +"')",
          '--BackgroundPosition': this.OnePage.Common.BackgroundPosition ,
          '--BackgroundRepeat': this.OnePage.Common.BackgroundRepeat ,
        }
      }else{
        return {}
      }
    }
  },
  watch:{
    '$route'(to,from){// eslint-disable-line
      this.OnePageId=this.$route.query.id

      if (!this.OnePageId){
        this.OnePageId="";
      }

      this.loadData()
      this.$forceUpdate();
    },
  },
  methods:{
    async loadData(){
      let json=await apiUBossOnePage.api_GetOnePage(this.OnePageId)
      this.OnePageId=json.SystemId
      this.OnePage=json.OnePage

      let dataOnePageAdv=await apiUBossOnePage.api_Get_OnePageAdv();
      this.OnePageAdv=dataOnePageAdv;


      this.$meta().refresh()
    },
    toDownloadLink(imageFileUrl){
      return apiServerUtil.toDownloadLink(imageFileUrl);
    },
    async Save(){
      await apiUBossOnePage.api_Save_OnePage(this.OnePageId,JSON.stringify(this.OnePage))

      util.ShowMessage("儲存成功","訊息")

      location.reload()
    },
    async Preview() {
      let routeData = this.$router.resolve({name: 'IndexOnePage'});
      window.open(routeData.href, '_blank');
    },
  },
  beforeCreate: function() {

  },
  created() {
    this.OnePageId=this.$route.query.id

    if (!this.OnePageId){
      this.OnePageId="";
    }

    console.info(this.$route.query);
    console.log("OnePageId:"+this.OnePageId)
    this.loadData()
  },
  mounted() {

  },

}
</script>


<style scoped>
.btn{
  color: white !important;
  margin: 5px;
  width: 100px;
}

.backgroundSetting {
  background-color: var(--BackgroundColor);
  background-image: var(--BackgroundImageUrl);
  background-position: var(--BackgroundPosition);
  background-repeat: var(--BackgroundRepeat);
}
</style>
