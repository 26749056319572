<template>
  <div class="container">
    <a class="btn btn-primary btn-lg" href="#">收入</a> <br/>
    <a class="btn btn-danger btn-sm first" href="#">營業收入</a>
    <a class="btn btn-info btn-sm" href="#">營業外收入</a>

    <br>
    <a class="btn btn-primary btn-lg" href="#">支出</a><br/>
    <a class="btn btn-info btn-sm first" href="#">薪資</a>
    <a class="btn btn-info btn-sm" href="#">雜支</a>

    <br>
    <a class="btn btn-primary btn-lg" href="#">設備</a><br/>
    <a class="btn btn-info btn-sm first" href="#">存貨</a>
    <a class="btn btn-info btn-sm" href="#">辦公設備</a>

    <hr>

    <table class="table table-hover">
      <thead class="thead-light">
      <tr>
        <th class="text-center">科目</th>
        <th class="text-center">日期</th>
        <th>金額</th>
        <th>備註</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="text-left">
          營業收入
        </td>
        <td class="text-left">
          <input type="date" class="form-control" id="name" name="name" placeholder="">
        </td>
        <td class="text-left">
          <input type="number" class="form-control" id="name" name="name" placeholder="">
        </td>
        <td class="text-left">
          <input type="text" class="form-control" id="name" name="name" placeholder="">
        </td>
      </tr>

      </tbody>
    </table>

  </div>
</template>

<script>

export default {
  //name: "AccountDetail",
  // props: [""],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>
.btn-sm{
  margin: 5px;
}
.first{
  margin-left: 50px;
}

</style>