<template>
  <div class="ubossHtmlContent" >
    <span v-html="propsWebPart.PartHtmlContent"></span>
  </div>

</template>

<script>


export default {
  props: {
    webPart: {},
  },
  data: function() {
    return {
      /** @type {JWebPartHtml} */
      propsWebPart:this.webPart
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
