var apiUrl=process.env.VUE_APP_apiUrl;
var apiPathBase="api/UBossCommonFileSecApi/";
import AxiosManager from "@/pages/common/axiosLib/axiosManager";
import * as ubossCommon from "@/pages/commonUBoss/ubossCommon";
import ubossCrudApiManager from "@/pages/commonUBoss/ubossCrudApiManager";

export default {
    //---------------axios------------------------
    getAxiosManager(){
        return new AxiosManager(ubossCommon.SessionKeyPrefix_Bk + ubossCommon.TokenKeyLastFix)
    },
    //---------------------------------------

    getApiManager_File() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"File", this.getAxiosManager(),null)
    },

    async api_File_Query ( ownerSystemId)
    {
        var sendData={
            ownerSystemId:ownerSystemId,
        };
        return this.getApiManager_File().api_CallMethod_ByParamObj("File_Query",sendData)
    },

    // async api_File_GetFilePathReal ( fileSystemId)
    // {
    //     var sendData={
    //         fileSystemId:fileSystemId,
    //     };
    //
    //     return this.getApiManager_File().api_CallMethod_ByParamObj("File_GetFilePathReal",sendData)
    // },
    //
    // async api_File_GetDownloadUrl ( fileSystemId)
    // {
    //     var sendData={
    //         fileSystemId:fileSystemId,
    //     };
    //
    //     return this.getApiManager_File().api_CallMethod_ByParamObj("File_GetDownloadUrl",sendData)
    // },

    async api_File_RemoveFile ( fileSystemId)
    {
        var sendData={
            fileSystemId:fileSystemId,
        };
        return this.getApiManager_File().api_CallMethod_ByParamObj("File_RemoveFile",sendData)
    },

    async api_File_UpdateFile ( files, ownerSystemId, sFileUseType)
    {
        var sendData={
            ownerSystemId:ownerSystemId,
            sFileUseType:sFileUseType,
        };

        return this.getApiManager_File().api_UpdateFile("File_UpdateFile",files,sendData)
    },

    async api_File_UpdateOrderKey (ownerSystemId, lstOrderKeyItems)
    {
        let method='File_UpdateOrderKey?ownerSystemId='+ownerSystemId;

        return this.getApiManager_File().api_CallMethod_SendObj(method,lstOrderKeyItems)
    },

    //---------------------------------------
    getApiManager_General() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"", this.getAxiosManager(),null)
    },
    //---------------Upload------------------------

    async api_UploadFiles(uploadType,fileName,files,fileNamdAppendShortId)
    {
        var sendData={
            uploadType:uploadType,
            fileName:fileName,
            fileNamdAppendShortId:fileNamdAppendShortId,
        };
        return this.getApiManager_General().api_UpdateFile("UploadFile",files,sendData)
    },
    //---------------------------------------
}