<template>
  <span>
    <input ref="file" type="file" class="form-control" placeholder="" style="" hidden @change="selectFile"  >
<!--    multiple-->
<!--    accept=".png,.jpg,.jpeg,.gif"-->




    <div class="row">
      <div class="col-1" v-for="(item,index) in this.files" :key="index">
        <img :src="toDownloadLink(item) + '?width=80&height=80'" @click="imageClick(item)"/>

        <a class="btn btn-danger btn-dangerU" @click="DataItem_Remove(item)" ><i class="far fa-trash-alt"></i></a>

<!--        @click.prevent="showImage(item)"-->


<!--          <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>-->
<!--          <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>        -->
      </div>
      <div class="d-flex align-items-center">
        <button class="btn btn-primary btn-primaryU" @click="fileUplaodControlClick"><i class="fa fa-upload"></i>上傳圖片</button>
      </div>
    </div>
  </span>
</template>

<script>

/**
 <FileUploadList emmit-back-param="undefined" :owner-system-id="this.systemId" file-use-memo="productImage"></FileUploadList>


 **/
import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";
import apiServerUtil from "@/pages/common/apiServerUtil";
// import apiUBossCommonFile from "@/pages/commonUBoss/apiUBossCommonFile";
import apiUBossCommonFileSec from "@/pages/commonUBoss/apiUBossCommonFileSec";

export default {
  components: {},
  props: ["emmitBackParam","ownerSystemId","fileUseMemo"],
  data: function () {
    return {
      selectdFiles:undefined,
      files:[],
    };
  },
  methods: {
    fileUplaodControlClick(){
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    async selectFile(event){
      this.selectdFiles=event.target.files;
      await this.uploadFiles();
      event.target.value=null;

      // doc event.target.files 的內容
      // lastModified: 1612407992013
      // lastModifiedDate: Thu Feb 04 2021 11:06:32 GMT+0800 (台北標準時間) {}
      // name: "GPKlogo2.png"
      // size: 45641
      // type: "image/png"
      // webkitRelativePath: ""
    },
    async uploadFiles(){
      let uploadFiles=this.selectdFiles;

      let result=await apiUBossCommonFileSec.api_File_UpdateFile(uploadFiles,this.ownerSystemId,this.fileUseMemo);

      this.files=result;
      this.$emit('uploadSuccess',this.emmitBackParam,result)

    },
    async loadData() {
      let data=await apiUBossCommonFileSec.api_File_Query(this.ownerSystemId)

      this.files=data
    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.files,"SystemId","OrderKey")
      this.DataItem_UpdateOrderKey()
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.files,"SystemId","OrderKey")
      this.DataItem_UpdateOrderKey()
    },
    DataItem_Remove(item){
      apiUBossCommonFileSec.api_File_RemoveFile(item.SystemId).then(()=>{
        this.loadData()
      })
    },
    async DataItem_UpdateOrderKey(){
      let infoOrderKey=this.files.map((value,index) => {  // eslint-disable-line
        return {
          SystemId:value.SystemId,
          OrderKey:value.OrderKey,
        };
      });

      await apiUBossCommonFileSec.api_File_UpdateOrderKey(this.ownerSystemId,infoOrderKey)
    },
    toDownloadLink(item){
      let link=apiServerUtil.toDownloadLink(item.Url);

      return link;
    },
    downloadFile(item){
      let link=apiServerUtil.toDownloadLink(item.Url);

      util.downloadFile(link)
    },
    imageClick(item){
      this.$emit('imageClick',item.Url)
    }
  },
  mounted() {


    this.loadData()
  },
}
</script>

<style scoped>
#tblMain{
  /*width: 1000px;*/
}
.btn-dangerU{
  color: white !important;
  margin: 5px;
  width: 40px;
}
.btn-primaryU{
  color: white !important;
  margin: 5px;
  width: 120px;
}
</style>
