<template>
  <div class="" >
    <b-modal ref="my-modal" size="lg" hide-footer title="請輸入">
      <a class="btn btn-primary" @click="DataItem_AddLast"><i class="fas fa-plus-circle"></i></a>
      <table class="table table-hover" id="tblMain">
        <thead class="thead-light">
        <tr>
          <th class="text-center">功能</th>
          <th>標題1</th>
          <th>標題2</th>
          <th>內容</th>
          <th>連結文字</th>
          <th>連結</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in this.datas" :key="index">
          <td class="text-center">
            <a class="btn btn-danger" @click="DataItem_Remove(item)"><i class="far fa-trash-alt"></i></a>
            <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>
            <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>
          </td>
          <td >
            <input type="text" class="form-control" placeholder="" v-model="item.title1">
          </td>
          <td >
            <input type="text" class="form-control" placeholder="" v-model="item.title2">
          </td>
          <td >
            <textarea class="form-control" v-model="item.content1" rows="3"></textarea>
          </td>
          <td >
            <input type="text" class="form-control" placeholder="" v-model="item.linkText">

          </td>
          <td >
            <input type="text" class="form-control" placeholder="" v-model="item.linkUrl">
          </td>
        </tr>

        </tbody>
      </table>

      <b-button class="mt-2" variant="btn btn-primary" block @click="toggleModal()">儲存</b-button>
      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>
  </div>

</template>

<script>

//use this.$refs.settingSliderImage.showModal();
//use < ref="" :webPart="" v-on:returnOk="blockWebPartSelectorReturnOk">
//import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";

import util from "@/pages/common/util";
import arrayUtil from "@/pages/common/arrayUtil";

export default {
  // props:["partImage"],
  // components: {
  //   HelloWorld
  // }

  props: {
    partImage: {
      type: Object,
      default () {  //因為這個是 WebPartImageDesigner 是單體，而mounted 時，需要給一個空的物件，以免上面在bind時，會有錯誤訊息
        return {
          LinkUrl:"",
          LinkTarget:""
        }
      }
    },
  },
  data: function() {
    return {
      showThis:false,
      datas:[],
    };
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')

      // onePageUtil.webPartHtml_Save(this.areaId,this.blockId,this.formData.content)

      this.$emit('returnOk');
    },
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    submit(){
      for (let i = 0; i < this.exchangeRate.Details.length; i++) {
        let item = this.exchangeRate.Details[i];

        if (item.Year===""){
          util.showErrorMessage("第"+i+1+" 行，「年」為必填欄位")
          return;
        }
        if (item.Month===""){
          util.showErrorMessage("第"+i+1+" 行，「月」為必填欄位")
          return;
        }
        if (item.Type===""){
          util.showErrorMessage("第"+i+1+" 行，「幣別」為必填欄位")
          return;
        }
        if (item.Rate===""){
          util.showErrorMessage("第"+i+1+" 行，「匯率」為必填欄位")
          return;
        }
      }

      // apiGPK.api_Save_ExchangeRate(this.exchangeRate.SystemId,"",this.exchangeRate).then((data)=>{
      //   util.ShowMessage('儲存成功')
      // })
    },

    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.datas,"SystemId","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.datas,"SystemId","OrderKey")
    },
    DataItem_AddLast(){
      let newItem={
        Id:util.uuid(),
        title1:"",
        title2:"",
        content1:"",
        linkText:"",
        linkUrl:"",
        OrderKey:"999",
      };
      newItem.SystemId=util.uuid();

      arrayUtil.orderAppendLast(newItem,this.datas,"OrderKey")
    },
    DataItem_Insert(currentItem){
      let newItem={
        Id:util.uuid(),
        title1:"",
        title2:"",
        content1:"",
        linkText:"",
        linkUrl:"",
        OrderKey:"0",
      };
      newItem.SystemId=util.uuid();

      arrayUtil.orderInsert(newItem,currentItem,this.datas,"SystemId","OrderKey")
    },
    DataItem_Remove(item){
      this.datas= this.datas.filter(s => s.SystemId !==item.SystemId);
    },


  },
  mounted() {
    this.loadData()
  },
}
</script>
