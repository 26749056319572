<template>
  <div>
    <div class="jumbotron text-center">

      <h3 class="display-8"></h3>
      <p class="lead"></p>
      <hr>
      <p class="lead">
        <a class="btn btn-primary btn-sm" href="/" role="button">回首頁</a>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  //name: "Message",
  // props: [""],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>

</style>