<template>
  <div style=" height: 100vh;margin:0px;padding:0px;overflow:hidden">
<!--    <MarqueeText :duration="20" :repeat="10">-->
<!--    <MarqueeText :duration="settingDuration">-->
<!--    <MarqueeText>-->
<!--      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.-->
<!--    </MarqueeText>-->

<!--    <iframe width="100%" height="100%" style="overflow:hidden;height:100%;width:100%" src="https://www.youtube.com/embed/ptv33WUJyI4?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->

    <span v-if="carousels[show].isVideo">
      <iframe width="100%" height="100%" style="overflow:hidden;height:100%;width:100%" :src="carousels[show].img" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </span>
    <span v-else-if="!carousels[show].isVideo">
      <div class="bg" :style="'background-image: url(' + carousels[show].img + ')'"></div>
    </span>
    <span v-else>

    </span>

<!--    <marquee-text >-->
<!--      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.-->
<!--    </marquee-text>-->

    <!-- .bg 是另外增加頁面效果，可刪-->
<!--    <div class="bg" :style="'background-image: url(' + carousels[show].img + ')'"></div>-->
<!--    <iframe id="video" src="//www.youtube.com/embed/5iiPC-VGFLU?rel=0&autoplay=1" frameborder="0" allowfullscreen></iframe>-->

<!--        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ptv33WUJyI4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
<!--        <iframe width="100%" height="100%" style="overflow:hidden;height:100%;width:100%" src="https://www.youtube.com/embed/ptv33WUJyI4?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->

<!--    style="max-height: 400px; height: 100vh;"-->
<!--    <video-background src="https://www.youtube.com/embed/ptv33WUJyI4">-->
<!--      <h1 style="color: white;">Hello welcome!</h1>-->
<!--    </video-background>-->

<!--    <youtube  :video-id="videoId" :player-vars="playerVars" player-width="100%" player-height="100%" style="overflow:hidden;height:100%;width:100%"></youtube>-->

    <div class="container">

      <!-- carousel-->
      <!-- data-auto 是否自動輪播，預設false。 data-delay 間隔多久，預設3000。-->
      <!-- data-arrows, data-dots 是否有箭頭、點點-->
      <div class="carousel-wrap" ref="carousel" data-auto="true" data-delay="3000" data-arrows="true" data-dots="true" @mouseenter.stop="toggleTimer = false" @mouseleave.stop="toggleTimer = true" @touchstart.stop="touchStart" @touchmove.stop="touchMove" :style="'min-height:' + minHeight ">

        <!-- carousel items-->
        <keep-alive>

          <transition :name="carouselName">

<!--            <div class="item" v-for="(s, i) in carousels" :key="i"><a :href="s.href"><img :src="s.img"/></a></div>-->
<!--            //v-if="show == i"-->

          </transition>

        </keep-alive>

        <!-- button: prev, next-->
<!--        <div class="arrows-group" v-if="arrows"><a class="button-prev" href="#" @click.prevent="toPrev"><img src="//akveo.github.io/eva-icons/outline/png/128/arrow-ios-back-outline.png"/></a><a class="button-next" href="#" @click.prevent="toNext"><img src="//akveo.github.io/eva-icons/outline/png/128/arrow-ios-forward-outline.png"/></a></div>-->

<!--        <div class="dot-group" v-if="dots"><a v-for="(l, i) in len" :key="i" href="#" :class="{ 'active': show == i }" @click.prevent="show = i"></a></div>-->

      </div>

    </div>
  </div>
</template>

<script>

// import VideoBackground from 'vue-responsive-video-background-player'
// import MarqueeText from 'vue-marquee-text-component'

export default {
  name: 'MyComponent',
  // components: {VideoBackground },
  // components: {MarqueeText },
  data: function () {
    return {
      settingDuration:30,
      videoId: 'ptv33WUJyI4',
      playerVars: {
        autoplay: 1
      },
      carouselName: 'carousel-next',
      carousels: [
        {
          img: 'https://picsum.photos/900/506?image=508',
          href: "#",
          isVideo:false,
        },
        {
          img: 'https://picsum.photos/900/506?image=1068',
          href: "#",
          isVideo:false,
        },
        {
          img: 'https://www.youtube.com/embed/ptv33WUJyI4?autoplay=1',
          href: "#",
          isVideo:true,
        }
      ],
      len: 0,
      show: 0,
      xDown: null, // for swiper
      yDown: null, // for swiper
      autoplay: false, // 是否自動輪播
      timer: null, // auto play
      timerDelay: 10000, // 自動輪播間隔秒數
      toggleTimer: true, // pause auto play
      minHeight: 0, // 抓最小高度
      arrows: true, // 是否要有箭頭，預設 true
      dots: true // 是否要有小點，預設 true
    };
  },
  methods: {
    toNext() {
      this.carouselName = 'carousel-next';
      this.show + 1 >= this.len ? this.show = 0 : this.show = this.show + 1;
    },
    toPrev() {
      this.carouselName = 'carousel-prev';
      this.show - 1 < 0 ? this.show = this.len - 1 : this.show = this.show - 1;
    },
    // swiper event(for mobile)
    touchStart(e) {
      this.xDown = e.touches[0].clientX;
      this.yDown = e.touches[0].clientY;
    },
    touchMove(e) {
      const _this = this;
      if(!this.xDown || !this.yDown) { return; }

      let xUp = e.touches[0].clientX;
      let yUp = e.touches[0].clientY;

      let xDiff = this.xDown - xUp;
      let yDiff = this.yDown - yUp;

      if(Math.abs(xDiff) > Math.abs(yDiff)) {
        xDiff > 0 ? _this.toNext() : _this.toPrev();
      }
      this.xDown = null;
      this.yDown = null;
    },
    // 自動輪播
    autoPlay() {
      setInterval(() => {
        if(this.toggleTimer) this.toNext();
      }, this.timerDelay);
    }
  },
  mounted() {
    this.len = this.carousels.length;

    const data = this.$refs.carousel.dataset;
    this.autoplay = data.auto == 'true';
    this.timerDelay = Number(data.delay) || 3000;
    this.arrows = data.arrows == 'true';
    this.dots = data.dots == 'true';

    if(this.autoplay) this.autoPlay();

    window.addEventListener('load', () => {
      this.minHeight = this.$refs.carousel.offsetHeight + 'px';
    });
  },
}
</script>

<style scoped>
*, *::before, *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html, body, .container {
  height: 100vh;
  overflow-y: hidden;
}
.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 900px;
}
.bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /*-webkit-filter: blur(8px);*/
  /*filter: blur(8px);*/
  /*-webkit-transition: background-image 0.5s;*/
  /*-o-transition: background-image 0.5s;*/
  /*transition: background-image 0.5s;*/
}
.bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.2)));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
}

</style>
