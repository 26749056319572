export default {
    // test:[
    //     {group:"g1",group2:"sg1",name:"xxx"},
    //     {group:"g1",group2:"sg2",name:"xxx"},
    //     {group:"g2",group2:"sg11",name:"xxx"},
    //     {group:"g2",group2:"sg22",name:"xxx"},
    // ]
    groupByToObject(collection, property) {
        const groupBy = key => array =>
            array.reduce((objectsByKeyValue, obj) => {
                const value = obj[key];
                objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
                return objectsByKeyValue;
            }, {});

        const groupByBrand = groupBy(property);


        return groupByBrand(collection);
    },
    groupByToArray(collection, property) {
        var i = 0, val, index,
            values = [], result = [];
        for (; i < collection.length; i++) {
            val = collection[i][property];
            index = values.indexOf(val);
            if (index > -1)
                result[index].push(collection[i]);
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
        return result;
    },
    filterByProperty(collection,property,filterTerm){
        filterTerm = filterTerm.toLowerCase()
        // let data= collection.filter(item =>
        //     item => item[property].toLowerCase().includes(filterTerm)
        // )

        var data = collection.filter(function(item){
            let isInclude=item[property].toLowerCase().includes(filterTerm)
            if (isInclude){
                return item
            }
        });

        return data
        // return collection.filter(item => {
        //     return searchFields.some(field => item[field].toLowerCase().includes(filterTerm))
        // })
    },
    filterByPropertyArray(collection,property,filterTerms){
        // filterTerm = filterTerm.toLowerCase()
        // let data= collection.filter(item =>
        //     item => item[property].toLowerCase().includes(filterTerm)
        // )

        let filterTerms_lowercase = filterTerms.map(name => name.toLowerCase());

        var data = collection.filter(function(item){
            for (let term of filterTerms_lowercase) {
                let isInclude=item[property].toLowerCase().includes(term)
                if (isInclude){
                    return item
                }
            }
        });

        return data
        // return collection.filter(item => {
        //     return searchFields.some(field => item[field].toLowerCase().includes(filterTerm))
        // })
    },
    SortByOrderKey(datas,secondSortFieldName){
        if (secondSortFieldName){
            //有第二欄位
            return datas.sort(function(a, b) {
                if(a.ORDERKEY === "" || a.ORDERKEY === null) return 1;
                if(b.ORDERKEY === "" || b.ORDERKEY === null) return -1;
                if(Number(a.ORDERKEY) ===Number(b.ORDERKEY)) {
                    //第二欄位就只是文字排序
                    if(a[secondSortFieldName] === "" || a[secondSortFieldName] === null) return 1;
                    if(b[secondSortFieldName] === "" || b[secondSortFieldName] === null) return -1;
                    if(a[secondSortFieldName] ===b[secondSortFieldName]) {
                        return 0;
                    }else{
                        return a[secondSortFieldName] < b[secondSortFieldName] ? -1 : 1;
                    }
                }else{
                    return Number(a.ORDERKEY) < Number(b.ORDERKEY) ? -1 : 1;
                }
            });
        }else{
            return datas.sort(function(a, b) {
                if(a.ORDERKEY === "" || a.ORDERKEY === null) return 1;
                if(b.ORDERKEY === "" || b.ORDERKEY === null) return -1;
                if(Number(a.ORDERKEY) ===Number(b.ORDERKEY)) return 0;
                return Number(a.ORDERKEY) < Number(b.ORDERKEY) ? -1 : 1;
            });
        }

    },

    UniqueArray(array){
        return array.filter((value, index, self)=>{
            return self.indexOf(value) === index;
        });
    },
    UniqueByObjectPropperty(array,property,trimEmptyElement){
        let result= [...new Set(array.map(s=>s[property]))];

        if (trimEmptyElement){
            return result.filter(s=>s!==null && s!==undefined && s!=="")
        }else{
            return result
        }
    },
    pushIfNotExist(array,value){
        if(array.indexOf(value) === -1) {
            array.push(value);
        }
    },
    swap(arrWork, x, y) {
        if (arrWork.length === 1) return arrWork;
        arrWork.splice(y, 1, arrWork.splice(x, 1, arrWork[y])[0]);
        return arrWork;
    },
    //vueUtil.orderMove(true,started_data.SystemId,state.storeFormData.Sections)
    orderMoveSimple:function(isMoveDown,item,arrWork) {
        let index=arrWork.findIndex(s=>s===item)
        if (isMoveDown){
            if (index !==arrWork.length-1){
                this.swap(arrWork,index,index+1)
            }
        }else{
            if (index !==0){
                this.swap(arrWork,index,index-1)
            }
        }
    },
    orderMoveEndReorder:function(arrWork,orderKeyFieldName="OrderKey") {
        let i=1;
        arrWork.forEach((item)=>{
            item[orderKeyFieldName]=i.toString()
            i++
        })
    },
    orderMove:function(isMoveDown,systemId,arrWork,idFieldName="SystemId",orderKeyFieldName="OrderKey") {
        let index=arrWork.findIndex(s=>s[idFieldName]===systemId)

        if (isMoveDown){
            if (index===arrWork.length-1){
                return arrWork
            }
            arrWork=this.moveArrayItemToNewIndex(arrWork,index,index+1)
        }else{
            if (index===0){
                return arrWork
            }

            arrWork=this.moveArrayItemToNewIndex(arrWork,index,index-1)
        }

        let i=1;
        for (let arrItem of arrWork) {
            arrItem[orderKeyFieldName]=i.toString()
            i++
        }

        return arrWork;
    },
    orderInsert:function(insertItem,currentItem, arrWork,idFieldName="SystemId",orderKeyFieldName="OrderKey"){
        let index = arrWork.findIndex(x => x[idFieldName] ===currentItem[idFieldName]);

        insertItem[orderKeyFieldName]=index+1
        arrWork.push(insertItem)

        arrWork=this.moveArrayItemToNewIndex(arrWork,arrWork.length-1,index+1)


        //對其後的元素OrderKey+1
        for (let i = index+2; i < arrWork.length ; i++) {
            arrWork[i][orderKeyFieldName]=i
            i++
        }

    },
    orderAppendLast:function(appendItem, arrWork,orderKeyFieldName="OrderKey"){
        let maxOrderKey=Math.max.apply(Math, arrWork.map(function(o) { return o[orderKeyFieldName]; }))

        appendItem[orderKeyFieldName]=String(maxOrderKey+1);
        arrWork.push(appendItem)
    },
    orderAppend:function(appendItem, arrWork){
        arrWork.push(appendItem)
    },
    moveArrayItemToNewIndex :function(arr, old_index, new_index){
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    },
    removeByProperty(arrWork,propertyName,value){
        var index = arrWork.findIndex(function(o){
            return o[propertyName] === value;
        })
        if (index !== -1) arrWork.splice(index, 1);
        return arrWork
    },
    removeByPredicate(arrWork,predicate){
        let index=arrWork.findIndex(predicate);
        arrWork.splice(index, 1);

        return arrWork
    },
    //一並去除空白的元素
    //return _.filter(arrayUtil.removeDupicate(group1s), Boolean);
    removeDupicate(arrWork){
        var unique = arrWork.filter(function(elem, index, self) {
            return self.indexOf(elem) === index;
        });

        return unique;
    },


}
