<template>
  <div :id="`popover-1-${field.Id}`">
<!--    {{field.InputType}}-->

    <div v-if="field.InputType===this.$formUtil.InputType_Text">
      <div class="row">
        <label for="" :class="labelClass" class=" formFieldLabel"><span v-html="field.Title"/> <span class="require" v-if="isMustInput(field)">*</span></label>
        <div  :class="inputClass" class="formFieldInputBlock">
          <validation-provider :rules="ruleString(field)" v-slot="{ errors }"  :name="field.Title" >
            <input type="text" class="form-control" placeholder="" v-model="fieldValue" :class="getCssClass(field)" >
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
    </div>
    <div v-else-if="field.InputType===this.$formUtil.InputType_TextArea">
      <div class="row">
        <label for="" :class="labelClass" class=" formFieldLabel"><span v-html="field.Title"/> <span class="require" v-if="isMustInput(field)">*</span></label>
        <div :class="labelClass" class="formFieldInputBlock">
          <validation-provider :rules="ruleString(field)" v-slot="{ errors }"  :name="field.Title" >
            <textarea class="form-control" placeholder="" v-model="fieldValue" :class="getCssClass(field)" rows="5" ></textarea>
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
    </div>
    <div v-else-if="field.InputType===this.$formUtil.InputType_Select">
      <div class="row">
        <label for="" :class="labelClass" class=" formFieldLabel"><span v-html="field.Title"/> <span class="require" v-if="isMustInput(field)">*</span></label>
        <div :class="labelClass" class="formFieldInputBlock">
          <validation-provider :rules="ruleString(field)" v-slot="{ errors }" :name="field.Title">
            <select class="form-control" v-model="fieldValue" :class="getCssClass(field)">
              <option v-for="(option,index) in splitOptions(field.Values)" v-bind:key="option"  v-bind:selected="index===0">{{option}}</option>
            </select>
            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
    </div>
    <div v-else-if="field.InputType===this.$formUtil.InputType_CheckBox">
      <div class="row">
        <label for="" :class="labelClass" class=" formFieldLabel"><span v-html="field.Title"/> <span class="require" v-if="isMustInput(field)">*</span></label>
        <div :class="labelClass" class="formFieldInputBlock">
          <validation-provider :rules="ruleString(field)" v-slot="{ errors }" :name="field.Title">
            <li v-for="(option) in splitOptions(field.Values)" v-bind:key="option" style="list-style-type: none;">
              <label class="checkbox-inline" >
                <!--              需要設定name,否則vee會無法運作-->
              <input class="myCheckbox" type="checkbox" v-model="fieldValue" :value="option" :name="field.Id">{{option}}</label>
            </li>

            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
    </div>
    <div v-else-if="field.InputType===this.$formUtil.InputType_Radio">
      <div class="row">
        <label for="" :class="labelClass" class=" formFieldLabel"><span v-html="field.Title"/> <span class="require" v-if="isMustInput(field)">*</span></label>
        <div :class="labelClass" class="formFieldInputBlock">
          <validation-provider :rules="ruleString(field)" v-slot="{ errors }" :name="field.Title">

<!--          橫式-->
<!--          <label class="radio-inline"  v-for="(option) in splitOptions(field.Values)" v-bind:key="option"><input class="myRadio" type="radio" v-model="fieldValue" :value="option">{{option}}</label>-->


            <div class="form-check" v-for="(option,index) in splitOptions(field.Values)" v-bind:key="option">
<!--              需要設定name,否則vee會無法運作-->
              <input class="form-check-input" type="radio" v-model="fieldValue" :value="option"  :id="'rdo'+field.Id + index" :name="field.Id">
              <label class="form-check-label" :for="'rdo'+field.Id+index" >
                {{option}}
              </label>
            </div>

            <span class="error_msg">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
    </div>
    <div v-else-if="field.InputType===this.$formUtil.InputType_Shop">
      <div class="row">
        <table class="table table-hover" id="tblMain" style="margin-left: 40px;margin-top: 40px">
          <thead class="thead-light">
          <tr>
            <th class="text-center">說明<th>
            <th class="text-center">數量</th>
          </tr>
          </thead>
          <tbody>
          <tr >
            <td class="text-center">
              方案一  大人 $500
            </td>
            <td width="200px" >
              <input type="number" class="form-control" id="name" name="name" placeholder="">
            </td>
          </tr>

          <tr >
            <td class="text-center">
              方案一  小孩 $200
            </td>
            <td >
              <input type="number" class="form-control" id="name" name="name" placeholder="">
            </td>
          </tr>

          <tr >
            <td class="text-center">
              方案二  大人 $500
            </td>
            <td >
              <input type="number" class="form-control" id="name" name="name" placeholder="">
            </td>
          </tr>

          <tr >
            <td class="text-center">
              方案二  小孩 $200
            </td>
            <td >
              <input type="number" class="form-control" id="name" name="name" placeholder="">
            </td>
          </tr>


          <tr >
            <td class="text-center">
              合計
            </td>
            <td >
              $1,000
            </td>
          </tr>

          </tbody>
        </table>

      </div>
    </div>


  </div>
</template>

<script>

export default {
  //name: "FormFieldDesigner",
  props: ["field","fieldValue"],
  data: function () {
    return {
      id: "",
      labelClass:"col-sm-12",
      inputClass:"col-sm-12",
      // labelClass:"col-sm-3",
      // inputClass:"col-sm-9",
    };
  },
  watch:{
    'fieldValue'(newValue){
      this.$emit("update:field-value", newValue)
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    getCssClass(field){
      if (this.isMustInput(field)){
        return "requireField";
      }
    },
    isMustInput:function(field){
      if (field.ValidateRule.includes("required")){
        return true;
      }
      return false
    },
    ruleString:function(field){
      if (this.isMustInput(field)){
        return "required";
      }
    },
    splitOptions(values){
      return values.split('\n');
    },
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>
.myCheckbox .myRadio{
  margin: 10px;
}
.formFieldLabel{
  padding-left: 30px;
  padding-bottom: 0px;
}
.formFieldInputBlock{
  /*padding-top: 0px;*/
  /*padding-bottom: 20px;*/
}
.error_msg{
  color: red;
}

</style>
