<template>
  <div class="" >
    <b-modal ref="my-modal" hide-footer title="請選擇要放置的內容">
      <div class="d-block text-center">
        <!--        <h3>Hello From My Modal!</h3>-->
      </div>

<!--      <div class="form-group row">-->
<!--        <label for="" class="col-sm-2">內容</label>-->
<!--        <div class="col-sm-10">-->
<!--          &lt;!&ndash;            <input type="text" class="form-control" id="txtCONTENT" placeholder="" v-model="formData.CONTENT" :class="{errorAdm: validation.hasError('formData.CONTENT')}">&ndash;&gt;-->


<!--          &lt;!&ndash;          <div class="message">{{ validation.firstError('formData.CONTENT') }}</div>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
      <a class="btn btn-primary btn-lg" href="#">收入</a> <br/>
      <a class="btn btn-danger btn-sm first" href="#">營業收入</a>
      <a class="btn btn-info btn-sm" href="#">營業外收入</a>

      <br>
      <a class="btn btn-primary btn-lg" href="#">支出</a><br/>
      <a class="btn btn-info btn-sm first" href="#">薪資</a>
      <a class="btn btn-info btn-sm" href="#">雜支</a>

      <br>
      <a class="btn btn-primary btn-lg" href="#">設備</a><br/>
      <a class="btn btn-info btn-sm first" href="#">存貨</a>
      <a class="btn btn-info btn-sm" href="#">辦公設備</a>

      <hr>
      <div class="form-group row">
        <label for="" class="col-sm-2">日期</label>
        <div class="col-sm-10">
          <input type="date" class="form-control" id="name" name="name" placeholder="">
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="col-sm-2">金額</label>
        <div class="col-sm-10">
          <input type="number" class="form-control" id="name" name="name" placeholder="">
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="col-sm-2">備註</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" id="name" name="name" placeholder="">
        </div>
      </div>

      <b-button class="mt-2" variant="btn btn-primary" block @click="toggleModal()">儲存</b-button>
      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>
  </div>

</template>

<script>

//use this.$refs.settingSliderImage.showModal();

export default {
  name: 'WebPartHtmlSetting',
  props:["areaId","blockId"],
  // components: {
  //   HelloWorld
  // }

  data: function() {
    return {
      showThis:false,
    };
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')

      // onePageUtil.webPartHtml_Save(this.areaId,this.blockId,this.formData.content)

      this.$emit('returnOk');
    },
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
.btn-sm{
  margin: 5px;
}
.first{
  margin-left: 50px;
}

</style>