

<script>
import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";
import securityUtil from "@/pages/common/securityUtil";

export default {
  //name: "DesignTimeSupport",
  props:{
    designTime: {
        type: Boolean,
        required: false
    }
  },
  data: function () {
    return {
      timeType:this.$onePageUtil.OnePage_TimeType_Run,
      // timeType:this.$onePageUtil.OnePage_TimeType_Design,
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  created() {
    // this.timeType=onePageUtil.getTimeType()
    // alert('created');
    let s=this.$route.query.s

    // if (s){
    //   onePageUtil.setTimeType(this.$onePageUtil.OnePage_TimeType_Design)
    // }else{
    //   onePageUtil.setTimeType(this.$onePageUtil.OnePage_TimeType_Run)
    // }
    if (s){
      s=atob(s)
      let desS=securityUtil.decryptDes(s,"taylor")

      if (desS.startsWith("taylor")){
        onePageUtil.setTimeType(this.$onePageUtil.OnePage_TimeType_Design)
      }else{
        onePageUtil.setTimeType(this.$onePageUtil.OnePage_TimeType_Run)
      }
    }else{
      if (this.designTime){
        onePageUtil.setTimeType(this.$onePageUtil.OnePage_TimeType_Design)
      }else{
        onePageUtil.setTimeType(this.$onePageUtil.OnePage_TimeType_Run)
      }
    }


      // onePageUtil.setTimeType(this.$onePageUtil.OnePage_TimeType_Design)

    this.timeType=onePageUtil.getTimeType()


  },
}
</script>

<style scoped>

</style>
