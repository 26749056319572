<template>
  <div>
<!--    由InputColorPicker套件提供-->
    <v-input-colorpicker  v-model="propsValue" style="border: black;border-style:solid;width:100px"/>

<!--    <v-color-picker-->
<!--        v-model="propsValue" style="border: black;border-style:solid"-->
<!--    ></v-color-picker>-->
  </div>
</template>

<script>
/**
 <MyColorPicker v-model="Header.BackgroundColor"></MyColorPicker>
 **/


export default {
  // name: "MyColorPicker",
  props: ["value"],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      propsValue:this.value,
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
