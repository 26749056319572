<template>
  <div >

    <div v-if="this.timeType=== this.$onePageUtil.OnePage_TimeType_Design">
      <a class="btn btn-primary" @click="footerSetting"><i class="fa fa-cog"></i> </a>
    </div>
<!--    <froala id="edit" :tag="'textarea'" :config="config" v-model="Footer.HtmlContent"></froala>-->
    <span v-html="Footer.HtmlContent"></span>
    <COM_Footer_Setting ref="footerSetting" :footer="Footer" v-if="Footer" :page-id="pageId"></COM_Footer_Setting>
  </div>
</template>

<script>
import COM_Footer_Setting from "./COM_Footer_Setting.vue";
import DesignTimeSupport from "@/pages/featureOnePage/base/DesignTimeSupport.vue";

export default {
  components: {COM_Footer_Setting},
  props: ["Footer","pageId"],
  mixins: [DesignTimeSupport],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      config: {
        events: {
          initialized: function () {
            console.log('initialized')
          }
        }
      },
    };
  },
  methods: {
    footerSetting(){
      this.$refs.footerSetting.showModal();
    },
    async loadData() {

    },
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
