<template>
  <div>

    <section class="h-100 gradient-form" style="background-color: #eee;">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-xl-10">
            <div class="card rounded-3 text-black">
              <div class="row g-0">
                <div class="col-lg-12">
                  <div class="card-body p-md-5 mx-md-4">

<!--                    <div class="text-center">-->
<!--                      <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"-->
<!--                           style="width: 185px;" alt="logo">-->
<!--                      <h4 class="mt-1 mb-5 pb-1">We are The Lotus Team</h4>-->
<!--                    </div>-->

                    <form>
                      <p>請輸入您的PassCode</p>

                      <div class="form-outline mb-4">
                        <input type="text" id="form2Example11" class="form-control"
                               placeholder="請輸入您的PassCode" v-model="password" />
                        <label class="form-label" for="form2Example11"></label>
                      </div>

<!--                      <div class="form-outline mb-4">-->
<!--                        <input type="password" id="form2Example22" class="form-control" />-->
<!--                        <label class="form-label" for="form2Example22">Password</label>-->
<!--                      </div>-->

                      <div class="text-center pt-1 mb-5 pb-1">
                        <button class="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="button" @click="login()">登入</button>
<!--                        <a class="text-muted" href="#!">Forgot password?</a>-->
                      </div>

<!--                      <div class="d-flex align-items-center justify-content-center pb-4">-->
<!--                        <p class="mb-0 me-2"></p>-->
<!--                        <button type="button" class="btn btn-outline-danger">Create new</button>-->
<!--                      </div>-->

                    </form>

                  </div>
                </div>
<!--                <div class="col-lg-6 d-flex align-items-center gradient-custom-2">-->
<!--                  <div class="text-white px-3 py-4 p-md-5 mx-md-4">-->
<!--                    <h4 class="mb-4">We are more than just a company</h4>-->
<!--                    <p class="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod-->
<!--                      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud-->
<!--                      exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>
</template>

<script>
/**

 **/
import util from "@/pages/common/util";

export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      propsValue: this.value,
      formData: {
        name: "",
      },
      password:"",
    };
  },
  watch: {
    propsValue(newVal) {
      //this.('input', newVal);
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.propsValue = newVal
    }
  },
  methods: {
    //給予value屬性預設值
    initValue() {
      const isMyObjectEmpty = !Object.keys(this.value).length;

      if (isMyObjectEmpty) {
        let template = {...this.datasDemo};
        this.value = template;
      }
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    login(){
      if (this.password==="1234"){//QQ
        //redirect to
        this.$router.push({ name: 'Index2', query: { id: 2 }})
      }else{
        util.showErrorMessage("您的密碼不正確")
      }
    }
  },
  mounted() {
    // this.initValue()
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
