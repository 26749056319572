import { render, staticRenderFns } from "./MyCSSBackground.vue?vue&type=template&id=5f23938b&scoped=true&"
import script from "./MyCSSBackground.vue?vue&type=script&lang=js&"
export * from "./MyCSSBackground.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f23938b",
  null
  
)

export default component.exports