<template>
  <div>
    <a class="btn btn-primary" @click="DataItem_AddLast"><i class="fas fa-plus-circle"></i></a>
    <table class="table table-hover" id="tblMain">
      <thead class="thead-light">
      <tr>
        <th class="text-center">功能</th>
        <th>連結文字</th>
        <th>連結</th>
        <th>開新視窗</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) in this.propsValue" :key="index">
        <td class="text-center" width="150px">
          <a class="btn btn-primary" @click="DataItem_Insert(item)"><i class="fas fa-plus-circle"></i></a>
          <a class="btn btn-danger" @click="DataItem_Remove(item)"><i class="far fa-trash-alt"></i></a>
          <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>
          <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>
        </td>
        <td >
          <input type="text" class="form-control" placeholder="" v-model="item.LinkText" style="width: 100px">
        </td>
        <td >
          <input type="text" class="form-control" placeholder="" v-model="item.LinkUrl">
        </td>
        <td>
          <label class="checkbox-inline"><input type="checkbox" v-model="item.LinkTarget" value="_blank">開新視窗</label>
        </td>
      </tr>

      </tbody>
    </table>

  </div>
</template>

<script>
/**

 **/

import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";

export default {
  //name: "MyListUrls",
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
  },
  data: function () {
    return {
      id: "",
      propsValue: this.value,
      formDataDemo: [
        {
          LinkText:"",
          LinkUrl:"",
          LinkTarget:"_blank",
          OrderKey:"",
        }
      ],
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    }
  },
  methods: {
    submit(){

    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.Id,this.propsValue,"Id","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.Id,this.propsValue,"Id","OrderKey")
    },
    DataItem_AddLast(){
      let newItem={
        Id:util.uuid(),
        LinkText:"",
        LinkUrl:"",
        LinkTarget:"_blank",
        OrderKey:"",
      }

      arrayUtil.orderAppendLast(newItem,this.propsValue,"OrderKey")
    },
    DataItem_Insert(currentItem){
      let newItem={
        Id:util.uuid(),
        LinkText:"",
        LinkUrl:"",
        LinkTarget:"_blank",
        OrderKey:"",
      }

      arrayUtil.orderInsert(newItem,currentItem,this.propsValue,"Id","OrderKey")
    },
    DataItem_Remove(item){
      this.propsValue= this.propsValue.filter(s => s.Id !==item.Id);
    },

    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

#tblMain{
  width: 100%;
}
.btn{
  color: white !important;
  margin: 5px;
  width: 40px;
}
</style>