<template>
  <div class="" >
    <div class="ckeditorDiv" style="">
      <MyCkEditor ref="myCkEditor" v-model="propsWebPart.PartHtmlContent"  height="400" width="100%" :file-upload-list-lite_is-show="true" :file-upload-list-lite_owner-system-id="propsWebPart.Id"  :file-upload-list-lite_file-use-memo="this.$u.UBossFileUseType_OnePageWebPartHtml"></MyCkEditor>
<!--      type="inline"-->
    </div>
  </div>

</template>

<script>
import MyCkEditor from "@/pages/commonComponent/MyCkEditor";

export default {
  props: {
    webPart: {},
  },
  components:{MyCkEditor},
  data: function() {
    return {
      /** @type {JWebPartHtml} */
      propsWebPart: this.webPart,
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
