<template>
  <div style="width:1024px">
        <mycarousel :auto="true" :delay="5000" :dots="true" :arrows="true" :carousels="[{img: 'https://picsum.photos/900/506?image=508',href: '#'}, { img: 'https://picsum.photos/900/506?image=1068', href: '#' },{ img: 'https://picsum.photos/900/506?image=509', href: '#' }]">

          <!-- 想改箭頭的話，可以加上slot-->
          <template slot="arrows-prev"><img src="//akveo.github.io/eva-icons/outline/png/128/arrow-ios-back-outline.png"/></template>
          <template slot="arrows-next"><img src="//akveo.github.io/eva-icons/outline/png/128/arrow-ios-forward-outline.png"/></template>

        </mycarousel>
  </div>
</template>

<script>
import mycarousel from './Carousel';

export default {
  name: 'MyComponent',
  components: {mycarousel },
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
      // responsive:{
      //   600:{
      //     items:3
      //   }
      // }
    };
  },
}
</script>

<style scoped>

</style>
