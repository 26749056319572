<template>
<!--  //QQ Css varable-->
  <div style="background-color:rgb(238, 237, 229) !important">
    <COM_Header v-if="FormPage.Header" :header="FormPage.Header" :common="FormPage.Common" :web-parts="FormPage.WebParts" :type="this.$u.UBossOnePageHeaderType_OnePage"></COM_Header>

    <BlockListView v-if="FormPage.Header" area-id="area1" :page-id="FormPageId" :web-parts="FormPage.WebParts" v-on:Save="Save" v-on:Preview="Preview" :page-mode="this.$u.UBossPageMode_Form"></BlockListView>

    <COM_Footer v-if="FormPage.Header" :footer="FormPage.Footer" :page-id="FormPageId" ></COM_Footer>

  </div>

</template>

<script>
import COM_Header from "@/pages/featureOnePage/ubossOnePage/COM_Header";
import BlockListView from "../../featureOnePage/ubossOnePage/BlockListView";
import DesignTimeSupport from "@/pages/featureOnePage/base/DesignTimeSupport";
import COM_Footer from "../../featureOnePage/ubossOnePage/COM_Footer";
import apiServerUtil from "@/pages/common/apiServerUtil";
import apiUBossForm from "@/pages/featureOnePage/util/apiUBossForm";
import util from "@/pages/common/util";
import ubossHostManager from "@/pages/commonUBoss/ubossHostManager";


export default {
  name: 'Index',
  components: {
    COM_Footer,
    BlockListView, COM_Header},
  mixins: [DesignTimeSupport],
  metaInfo: function() {
    //CC
    if (this.FormPage.Common){
      return {
        title: this.FormPage.Common.SiteTitle,
        link: [
          { rel: 'icon', href: this.toDownloadLink(this.FormPage.Common.FavIcon) }
        ],
        meta: [
          { charset: 'utf-8' },
          { property: 'og:title', content: this.FormPage.Common.SiteTitle},
          { property: 'og:description', content: this.FormPage.Common.SiteTitle},
        ]
      }
    }else{
      return {}
    }

  },
  data: function() {
    return {
      FormPage:{},
      FormPageId:"",
    };
  },
  methods:{
    async loadData(){
      let json=await apiUBossForm.api_Form_Get_Web(this.FormPageId)
      this.FormPage=json.Form

      this.$meta().refresh()
    },
    toDownloadLink(imageFileUrl){
      return apiServerUtil.toDownloadLink(imageFileUrl);
    },
    async Save(){
      let record={
        SystemId:this.FormPageId,
        Form:this.FormPage,
      }
      await apiUBossForm.api_Form_Save_Web(JSON.stringify(record))

      util.ShowMessage("儲存成功","訊息")

      location.reload()
    },
    async Preview() {
      let ubossHost=new ubossHostManager();
      let url=ubossHost.formShowFormUrl(this.FormPageId);

      window.open(url, '_blank');
    }
  },
  beforeCreate: function() {

  },
  created() {
    this.FormPageId=this.$route.query.id

    if (!this.FormPageId){
      this.FormPageId="";
    }

    this.loadData()
  },
  mounted() {

  },

}
</script>


<style scoped>
.btn{
  color: white !important;
  margin: 5px;
  width: 100px;
}
</style>
