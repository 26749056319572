<template>
  <div class="" >
<!--    <div class="form-group row" style="">-->
<!--      <label class="col-sm-2 labelClass" style="color: #2c9faf">填寫標題</label>-->
<!--      <div class="col-sm-10">-->
<!--        <input type="text" class="form-control"  placeholder="" v-model="webPart.DescText">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="form-group row" style="">-->
<!--      <label class="col-sm-2 labelClass" style="color: #2c9faf">填寫說明文字</label>-->
<!--      <div class="col-sm-10">-->
<!--        <MyCkEditor ref="myCkEditor" v-model="webPart.Desc"  height="200" width="100%" :file-upload-list-lite_is-show="true" :file-upload-list-lite_owner-system-id="'1234'" file-upload-list-lite_file-use-memo="onePageImage"></MyCkEditor>-->
<!--      </div>-->
<!--    </div>-->
    <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
      <div class="btn-group" role="group">
        <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width: 200px">
          <i class="fas fa-plus-circle"></i>新增
        </button>
        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
          <a class="dropdown-item" @click="DataItem_AddLast($formUtil.InputType_Text)">文字框</a>
          <a class="dropdown-item" @click="DataItem_AddLast($formUtil.InputType_TextArea)">文字框(多行)</a>
          <a class="dropdown-item" @click="DataItem_AddLast($formUtil.InputType_Select)">下拉選擇</a>
          <a class="dropdown-item" @click="DataItem_AddLast($formUtil.InputType_CheckBox)">多選</a>
          <a class="dropdown-item" @click="DataItem_AddLast($formUtil.InputType_Radio)">單選</a>
          <a class="dropdown-item" @click="DataItem_AddLast($formUtil.InputType_Shop)">購買</a>
        </div>
      </div>
    </div>

<!--    <div v-for="(item,index) in this.webPart.Fields" :key="index">-->
<!--      <FormFieldRunner :field="item"></FormFieldRunner>-->
<!--      <WebPartFormDesignerToolbar :ref="'toolbar'+item.Id" :web-part="webPart" :field="item"></WebPartFormDesignerToolbar>-->

<!--    </div>-->

    <table class="" id="tblMain">
        <tr class="tblMainTR" v-for="(item,index) in this.webPart.Fields"  :key="index">
          <td>
              <FormFieldRunner   :field="item"></FormFieldRunner>
          </td>
          <td style="width: 100px">
            <WebPartFormDesignerToolbar :ref="'toolbar'+item.Id" :web-part="webPart" :field="item"></WebPartFormDesignerToolbar>
          </td>
        </tr>
        <tr class="tblMainTR" >
          <td>
            <a role="button" class="btn btn-primary" href="#">送出</a>
          </td>
          <td style="width: 100px">

          </td>
        </tr>
    </table>




  </div>

</template>

<script>


import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";

// import FormFieldDesigner from "./formField/FormFieldDesigner";
import FormFieldRunner from "./formField/FormFieldRunner";
import WebPartFormDesignerToolbar from "@/pages/featureOnePage/webParts/WebPartFormDesignerToolbar";
// import MyCkEditor from "@/pages/commonComponent/MyCkEditor";

export default {
  props:["webPart"],
  components:{ WebPartFormDesignerToolbar, FormFieldRunner},
  data: function() {
    return {
      componentType:{},
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    submit(){
      for (let i = 0; i < this.webPart.Fields.length; i++) {
        // let item = this.webPart.Fields[i];


        // apiGPK.api_Save_ExchangeRate(this.exchangeRate.SystemId,"",this.exchangeRate).then((data)=>{
        //   util.ShowMessage('儲存成功')
        // })
      }
    },
    DataItem_AddLast(inputType){
      let newItem={
        Id:util.uuid(),
        SpecifyId:"",
        Title:"欄位標題",
        InputType:inputType,
        Values:"",
        DefaultValue:"",
        ValidateRule:[],
        Visible:true,
        OrderKey:"",
        InputValue:"",
      }

      arrayUtil.orderAppendLast(newItem,this.webPart.Fields,"OrderKey")
    },
    handleHover(hovered) { // eslint-disable-line
      // this.isHovered = hovered
      this.$refs['toolbar'+hovered.Id].showModal();
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
.tablePanel td{
  height: 14px
}
#tblMain{
  width: 1000px;
  border: 1px solid red;
}
.tblMainTR{

  border-top: 1px solid red;
  border-bottom: 1px solid red;
}




.btn{
  color: white !important;
  margin: 5px;
  /*width: 40px;*/
}
</style>
