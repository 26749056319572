<template>
  <div class="" >
    <div class="ckeditorDiv" style="">
<!--      type="inline"-->
      <table class="table table-hover">
        <thead class="thead-light">
        <tr>
          <th>項目</th>
          <th>聯絡資訊</th>
        </tr>
        </thead>
        <tbody>
        <tr>

          <td class="text-left">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1">

            Line
          </td>
          <td class="text-center">
            <input type="text" class="form-control" id="name" name="name" placeholder="" v-model="propsWebPart.Line">
          </td>
        </tr>
        <tr>
          <td class="text-left">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option2">
            FB
          </td>
          <td class="text-center">
            <input type="text" class="form-control" id="name" name="name" placeholder="" v-model="propsWebPart.FB">
          </td>
        </tr>
        <tr>
          <td class="text-left">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option3">
            電子信箱
          </td>
          <td class="text-center">
            <input type="text" class="form-control" id="name" name="name" placeholder="" v-model="propsWebPart.Email">

            <MyLiteFormDesigntime v-model="liteFormData" :config="liteFormConfig"></MyLiteFormDesigntime>

            預覽
            <MyLiteFormRuntime v-model="liteFormData" :config="liteFormConfig"></MyLiteFormRuntime>
          </td>
        </tr>
        <tr>
          <td class="text-left">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option4">
            簡訊
          </td>
          <td class="text-center">
            <input type="text" class="form-control" id="name" name="name" placeholder="" v-model="propsWebPart.CellPhone">
          </td>
        </tr>




        </tbody>
      </table>
    </div>

    <hr>


  </div>

</template>

<script>


import MyLiteFormDesigntime from "@/pages/commonComponent/MyLiteFormDesigntime.vue";
import MyLiteFormRuntime from "@/pages/commonComponent/MyLiteFormRuntime.vue";

export default {
  components: {MyLiteFormRuntime, MyLiteFormDesigntime},
  props: {
    webPart: {},
  },
  data: function() {
    return {
      /** @type {JWebPartHtml} */
      propsWebPart: this.webPart,
      //
      // datas:{
      //   memo:"",
      //   mphone:"",
      //   email:"",
      //   line:"",
      //   fb:"",
      // }
      liteFormData:[

      ],
      liteFormConfig:{

      }
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
