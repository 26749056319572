<template>
  <div style="width:1024px">
<!--    <VueSlickCarousel v-bind="settings" class="carousel">-->
<!--      <div><img src="/img/AD01.jpg"></div>-->
<!--      <div><img src="/img/AD02.jpg"></div>-->
<!--      <div><img src="/img/AD03.jpg"></div>-->
<!--      <div><img src="/img/AD01.jpg"></div>-->
<!--&lt;!&ndash;      <div><img src="/img/AD03.jpg"></div>&ndash;&gt;-->
<!--&lt;!&ndash;      <div><img src="/img/AD03.jpg"></div>&ndash;&gt;-->
<!--&lt;!&ndash;      <div><img src="/img/AD03.jpg"></div>&ndash;&gt;-->
<!--&lt;!&ndash;      <div><img src="/img/AD03.jpg"></div>&ndash;&gt;-->
<!--&lt;!&ndash;      <div><img src="/img/AD03.jpg"></div>&ndash;&gt;-->
<!--&lt;!&ndash;      <div><img src="/img/AD03.jpg"></div>&ndash;&gt;-->
<!--    </VueSlickCarousel>-->

    <h1>Drag Line</h1>

    <MyLibDragLineTester></MyLibDragLineTester>


    <carousel :autoplay="false" :nav="true" :dots="true" :center="false" :items="1" :loop="false" :responsive="responsive" :margin="10" :autoWidth="true">
      <img src="/img/C01.jpg" style="width:400px">
      <img src="/img/C02.jpg" style="width:400px">
      <img src="/img/C03.jpg" style="width:400px">
      <img src="/img/C02.jpg" style="width:400px">
      <img src="/img/C02.jpg" style="width:400px">
      <img src="/img/C02.jpg" style="width:400px">
      <img src="/img/C02.jpg" style="width:400px">
      <img src="/img/C02.jpg" style="width:400px">
      <img src="/img/C02.jpg" style="width:400px">
      <img src="/img/C02.jpg" style="width:400px">
      <img src="/img/C02.jpg" style="width:400px">
      <img src="/img/C02.jpg" style="width:400px">
      <img src="/img/C02.jpg" style="width:400px">
      <img src="/img/C02.jpg" style="width:400px">
<!--      <img src="https://placeimg.com/200/200/any?1">-->

<!--      <img src="https://placeimg.com/200/200/any?2">-->

<!--      <img src="https://placeimg.com/200/200/any?3">-->

<!--      <img src="https://placeimg.com/200/200/any?4">-->

    </carousel>

    <MyColorPicker></MyColorPicker>

    <MyColorPicker2></MyColorPicker2>

    my-color-picker3
    <my-color-picker3></my-color-picker3>


    <hr>
    <a role="button" class="btn btn-primary" @click="btnTest">送出</a>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import MyColorPicker from "@/pages/commonComponent/MyColorPicker";
import MyColorPicker2 from "@/pages/commonComponent/MyColorPicker2";
import MyLibDragLineTester from "@/pages/commonComponent/LibDragLine/MyLibDragLineTester";
import MyColorPicker3 from "@/pages/commonComponent/MyColorPicker3.vue";

export default {
  name: 'MyComponent',
  components: {MyColorPicker3, MyColorPicker2, carousel,MyColorPicker ,MyLibDragLineTester},
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
      videoOptions:{
        autoplay: 1,
        // loop:1
      },
      // responsive:{
      //   600:{
      //     items:3
      //   }
      // }
    };
  },
  methods: {
    btnTest(){
    }
  }
}
</script>

<style scoped>
.carousel {
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fff;
}
</style>
