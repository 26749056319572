var apiUrl=process.env.VUE_APP_apiUrl;
var apiPathBase="api/UBossFormApi/";
import AxiosManager from "@/pages/common/axiosLib/axiosManager";
import * as ubossCommon from "@/pages/commonUBoss/ubossCommon";
import ubossCrudApiManager from "@/pages/commonUBoss/ubossCrudApiManager";

export default {
    //---------------axios------------------------
    getAxiosManager(){
        return new AxiosManager(ubossCommon.SessionKeyPrefix_Bk + ubossCommon.TokenKeyLastFix)
    },
    //---------------------------------------
    getApiManager_General() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"", this.getAxiosManager(),null)
    },

    //-------------Form--------------------------
    async api_Form_Get_Web ( systemId)
    {
        var sendData={
            systemId:systemId,
        };
        return this.getApiManager_General().api_CallMethod_ByParamObj("Form_Get_Web",sendData);
    },

    async api_Form_Save_Web ( json)
    {
        var sendData={
            json:json,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("Form_Save_Web",sendData);
    },

    async api_FormData_Save_Web ( record)
    {
        return this.getApiManager_General().api_CallMethod_SendObj("Form_Get_Web",record);
    },


}