<template>
  <span>
    <input ref="file" type="file" class="form-control" placeholder="" style="" hidden @change="selectFile"  >
<!--    multiple-->
<!--    accept=".png,.jpg,.jpeg,.gif"-->

    <a class="btn btn-primary" @click="fileUplaodControlClick"><i class="fa fa-upload"></i></a>
    <span v-if="allowRemoveFile">
      <a class="btn btn-danger" @click="fileRemove"><i class="fa fa-trash"></i></a>
    </span>
  </span>
</template>

<script>

/**

 //一般
 <FileUploadButton upload-type="$u.UBossUploadType_Shop" file-name="logo.png" v-model="xx" </FileUploadButton>

 //進階
 <FileUploadButton :emmit-back-param="undefined" v-on:uploadSuccess="uploadSuccess" upload-type="$u.UBossUploadType_Shop" file-name="logo.png"></FileUploadButton>

 uploadSuccess(image,result){
      //result
      //  count
      //  size
      //  newFileNames
      //  fileUrls
      if (result && result.FileCount>0){
        this.Common.FavIcon=result.FileUrls[0]
      }else{
        util.ShowMessage("檔案上傳失敗")
      }

    },


  // 不決定檔案，且可以清除
 <FileUploadButton :emmit-back-param="undefined" v-on:uploadSuccess="uploadSuccess" :upload-type="$u.UBossUploadType_UTube" ></FileUploadButton>
 <a class="btn btn-danger" @click="clearFileUpload"><i class="fa fa-trash"></i></a>

 clearFileUpload(){
      this.Common.BackgroundImageUrl=""
      util.ShowMessage("已刪除")
    },
 uploadSuccess(image,result){
      if (result && result.FileCount>0){
        this.Common.BackgroundImageUrl=result.FileUrls[0]
      }else{
        util.ShowMessage("檔案上傳失敗")
      }
    },
**/
// import apiUBossBk from "@/pages/ubossBk/util/apiUBossBk";
import apiUBossCommonFileSec from "@/pages/commonUBoss/apiUBossCommonFileSec";
import util from "@/pages/common/util";
export default {
  props: ["emmitBackParam","uploadType","fileName","value","allowRemoveFile"],
  data: function () {
    return {
      selectdFiles:undefined,
      propsValue:this.value,
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    }
  },
  methods: {
    fileUplaodControlClick(){
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    fileRemove(){
      this.propsValue = "";//QQ應該刪除檔案
      util.ShowMessage("已刪除")
    },
    async selectFile(event){
      this.selectdFiles=event.target.files;
      await this.uploadFiles();
      event.target.value=null;


      // doc event.target.files 的內容
      // lastModified: 1612407992013
      // lastModifiedDate: Thu Feb 04 2021 11:06:32 GMT+0800 (台北標準時間) {}
      // name: "GPKlogo2.png"
      // size: 45641
      // type: "image/png"
      // webkitRelativePath: ""
    },
    async uploadFiles(){
      let uploadFiles=this.selectdFiles;

      if (this.uploadType===undefined){
        this.uploadType="";
      }
      if (this.fileName===undefined){
        this.fileName="";
      }

      let result=await apiUBossCommonFileSec.api_UploadFiles(this.uploadType,this.fileName,uploadFiles,true);

      this.$emit('uploadSuccess',this.emmitBackParam,result)

      this.propsValue=result.FileUrls;

      console.log(result);
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>

</style>
