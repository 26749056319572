<template>
  <div class="row justify-content-center">

    <div class="col-xl-10 col-lg-12 col-md-9">

      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
          <!-- Nested Row within Card Body -->
          <div class="row">
<!--            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>-->
            <div class="col-lg-6">
              <div class="p-5">
                <div class="text-center">
                  <h1 class="h4 text-gray-900 mb-4">UBoss Biz!</h1>
                </div>
                <div class="user">
                  <div class="form-group">
                    <input type="text" class="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter LoginId..." v-model="formData.LoginId">
                  </div>
                  <div class="form-group">
                    <input type="password" class="form-control form-control-user" id="exampleInputPassword" placeholder="Password" v-model="formData.Pwd">
                  </div>
<!--                  <div class="form-group">-->
<!--                    <div class="custom-control custom-checkbox small">-->
<!--                      <input type="checkbox" class="custom-control-input" id="customCheck">-->
<!--                      <label class="custom-control-label" for="customCheck">Remember Me</label>-->
<!--                    </div>-->
<!--                  </div>-->
                  <a type="button" @click="submit" class="btn btn-primary btn-user btn-block abutton">
                    Login
                  </a>
                  <hr>
<!--                  <a href="index.html" class="btn btn-google btn-user btn-block">-->
<!--                    <i class="fab fa-google fa-fw"></i> Login with Google-->
<!--                  </a>-->
<!--                  <a href="index.html" class="btn btn-facebook btn-user btn-block">-->
<!--                    <i class="fab fa-facebook-f fa-fw"></i> Login with Facebook-->
<!--                  </a>-->
                </div>
                <hr>
<!--                <div class="text-center">-->
<!--                  <a class="small" href="forgot-password.html">Forgot Password?</a>-->
<!--                </div>-->
<!--                <div class="text-center">-->
<!--                  <a class="small" href="register.html">Create an Account!</a>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import util from "@/pages/common/util";
import session from "../../common/session";

export default {
  name: "Login",
  data: function() {
    return {
      formData: {
        LoginId:"",
        Pwd:"",
      }
    }
  },
  methods:{
    submit(){
      this.formData.LoginId=this.formData.LoginId.toUpperCase()
      this.formData.Pwd=this.formData.Pwd.toUpperCase()
      let user=this.$dec.UserDB_UBoss.filter(s=>s.LoginId.toUpperCase()===this.formData.LoginId && s.Pwd.toUpperCase()===this.formData.Pwd);

      if (user.length>0){
        session.setAccUid(user[0].LoginId.toUpperCase())
        this.$router.push({ name: 'Index', query: {  }})
        location.reload();//QQ
      }else{
        util.ShowMessage("不正確的帳號或密碼")
      }


    }
  }
}
</script>

<style scoped>

</style>
