<template>
  <div>
    <table class="table table-hover" id="tblMain">
      <thead class="thead-light">
      <tr>
        <th>{{configDefault.Column1Text}}</th>
        <th>{{configDefault.Column2Text}}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) in this.datas" :key="index">
        <td>
          {{item.FieldText}}
        </td>
        <td >
          <div v-if="item.ControlType==='text'">
            <input type="text" class="form-control" placeholder="" >
          </div>
          <div v-else-if="item.ControlType==='number'">
            <input type="number" class="form-control" placeholder="" >
          </div>
          <div v-else-if="item.ControlType==='date'">
            <input type="date" class="form-control" placeholder="" >
          </div>
          <div v-else-if="item.ControlType==='tel'">
            <input type="tel" class="form-control" placeholder="" >
          </div>
          <div v-else>

          </div>
        </td>
      </tr>

      </tbody>
    </table>

  </div>
</template>

<script>
/**

 **/

import util from "@/pages/common/util";


export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    config:{
      type: Object,
    },
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      propsValue: this.value,
      formData: {
        name: "",
      },
      configDefault:{
        Column1Text:"問題",
        Column2Text:"回答",
        Column3Text:"必填",
      },
      datas:[
        {
          SystemId:"",
          FieldText:"a文字",
          ControlType:"text",
          MustInput:false,
          OrderKey:"999",
        },
      ]
    };
  },
  watch: {
    propsValue(newVal) {
      //this.('input', newVal);
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.propsValue = newVal
    }
  },
  methods: {
    //給予value屬性預設值
    initValue() {
      const isMyObjectEmpty = !Object.keys(this.value).length;

      if (isMyObjectEmpty) {
        let template = {...this.datasDemo};
        this.value = template;
      }
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },

    submit(){
      for (let i = 0; i < this.datas.length; i++) {
        let item = this.datas[i];

        if (item.Year===""){
          util.showErrorMessage("第"+i+1+" 行，「年」為必填欄位")
          return;
        }
        if (item.Month===""){
          util.showErrorMessage("第"+i+1+" 行，「月」為必填欄位")
          return;
        }
        if (item.Type===""){
          util.showErrorMessage("第"+i+1+" 行，「幣別」為必填欄位")
          return;
        }
        if (item.Rate===""){
          util.showErrorMessage("第"+i+1+" 行，「匯率」為必填欄位")
          return;
        }
      }

      // apiGPK.api_Save_ExchangeRate(this.exchangeRate.SystemId,"",this.exchangeRate).then((data)=>{
      //   util.ShowMessage('儲存成功')
      // })
    },


  },
  mounted() {

    util.ObjectOverride(this.configDefault,this.config);
    this.datas=this.propsValue;
    // this.initValue()
    //this.loadData()
  },
}
</script>

<style scoped>
#tblMain{
  width: 1000px;
}
.btn{
  color: white !important;
  margin: 5px;
  width: 40px;
}
</style>
