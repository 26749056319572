<template>
  <div>
    <div v-for="(item,index) in itemsDemo" :key="index">
       <AccountCatalog></AccountCatalog>

      <a class="btn btn-primary" @click="add"><i class="fa fa-plus-circle"></i> 新增</a>
      <a class="btn btn-danger" ><i class="fa fa-trash"></i> 刪除</a>
    </div>

  </div>
</template>

<script>

import AccountCatalog from "./AccountCatalog.vue";
export default {
  components: {AccountCatalog},
  //name: "AccountIndex",
  // props: [""],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      itemsDemo:[
        {
          Date:"",
          Type:"",
          Amount:"",
        }
      ]
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    add(){
      this.itemsDemo.push({})
    }
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>

</style>
