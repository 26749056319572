<template>
  <div class="" >
    <a class="btn btn-primary" @click="DataItem_AddLast"><i class="fas fa-plus-circle"></i></a>
    <table class="table table-hover" id="tblMain">
      <thead class="thead-light">
      <tr>
        <th class="text-center">功能</th>
        <th><input type="text" class="form-control" placeholder="" v-model="Title.Title01"></th>
        <th><input type="text" class="form-control" placeholder="" v-model="Title.Title02"></th>
        <th><input type="text" class="form-control" placeholder="" v-model="Title.Title03"></th>
        <th><input type="text" class="form-control" placeholder="" v-model="Title.Title04"></th>
        <th><input type="text" class="form-control" placeholder="" v-model="Title.Title05"></th>
        <th><input type="text" class="form-control" placeholder="" v-model="Title.Title06"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) in this.Datas" :key="index" :class="item.IsDisable ? 'IsDisable' : ''">
        <td class="text-center">
          <a class="btn btn-primary" @click="DataItem_Insert(item)"><i class="fas fa-plus-circle"></i></a>
          <a class="btn btn-danger" @click="DataItem_Remove(item)"><i class="far fa-trash-alt"></i></a>
          <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>
          <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>

<!--          <div v-if="item.IsDisable">-->
<!--            <a class="btn btn-primary" @click="DataItem_ActiveOrInActive(item)"><i class="far fa-times-circle"></i> </a>-->
<!--          </div>-->
<!--          <div v-else>-->
<!--            <a class="btn btn-primary" @click="DataItem_ActiveOrInActive(item)"><i class="far fa-circle"></i> </a>-->
<!--          </div>-->
<!--          <a class="btn btn-primary" @click="DataItem_ActiveOrInActive(item)" ><i class="far" :class="getButtonClass(item)" ></i> </a>-->
<!--          <a class="btn btn-primary" @click="DataItem_ActiveOrInActive(item)" ><i class="far" :class="{'fa-circle':!item.IsDisable,'fa-times-circle' : item.IsDisable}" ></i> </a>-->
<!--          :class="{'far fa-circle' :!item.IsDisable}"-->
        </td>
        <td width="120px">
          <input type="text" class="form-control" placeholder="" v-model="item.Field01">
        </td>
        <td width="120px">
          <input type="text" class="form-control" placeholder="" v-model="item.Field02">
        </td>
        <td width="120px">
          <input type="text" class="form-control" placeholder="" v-model="item.Field03">
        </td>
        <td width="120px">
          <input type="text" class="form-control" placeholder="" v-model="item.Field04">
        </td>
        <td width="120px">
          <input type="text" class="form-control" placeholder="" v-model="item.Field05">
        </td>
        <td width="120px">
          <input type="text" class="form-control" placeholder="" v-model="item.Field06">
        </td>
      </tr>

      </tbody>
    </table>

    呈現效果
    <hr>
    <a role="button" class="btn btn-primary" >最新消息</a>
    <a role="button" class="btn btn-primary" >卡片</a>
    <a role="button" class="btn btn-primary" >地址</a>
  </div>

</template>

<script>
import util from "@/pages/common/util";
import arrayUtil from "@/pages/common/arrayUtil";

export default {
  components: {},
  props:["webPart"],
  data: function() {
    return {
      // formLink:"",
      Title:{
        Title01:"主標題",
        Title02:"副標題",
        Title03:"連結",
        Title04:"備註",
        Title05:"",
        Title06:"",
      },
      Datas:[
        {
          Id:"38708a68-d1ba-47f8-b799-ba3d14631403",
          IsDisable:false,
          Field01:"一機在手，威力無窮",
          Field02:"消費者可以使用手機連結隨時下單寄杯做自己的配方",
          Field03:"",
          Field04:"",
          Field05:"",
          Field06:"",
        },
        {
          Id:"28df5169-417d-412c-ab00-3a5c44dbf107",
          IsDisable:false,
          Field01:"多元支付，比便利商店還方便！",
          Field02:"全電子支付的消費模式，已成為消費者及店家的的未來",
          Field03:"",
          Field04:"",
          Field05:"",
          Field06:"",
        },
        {
          Id:"752fe45e-b6b6-4849-b407-1c626d53f64b",
          IsDisable:false,
          Field01:"自己喝還是請客，掃碼一次搞定",
          Field02:"先寄杯後領取的方式，讓消費者永遠喝到最新鮮的飲品",
          Field03:"",
          Field04:"",
          Field05:"",
          Field06:"",
        },
        {
          Id:"f173ae96-1011-4914-94e7-182f3db39530",
          IsDisable:false,
          Field01:"歡迎光臨，您想喝什麼呢?",
          Field02:"每天都有新的配方新的驚喜，隨時追蹤配配飲掌握最新的最Cool的飲品趨勢",
          Field03:"",
          Field04:"",
          Field05:"",
          Field06:"",
        },
      ]
    };
  },
  // watch: {
  //   formLink: function () {
  //     this.webPart.formLink=this.formLink;
  //
  //     let htmlTemplate=`<iframe frameborder="0" height="846" marginheight="0" marginwidth="0" src="${this.formLink}" width="100%">載入中&amp;hellip;</iframe>`;
  //
  //     this.webPart.PartHtmlContent=htmlTemplate;
  //   },
  // },
  methods: {
    async loadData(){
      this.formLink=this.webPart.formLink;
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    setting(){
      this.$refs.dialog.showModal();
    },
    blockWebPartSelectorReturnOk(){

    },
    submit(){

    },

    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.Id,this.Datas,"Id","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.Id,this.Datas,"Id","OrderKey")
    },
    DataItem_ActiveOrInActive(item){
      // let index = this.Datas.findIndex(x => x.Id ===item.Id);
      //
      // Vue.set(this.Datas, this.cards[i]._id, false)
      item.IsDisable=!item.IsDisable;

      item.Field01=item.IsDisable;
    },
    DataItem_AddLast(){
      let newItem={
        Id:util.uuid(),
        Year:0,
        Moneth:0,
        Type:"",
        Rate:0,
        OrderKey:0,
      }

      arrayUtil.orderAppendLast(newItem,this.Datas,"OrderKey")
    },
    DataItem_Insert(currentItem){
      let newItem={
        Id:util.uuid(),
        Year:0,
        Moneth:0,
        Type:"",
        Rate:0,
        OrderKey:0,
      }

      arrayUtil.orderInsert(newItem,currentItem,this.Datas,"Id","OrderKey")
    },
    DataItem_Remove(item){
      this.Datas= this.Datas.filter(s => s.Id !==item.Id);
    },
    getButtonClass(item){
      debugger // eslint-disable-line
      console.log(item.IsDisable);
      if (item.IsDisable){
        return "fa-times-circle";
      }else{
        return "fa-circle";
      }
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>



<style scoped>
.IsDisable{
  background-color:gray;
}
/*#tblMain{*/
/*  width: 1000px;*/
/*}*/
/*.btn{*/
/*  color: white !important;*/
/*  margin: 5px;*/
/*  width: 40px;*/
/*}*/
</style>
