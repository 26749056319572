var apiUrl=process.env.VUE_APP_apiUrl;

import parsePath from 'parse-filepath'
import util from "@/pages/common/util";

export default {
    toDownloadLink(relativePath){
        if (relativePath){
            return util.toDownloadLink(apiUrl,relativePath);
        }else{
            return "";
        }
    },
    toDownloadLink2(isMobile,relativePath){
        let imageFileUrl=util.toDownloadLink(apiUrl,relativePath);

        if (isMobile){
            let fileInfo=parsePath(imageFileUrl);

            //讀取/M/下的圖檔
            return fileInfo.dir + "/M/" + fileInfo.base;
        }else{
            return imageFileUrl;
        }
    },
    getPicLinkOnePage(isMobile,relativePath){
        if (isMobile){
            return this.getPicLinkOnlyCentral(relativePath,800);
        }else{
            return this.getPicLink(relativePath,1024);
        }
    },
    getPicLinkOnePage_OrgSize(isMobile,relativePath){
        return this.getPicLink(relativePath);
    },
    getPicLink(relativePath,width){
        let imageFileUrl=util.toDownloadLink(apiUrl,relativePath);

        if (width){
            return imageFileUrl +"?format=png&width="+width;
        }else{
            return imageFileUrl +"?format=png";
        }

    },
    getPicLinkOnlyCentral(relativePath,width){
        let imageFileUrl=util.toDownloadLink(apiUrl,relativePath);

        return imageFileUrl +"?format=png&cwidth="+width;
    }
}
