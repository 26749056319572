<template>
  <div style="">
    <!--    Pro -->
    <COM_ProHeader v-if="OnePagePro.SystemId" :one-page-pro="OnePagePro" v-on:menuClick="menuClick"></COM_ProHeader>

    <COM_Header v-if="OnePage.Header" :header="OnePage.Header" :common="OnePage.Common" :web-parts="OnePage.WebParts" :type="this.$u.UBossOnePageHeaderType_OnePagePro"></COM_Header>

    <BlockListView v-if="OnePage.Header" area-id="area1" :page-id="OnePageId" :web-parts="OnePage.WebParts" v-on:Save="Save" v-on:Preview="Preview" :page-mode="this.$u.UBossPageMode_OnePage"></BlockListView>

    <COM_Footer v-if="OnePage.Header" :footer="OnePage.Footer" :page-id="OnePageId"></COM_Footer>

  </div>

</template>

<script>
import COM_ProHeader from "@/pages/featureOnePage/ubossOnePagePro/COM_ProHeader";
import COM_Header from "@/pages/featureOnePage/ubossOnePage/COM_Header";
import BlockListView from "../../featureOnePage/ubossOnePage/BlockListView";
import DesignTimeSupport from "@/pages/featureOnePage/base/DesignTimeSupport";
import COM_Footer from "../../featureOnePage/ubossOnePage/COM_Footer";
import apiServerUtil from "@/pages/common/apiServerUtil";
import apiUBossOnePage from "@/pages/featureOnePage/util/apiUBossOnePage";
import util from "@/pages/common/util";
import onePageProUtil from "@/pages/featureOnePage/util/onePageProUtil";

export default {
  name: 'Index',
  components: {COM_ProHeader,
    COM_Footer,
    BlockListView, COM_Header},
  mixins: [DesignTimeSupport],
  metaInfo: function() {
    //CC
    if (this.OnePage.Common){
      return {
        title: this.OnePage.Common.SiteTitle,
        link: [
          { rel: 'icon', href: this.toDownloadLink(this.OnePage.Common.FavIcon) }
        ],
        meta: [
          { charset: 'utf-8' },
          { property: 'og:title', content: this.OnePage.Common.SiteTitle},
          { property: 'og:description', content: this.OnePage.Common.SiteTitle},
        ]
      }
    }else{
      return {}
    }

  },
  data: function() {
    return {
      OnePage:{},
      OnePageId:"",
      OnePagePro: {},
      OnePageProId:"",
    };
  },
  methods:{
    async loadDataAll(){
      if (!this.OnePageProId){
        // this.OnePageId="001d9468-42c6-467d-b646-f76ae3d08119";  //PRO
        this.OnePageProId="";  //PRO
      }
      // await this.loadDataPro(this.OnePageProId);

      let data=await apiUBossOnePage.api_GetOnePagePro(this.OnePageProId);

      this.OnePageId=data.SystemId;
      this.OnePagePro=data.OnePage;
      this.OnePageProId=this.OnePagePro.SystemId;

      let onePageSystemId=onePageProUtil.getFirstPageByLang(this.OnePagePro,"");
      this.loadData(onePageSystemId)
    },
    // async loadDataPro(onePageProId){
    //
    //
    // },
    async loadData(onePageId){
      let json=await apiUBossOnePage.api_GetOnePage(onePageId)
      this.OnePageId=json.SystemId;
      this.OnePage=json.OnePage

      this.$meta().refresh()

    },
    toDownloadLink(imageFileUrl){
      return apiServerUtil.toDownloadLink(imageFileUrl);
    },
    //Pro z
    menuClick(onePageId){ // eslint-disable-line
      console.log("load data:" + onePageId);

      // debugger // eslint-disable-line
      // this.loadData(onePageId);

      let onePageObj=onePageProUtil.getPageByOnePageSystemId(this.OnePagePro,onePageId);

      this.OnePage=onePageObj

      this.$meta().refresh()
    },
    async Save(){
      await apiUBossOnePage.api_Save_OnePagePro(this.OnePageProId,JSON.stringify(this.OnePagePro))

      util.ShowMessage("儲存成功","訊息")

      location.reload()
    },
    async Preview() {
      let routeData = this.$router.resolve({name: 'IndexOnePage'});
      window.open(routeData.href, '_blank');
    }
  },
  beforeCreate: function() {

  },
  created() {
    this.loadDataAll("");
    this.OnePageProId=this.$route.query.id

    this.loadDataAll(this.OnePageProId);

    //
    // if (!this.OnePageId){
    //   // this.OnePageId="001d9468-42c6-467d-b646-f76ae3d08119";  //PRO
    //   this.OnePageId="";  //PRO
    // }
    //
    // let that=this;
    //
    // debugger // eslint-disable-line
    // that.loadDataPro(that.OnePageProId).then(()=>{
    //   if (that.onePagePro.PageLists.length>0){
    //
    //     if (that.onePagePro.PageLists[0].Pages.length>0){
    //       that.OnePageId= that.onePagePro.PageLists[0].Pages[0].OnePageSystemId
    //       that.loadData(that.OnePageId)
    //     }
    //   }
    // })
    //
    // debugger // eslint-disable-line




  },
  mounted() {

  },

}
</script>


<style scoped>
.btn{
  color: white !important;
  margin: 5px;
  width: 100px;
}
</style>
