<template>
  <div>
    <span v-if="isDesignTime">
    <draggable-container class="dragContainer">
      <draggable-child
          :key="dragItemDefault.Id"
          :id="dragItemDefault.Id"
          :width="dragItemDefault.Width"
          :height="dragItemDefault.Height"
          @stop="handleStop"
          @start="handleStart"
          @drag="handleDrag"
          @resize="handleResize"
          :default-position="dragItemDefault.Position" :class="getClass(dragItemDefault)">
        <div
            class="dragItem"
            :style="{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'move',
            boxSizing: 'border-box',
            background: dragItemDefault.Background,
            opacity:getOpacity(dragItemDefault),
          }"  >
        </div>
      </draggable-child>
      <!--      dataId為自訂屬性-->
      <draggable-child ref="child"
                       v-for="item in image.TextBlocks"
                       :key="item.Id"
                       :id="item.Id"
                       :width="item.Width"
                       :height="item.Height"
                       :dataId="item.Id"
                       @stop="handleStop"
                       @start="handleStart"
                       @drag="handleDrag"
                       @resize="handleResize"
                       @click="handleClick(item)"
                       :default-position="item.Position" :class="getClass(item)">
        <div
            class="dragItem"
            :style="{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'move',
            boxSizing: 'border-box',
            background: item.Background,
            opacity:getOpacity(item),
          }"  >
  <!--        <span>size: {{ item.size }}</span>-->
          <!--        <span>drag me</span>-->
          <div v-if="item.Id !==999 " style="width: 100%;height: 100%;">
            <div class="btn-group blockToolbarFixRight" role="group" aria-label="">
<!--              <a class="btn btn-primary btn-sm " @click="$refs.editorDialog.showModal()"><i class="fa fa-pen"></i></a>-->
              <a class="btn btn-primary btn-sm " @click="showDialog(item)"  ><i class="fa fa-pen"></i></a>
              <a class="btn btn-danger btn-sm " @click="removeChild(item.Id)"><i class="fa fa-trash"></i></a>
            </div>
            <div v-html="item.HtmlContent"></div>
          </div>

        </div>
      </draggable-child>

    </draggable-container>
    <MyCkEditorDialog ref="editorDialog" v-model="currentHtml" v-on:returnOk="eventEditorDialogOK"></MyCkEditorDialog>
    </span>
    <span v-else>
      <div ref="child" class="designerContainer_Runtime"
                       v-for="item in image.TextBlocks"
                       :key="item.Id"
                       :id="item.Id"
                       :style="{
                        width:item.Width,
                        height:item.Height,
                        top: toPx(item.Position.x),
                        left: toPx(item.Position.y),
                      }"
           >
        <div
            class="dragItem"
            :style="{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'move',
            boxSizing: 'border-box',
            background: item.Background,
            opacity:getOpacity(item),
          }"  >
          <div>
            <div v-html="item.HtmlContent"></div>
          </div>

        </div>
      </div>
    </span>

  </div>
</template>
<script>
import 'vue-dragline/lib/vue-dragline.css';
import MyCkEditorDialog from "@/pages/commonComponent/MyCkEditorDialog.vue";
export default {
  components: {MyCkEditorDialog},
  props: {
    isDesignTime: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    image: {
      type: Object,
      default: function () {
        return null;
      }
    },
  },
  data() {
    return {
      dragItemDefault:{ Id: 999, Background: '', Width: 10, Height: 10, Position: { x: 500, y: 300 }},    //設為透明的
      initialChildrenDefault: [
        { Id: 999, Background: '', Width: 10, Height: 10, Position: { x: 500, y: 300 }},    //設為透明的
      ],
      // HtmlContent:"",
      currentHtml:"",
      currentEditorItem:null,
      currentDragItem:null,
      // TextBlocks:[
      //   // { Id: 2, Background: '', Width: 100, Height: 100, Position: { x: 400, y: 106 },HtmlContent:""},
      //   // { Id: 3, Background: '', Width: 100, Height: 100, Position: { x: 400, y: 106 },HtmlContent:""},
      // ]
    };
  },
  computed:{

  },
  methods: {
    loadData(){

    },
    toPx(value){
      return value + "px";
    },
    getOpacity(item){
      if (item.Id===999){
        return 0;
      }else{
        return 0.7
      }
    },
    getClass(item){
      if (item.Id===999){
        return "";
      }else{
        return "dragBlock";
      }
    },
    showDialog(item){
      this.currentEditorItem=item;
      this.currentHtml=item.HtmlContent;
      this.$refs.editorDialog.showModal();
    },
    eventEditorDialogOK(value){
      // let item=this.TextBlocks.filter(s=> s.Id ===this.currentEditorItem.Id)[0];
      // item.HtmlContent=value;

      this.currentEditorItem.HtmlContent=value;
      this.$forceUpdate();
    },
    addChild(){
      if (!this.image.TextBlocks){
        // this.image.TextBlocks=[];
        this.$set(this.image, 'TextBlocks', [])
      }

      let newId=this.image.TextBlocks.length +1;
      let newChild={ Id: newId, Background: '', Width: 100, Height: 100, Position: { x: 0, y: 0 } };

      this.image.TextBlocks.push(newChild);

    },
    removeChild(id){
      this.image.TextBlocks= this.image.TextBlocks.filter(s => s.Id !==id);
    },
    handleClick(e){
      console.log('click:'+ e.Id);
      this.currentDragItem=e;
    },
    handleResize(e) {// eslint-disable-line
      // console.log(e, 'handleResize');
      let currentItemId="";
      this.$refs.child.forEach((item) => {
        if (e.height===item.h && e.width===item.w){
          currentItemId=item.$attrs.dataId;
        }
      });

      if (currentItemId){
        let block=this.image.TextBlocks.filter(s=> s.Id ===currentItemId)[0];
        block.Height=e.height;
        block.Width=e.width;
      }

      console.log(e, 'handleResize id:' + currentItemId);
    },
    handleDrag(e) {// eslint-disable-line
      // console.log(e);
    },
    handleStop(e) { // eslint-disable-line
      let currentItemId="";
      this.$refs.child.forEach((item) => {
        if (e.x===item.x && e.y===item.y){
          currentItemId=item.$attrs.dataId;
        }
      });

      if (currentItemId){
        let block=this.image.TextBlocks.filter(s=> s.Id ===currentItemId)[0];
        block.Position.x=e.x;
        block.Position.y=e.y;
      }

      console.log(e, 'handleStop id:' + currentItemId);
    },
    handleStart() {
      console.log('开始');
    },
    // handleClick() {
    //   alert(1);
    // }

  },
  mounted() {
    this.loadData();
  }
};
</script>

<style scoped>
.dragContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  border-color:transparent;  /* //消除元件本來的style*/
}


.dragBlock{
  /* border-color:red;*/
  border: 1px dashed red;
}
.dragItem{
  background-color: transparent;
}

.editButton {
  position: absolute;
  top: 0;
  right: 0;
}

.designerContainer_Runtime{
  position: relative !important;
  text-align: center;
  color: white;
}


</style>
