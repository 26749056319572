import cookieUtil from "@/pages/common/cookieUtil";
import util from "@/pages/common/util";
import arrayUtil from "@/pages/common/arrayUtil";

// export const WebPartType_Section="section"
// export const WebPartType_SliderProduct="sliderProduct"
// export const WebPartType_Product="product"
export const WebPartType_Image="image"
export const WebPartType_Html="html"
export const WebPartType_SliderImage="sliderImage"
export const WebPartType_SliderImage2="sliderImage2"
export const WebPartType_SliderImage3="sliderImage3"
export const WebPartType_FormLink="formLink"
export const WebPartType_Form="form"
export const WebPartType_Video="video"
export const WebPartType_FormSubmit="formSubmit"
export const WebPartType_FormShop="formShop"
export const WebPartType_ListData="listData"
export const WebPartType_FormShopWithProduct="formShopWithProduct"
export const WebPartType_FormShopWithProductCalendar="formShopWithProductCalendar"
export const WebPartType_HR="hr"
export const WebPartType_PricePlan="pricePlan"
export const WebPartType_HotProducts="hotProducts"
export const WebPartType_CallToAction="callToAction"
export const WebPartType_Card="card"

export const OnePage_TimeType_Design="design"
export const OnePage_TimeType_Run="run"

export var  globalWebParts={}
// export const DebugOnePage=false
export const DebugOnePage=false


export default {

    webPartHtml_DefaultObject(id,title,partHtmlContent){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_Html);
        saveData.PartHtmlContent=partHtmlContent;

        return saveData;
    },

    webPartImage_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_Image);

        return saveData;
    },

    webPartSliderImage_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_SliderImage);

        return saveData;
    },
    webPartSliderImage2_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_SliderImage2);

        return saveData;
    },
    webPartSliderImage3_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_SliderImage3);

        return saveData;
    },

    webPartVideo_DefaultObject(id,title,videoLink,videoId){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_Video);
        saveData.VideoLink=videoLink;
        saveData.VideoId=videoId;

        return saveData;
    },

    webPartFormLink_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_FormLink);

        return saveData;
    },

    webPartForm_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_Form);

        saveData.Fields=[]

        return saveData;
    },

    webPartFormSubmit_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_FormSubmit);

        return saveData;
    },

    webPartFormShop_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_FormShop);
        saveData.ShopItems=[]

        return saveData;
    },


    webPartListData_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_ListData);
        saveData.Datas=[]

        return saveData;
    },

    webPartFormShopWithProduct_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_FormShopWithProduct);
        saveData.Datas=[]

        return saveData;
    },

    webPartFormShopWithProductCalendar_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_FormShopWithProductCalendar);
        saveData.Datas=[]

        return saveData;
    },


    webPartHR_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_HR);
        saveData.Datas=[]

        return saveData;
    },

    webPartPricePlan_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_PricePlan);
        saveData.Datas=[]

        return saveData;
    },


    webPartHotProducts_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_HotProducts);
        saveData.Datas=[]

        return saveData;
    },

    webPartCallToAction_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_CallToAction);
        saveData.Datas=[]

        return saveData;
    },


    webPartCard_DefaultObject(id,title){
        let saveData=this.webPart_getBaseJsonObject(id,title,WebPartType_Card);
        saveData.Cards=[];
        saveData.PerRowCardNum=3;

        return saveData;
    },



    //------------Base---------------------------
    webPart_getBaseJsonObject(id,title,webPartType){
        let id_new=util.uuid();

        if (id){
            id_new=id;
        }

        return {
            Id:id_new,
            Title:title,
            WebPartType:webPartType,
            OrderKey:"",
        };
    },
    // webPart_getId(areaId,blockId,type){
    //     return 'webPart_area'+areaId + '_block'+blockId +"_type"+type
    // },

    dataSave(id, saveData){
        localStorage.setItem(id, JSON.stringify(saveData));
    },
    dataLoad(id){
        let json= localStorage.getItem(id);

        if (json){
            return JSON.parse(json)
        }else{
            return null
        }
    },

    getValue(key){
        let value=cookieUtil.getCookie(key);

        if (value===undefined || value===null){
            return ""
        }else{
            return value;
        }
    },
    setValue(key,value){
        cookieUtil.setCookie(key,value);
    },
    //-----------Helper----------------------------

    getWebPartTypeText(webpartType){
        if (webpartType===WebPartType_Image){
            return "圖片"
        }else if (webpartType===WebPartType_Html){
            return "內容"
        }else if (webpartType===WebPartType_SliderImage){
            return "廣告區(大圖輪播)"
        }else if (webpartType===WebPartType_SliderImage2){
            return "廣告區(小圖並列)"
        }else if (webpartType===WebPartType_SliderImage3){
            return "廣告區(小圖並列)"
        }else if (webpartType===WebPartType_Video){
            return "影片"
        }else if (webpartType===WebPartType_FormLink){
            return "表單"
        }else if (webpartType===WebPartType_Form){
            return "表單"
        }else if (webpartType===WebPartType_FormSubmit){
            return "表單送出"
        }else if (webpartType===WebPartType_FormShop){
            return "購買項目"
        }else if (webpartType===WebPartType_ListData){
            return "條列資料"
        }else if (webpartType===WebPartType_FormShopWithProduct){
            return "購買商品"
        }else if (webpartType===WebPartType_FormShopWithProductCalendar){
            return "購買預約商品"
        }else if (webpartType===WebPartType_HR){
            return "分隔線"
        }else if (webpartType===WebPartType_PricePlan){
            return "面板"
        }else if (webpartType===WebPartType_HotProducts){
            return "熱銷商品"
        }else if (webpartType===WebPartType_CallToAction){
            return "與我連繫"
        }else if (webpartType===WebPartType_Card){
            return "卡片"
        }else{
            return "???"
        }
    },

    getTimeType(){
        let timeType= this.getValue("timeType");

        if (timeType){
            return timeType
        }else{
            return OnePage_TimeType_Run
        }


        // return OnePage_TimeType_Run
        // return OnePage_TimeType_Design
    },

    setTimeType(value) {
        this.setValue("timeType",value);
    },
    onePageDataSave(saveData){
        this.dataSave('onePage' ,saveData)
    },
    onePageDataLoad(){
        return this.dataLoad('onePage')
    },

    areaDataSave(areaId, saveData){
        this.dataSave('area' + areaId,saveData)
    },
    areaDataLoad(areaId){
        return this.dataLoad('area'+areaId)
    },

    blockDataSave(areaId,blockId, saveData){
        this.dataSave('area' + areaId +'_block'+blockId,saveData)
    },
    blockDataLoad(areaId,blockId){
        return this.dataLoad('area'+areaId + '_block'+blockId)
    },

    //-----------CRUD Loader----------------------------

    NewOnePage_GetAdd(id,title,webPartType){
        let defaultObject={};
        if (webPartType===WebPartType_Image) {
            defaultObject= this.webPartImage_DefaultObject(id,title);
        }else if (webPartType===WebPartType_Html){
            defaultObject= this.webPartHtml_DefaultObject(id,title,"");
        }else if (webPartType===WebPartType_SliderImage){
            defaultObject= this.webPartSliderImage_DefaultObject(id,title);
        }else if (webPartType===WebPartType_SliderImage2){
            defaultObject= this.webPartSliderImage2_DefaultObject(id,title);
        }else if (webPartType===WebPartType_SliderImage3){
            defaultObject= this.webPartSliderImage3_DefaultObject(id,title);
        }else if (webPartType===WebPartType_Video){
            defaultObject= this.webPartVideo_DefaultObject(id,title,"","");
        }else if (webPartType===WebPartType_FormLink){
            defaultObject= this.webPartFormLink_DefaultObject(id,title);
        }else if (webPartType===WebPartType_Form){
            defaultObject= this.webPartForm_DefaultObject(id,title);
        }else if (webPartType===WebPartType_FormSubmit){
            defaultObject= this.webPartFormSubmit_DefaultObject(id,title);
        }else if (webPartType===WebPartType_FormShop){
            defaultObject= this.webPartFormShop_DefaultObject(id,title);
        }else if (webPartType===WebPartType_ListData){
            defaultObject= this.webPartListData_DefaultObject(id,title);
        }else if (webPartType===WebPartType_FormShopWithProduct){
            defaultObject= this.webPartFormShopWithProduct_DefaultObject(id,title);
        }else if (webPartType===WebPartType_FormShopWithProductCalendar){
            defaultObject= this.webPartFormShopWithProductCalendar_DefaultObject(id,title);
        }else if (webPartType===WebPartType_HR){
            defaultObject= this.webPartHR_DefaultObject(id,title);
        }else if (webPartType===WebPartType_PricePlan){
            defaultObject= this.webPartPricePlan_DefaultObject(id,title);
        }else if (webPartType===WebPartType_HotProducts){
            defaultObject= this.webPartHotProducts_DefaultObject(id,title);
        }else if (webPartType===WebPartType_CallToAction){
            defaultObject= this.webPartCallToAction_DefaultObject(id,title);
        }else if (webPartType===WebPartType_Card){
            defaultObject= this.webPartCard_DefaultObject(id,title);
        }else{
            return "???"
        }

        return defaultObject
    },

    NewOnePage_Push(afterId,webPartNew,webPartsAll){
        if (afterId){
            const found = webPartsAll.findIndex(s => s.Id ===afterId);
            webPartsAll.splice(found+1,0, webPartNew);
        }else{
            webPartsAll.splice(webPartsAll.length,0, webPartNew);
        }
    },

    NewOnePage_Delete(id,webPartsAll){
        // webPartsAll= webPartsAll.filter(s => s.Id !==id);
        var index = webPartsAll.findIndex(function(o){
            return o.Id === id;
        })
        if (index !== -1) webPartsAll.splice(index, 1);
        return webPartsAll
    },


    NewOnePage_MoveUp(id,webPartsAll){
        arrayUtil.orderMove(false,id,webPartsAll,"Id","OrderKey")
    },


    NewOnePage_MoveDown(id,webPartsAll){
        arrayUtil.orderMove(true,id,webPartsAll,"Id","OrderKey")
    },


    NewOnePage_ImageDelete(id,webPartsAll){
        webPartsAll= webPartsAll.filter(s => s.ImageUrl !==id);
        return webPartsAll
    },

    NewOnePage_ImageMoveUp(id,imagesAll){
        arrayUtil.orderMove(false,id,imagesAll,"ImageUrl","OrderKey")
    },


    NewOnePage_ImageMoveDown(id,imagesAll){
        arrayUtil.orderMove(true,id,imagesAll,"ImageUrl","OrderKey")
    },

    Card_getClass(perRowCardNum){
        if (perRowCardNum===1){
            return "col-md-12";
        }else if (perRowCardNum===2){
            return "col-md-6";
        }else if (perRowCardNum===3){
            return "col-md-4";
        }else if (perRowCardNum===4) {
            return "col-md-3";
        }

        return "col-md-4";
    }

    //---------------------------------------

}
