<template>

  <div class="" style="" :style=" this.$onePageUtil.DebugOnePage ? 'border-color: red;border-style: solid' : '' " >
<!--    //QQA Adv Feature-->
<!--    <div class="" style="">-->
<!--      <a class="btn btn-primary"  @click="show_selectorWebPart()">元件類型</a>-->
<!--      <a class="btn btn-primary"  @click="show_settingWebPart()" v-if="webPartType">設定</a>-->
<!--    </div>-->

    <div v-if="this.$onePageUtil.DebugOnePage">
      Id:{{webPart.Id}}<br>
      Title:{{webPart.Title}}<br>
      WebPartType:{{webPart.WebPartType}}<br>
    </div>



    <div class="form-group row" style="">
      <label class="col-sm-2 labelClass" style="color: #2c9faf">標題</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" name="name" placeholder="" v-model="webPart.Title">
      </div>
    </div>

<!--    <BlockWebPartSelector ref="selectorWebPart" v-on:returnOk="blockWebPartSelectorReturnOk"></BlockWebPartSelector>-->
<!--    <WebPartHtmlSetting ref="settingHtml" :area-id="areaId" :block-id="blockId"></WebPartHtmlSetting>-->
<!--    <WebPartImageSetting ref="settingImage" :area-id="areaId" :block-id="blockId"></WebPartImageSetting>-->
<!--    <WebPartSlickerImageSetting ref="settingSliderImage" :area-id="areaId" :block-id="blockId"></WebPartSlickerImageSetting>-->
<!--    <WebPartSlickerProductSetting ref="settingSliderProduct" :area-id="areaId" :block-id="blockId"></WebPartSlickerProductSetting>-->
<!--    <WebPartProductSetting ref="settingProduct" :area-id="areaId" :block-id="blockId"></WebPartProductSetting>-->

    <div v-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_Html">
      <WebPartHtmlDesigner :web-part="webPart"></WebPartHtmlDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_Image">
      <WebPartImageDesigner :web-part="webPart"></WebPartImageDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_SliderImage">
      <WebPartSlickerImageDesigner :web-part="webPart"></WebPartSlickerImageDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_SliderImage2">
      <WebPartSlickerImage2Designer :web-part="webPart"></WebPartSlickerImage2Designer>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_SliderImage3">
      <WebPartSlickerImage3Designer :web-part="webPart"></WebPartSlickerImage3Designer>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_Video">
      <WebPartVideo :design-time="designTime" :web-part="webPart"></WebPartVideo>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_FormLink">
      <WebPartFormLinkDesigner :web-part="webPart"></WebPartFormLinkDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_Form">
      <WebPartFormDesigner :web-part="webPart"></WebPartFormDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_FormSubmit">
      <WebPartFormSubmitDesigner :web-part="webPart"></WebPartFormSubmitDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_FormShop">
      <WebPartFormShopDesigner :web-part="webPart"></WebPartFormShopDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_ListData">
      <WebPartListDataDesigner :web-part="webPart"></WebPartListDataDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_FormShopWithProduct">
      <WebPartFormShopWithProductDesigner :web-part="webPart"></WebPartFormShopWithProductDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_FormShopWithProductCalendar">
      <WebPartFormShopWithProductCalendarDesigner :web-part="webPart"></WebPartFormShopWithProductCalendarDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_HR">
      <WebPartHRDesigner :web-part="webPart"></WebPartHRDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_PricePlan">
      <WebPartPricePlanDesigner :web-part="webPart"></WebPartPricePlanDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_HotProducts">
      <WebPartHotProductsDesigner :web-part="webPart"></WebPartHotProductsDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_CallToAction">
      <WebPartCallToActionDesigner :web-part="webPart"></WebPartCallToActionDesigner>
    </div>
    <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_Card">
      <WebPartCardDesigner :web-part="webPart" ></WebPartCardDesigner>
    </div>
    <div v-else>

    </div>



  </div>


</template>

<script>
import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";
import WebPartHtmlDesigner from "@/pages/featureOnePage/webParts/WebPartHtmlDesigner.vue";
import WebPartImageDesigner from "@/pages/featureOnePage/webParts/WebPartImageDesigner.vue";
import WebPartSlickerImageDesigner from "@/pages/featureOnePage/webParts/WebPartSlickerImageDesigner.vue";
import WebPartSlickerImage2Designer from "@/pages/featureOnePage/webParts/WebPartSlickerImage2Designer.vue";
import WebPartSlickerImage3Designer from "@/pages/featureOnePage/webParts/WebPartSlickerImage3Designer.vue";
import WebPartVideo from "@/pages/featureOnePage/webParts/WebPartVideo.vue";
import WebPartFormDesigner from "@/pages/featureOnePage/webParts/WebPartFormDesigner.vue";
import WebPartFormLinkDesigner from "@/pages/featureOnePage/webParts/WebPartFormLinkDesigner.vue";
import WebPartFormSubmitDesigner from "@/pages/featureOnePage/webParts/WebPartFormSubmitDesigner.vue";
import WebPartFormShopDesigner from "@/pages/featureOnePage/webParts/WebPartFormShopDesigner.vue";
import WebPartListDataDesigner from "@/pages/featureOnePage/webParts/WebPartListDataDesigner.vue";
import WebPartFormShopWithProductDesigner from "@/pages/featureOnePage/webParts/WebPartFormShopWithProductDesigner.vue";
import WebPartFormShopWithProductCalendarDesigner from "@/pages/featureOnePage/webParts/WebPartFormShopWithProductCalendarDesigner.vue";
import WebPartHRDesigner from "@/pages/featureOnePage/webParts/WebPartHRDesigner.vue";
import WebPartPricePlanDesigner from "@/pages/featureOnePage/webParts/WebPartPricePlanDesigner.vue";
import WebPartHotProductsDesigner from "@/pages/featureOnePage/webParts/WebPartHotProductsDesigner.vue";
import WebPartCallToActionDesigner from "@/pages/featureOnePage/webParts/WebPartCallToActionDesigner.vue";
import DesignTimeSupport from "@/pages/featureOnePage/base/DesignTimeSupport.vue";
import WebPartCardDesigner from "@/pages/featureOnePage/webParts/WebPartCardDesigner.vue";
// import HelloWorld from '@/components/HelloWorld.vue'
//
export default {
  components: {
    WebPartCardDesigner,
    WebPartFormSubmitDesigner,
    WebPartFormDesigner,
    WebPartFormLinkDesigner,
    WebPartVideo,
    WebPartSlickerImageDesigner,
    WebPartSlickerImage2Designer,
    WebPartSlickerImage3Designer,
    WebPartImageDesigner,
    WebPartHtmlDesigner,
    WebPartFormShopDesigner,
    WebPartListDataDesigner,
    WebPartFormShopWithProductDesigner,
    WebPartFormShopWithProductCalendarDesigner,
    WebPartHRDesigner,
    WebPartPricePlanDesigner,
    WebPartHotProductsDesigner,
    WebPartCallToActionDesigner,
    },
  mixins: [DesignTimeSupport],
  props:["webPart"],
  // components: {
  //   HelloWorld
  // }

  data: function() {
    return {
      webPartType:"",
      webPartTypeText:"",
      formData:{
        name:"Sophie Wang",
      },
      submitted: false,
    };
  },
  methods: {
    show_selectorWebPart(){
      this.$refs.selectorWebPart.showModal();
    },
    blockWebPartSelectorReturnOk(webPartType){
      this.webPartType=webPartType;
      this.webPartTypeText=onePageUtil.getWebPartTypeText(this.webPartType)

      this.saveData()
    },
    // webPartHtmlSettingReturnOk(content){
    //   this.webPartType=webPartType;
    //   this.webPartTypeText=onePageUtil.getWebPartTypeText(this.webPartType)
    //
    //   this.saveData()
    // },
    saveData(){
      let saveData={
        webPartType:this.webPartType
      }

      onePageUtil.blockDataSave(this.areaId,this.blockId, saveData)
    },
    async loadData(){
      // this.webPart=onePageUtil.NewOnePage_ById(this.Id)
      // let data=onePageUtil.NewOnePage_Load()
      //
      // if (data){
      //
      //   this.webPartType=this.webPart.webPartType
      //
      //
      //   this.webPartTypeText=onePageUtil.getWebPartTypeText(this.webPartType)
      // }
    },
    updateData(newData) {
      debugger // eslint-disable-line
      this.webPart = newData;
    },
  },
  mounted() {


    this.loadData()
  },
}


</script>

<style scoped>
.btn{
  color: white !important;
}
</style>
