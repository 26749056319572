<template>
  <div class="">
    <WebPartPricePlanSetting ref="setting" ></WebPartPricePlanSetting>

    <div class="" >

      <div class="card-deck mb-3 text-center">
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">方案1</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">1,000</h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>10 users included</li>
              <li>2 GB of storage</li>
              <li>Email support</li>
              <li>Help center access</li>
            </ul>
            <button type="button" class="btn btn-lg btn-block btn-outline-primary">購買連結</button>
          </div>
        </div>
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">方案1</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">1,000</h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>10 users included</li>
              <li>2 GB of storage</li>
              <li>Email support</li>
              <li>Help center access</li>
            </ul>
            <button type="button" class="btn btn-lg btn-block btn-outline-primary">購買連結</button>
          </div>
        </div>
      </div>
    </div>

    <a class="btn btn-primary" @click="setting()"><i class="fa fa-cog"></i> </a>

  </div>

</template>

<script>
// import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";
// import util from "@/pages/common/util";
import apiServerUtil from "@/pages/common/apiServerUtil";
import WebPartPricePlanSetting from "@/pages/featureOnePage/webParts/WebPartPricePlanSetting";

export default {
  components: {WebPartPricePlanSetting},
  props:["webPart"],
  data: function() {
    return {

    };
  },
  computed:{
    myWebPart() {
      return this.webPart;
    },
  },
  methods: {

    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    setting(){
      this.$refs.setting.showModal();

    },
    toDownloadLink(isMobile,imageFileUrl){
      return apiServerUtil.getPicLinkOnePage(isMobile,imageFileUrl);
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
