import session from "@/pages/common/session";

export const DB_WebPartDataDemo = []


//-------------手動設定--------------------------
export const Hosts_Form = ["form.uboss.cc","form.uboss.loc","form.taylor.tw","127.0.0.1:9009"]
export const Hosts_Biz = ["biz.uboss.cc","biz.uboss.loc","biz.taylor.tw","127.0.0.1:9006"]
export const Hosts_OnePage = ["biz.uboss.cc","172.104.81.182:8081"]
export const Hosts_ShoppingCart = ["biz.uboss.cc","172.104.81.182:8081"]

//-------------手動設定 (開發時才生效)--------------------------
export const Dev_IsRunInFormMode = true
export const Dev_IsRunInOnePageMode = false
export const Dev_IsRunInShoppingCartMode = false

// export const TokenKey_Adm = "ubossAdmToken";
// export const TokenKey_Bk = "ubossBkToken";
// export const TokenKey_Shop = "ubossShopToken";
// export const TokenKey_Form = "ubossFormToken";

export const TokenKeyLastFix = "Token";


export const SessionKeyPrefix_Adm = "ubossAdm";
export const SessionKeyPrefix_Bk = "ubossBk";
export const SessionKeyPrefix_Shop = "ubossShop";
export const SessionKeyPrefix_Form = "ubossForm";


export default {
    IsAppProduction(){
        console.log(process.env.VUE_APP_env);
        if ("production"===process.env.NODE_ENV){
            return true;
        }else{
            return false;
        }
    },
    IsSomeModeSimple(arrHosts){
        let host=window.location.host;
        host=host.toLowerCase()

        if (arrHosts.includes(host)){
            return true;
        }else{
            return false;
        }
    },
    IsSomeMode(arrHosts,bDevEnable){
        if (this.IsAppProduction()){
            let host=window.location.host;
            host=host.toLowerCase()

            if (arrHosts.includes(host)){
                return true;
            }else{
                return false;
            }
        }else{
            return bDevEnable;
        }
    },
    //---------------------------------------
    IsLocalhost(){
        let host=window.location.host;
        host=host.toLowerCase()

        let index = host.indexOf("127.0.0.1");
        if (index > -1){
            return true;
        }else{
            return false;
        }
    },
    //---------------------------------------
    IsFormMode(){
        return this.IsSomeModeSimple(Hosts_Form);
    },
    IsBizMode(){
        return this.IsSomeModeSimple(Hosts_Biz);
    },

    BizOrFormTitle(){
        if (this.IsFormMode()){
            return "UBoss Form";
        }else{
            return "UBoss Backend";
        }
    },
    //---------------------------------------
    getCustomeCode(){
        let publicPath=process.env.VUE_APP_publicPath //    /shop/paypaydrink/

        publicPath=publicPath.replace("/shop/","")
        publicPath=publicPath.replace("/onepage/","")
        publicPath=publicPath.replace("/","")

        if (publicPath==="."){
            // return "paypaydrink";
            return "bodytalk";
            // return "storyteller1111";
        }else{
            return publicPath;
        }
    },
    getSubDomain(){
        return this.getCustomeCode();
        // let href=window.location.href;
        // href=href.toLowerCase();
        //
        // let index = href.indexOf("bodytalk");
        // if (index > -1){
        //     return "bodytalk";
        // }
        //
        // index = href.indexOf("aesa");
        // if (index > -1){
        //     return "aesa";
        // }
        // index = href.indexOf("shouldbio");
        // if (index > -1){
        //     return "shouldbio";
        // }
        // index = href.indexOf("goodjob");
        // if (index > -1){
        //     return "goodjob";
        // }
        //
        // return "bodytalk";
    },
    getOnePageUrl(){
        let url="https://biz.uboss.cc/onepage/";    //QQ Const
        url=url +this.getSubDomain();
        return url;
    },
    getOnePageSettingUrl(){

    },

    //-----------
    IsLoginAdm(){
        let token=session.getTokenByKey(SessionKeyPrefix_Adm + TokenKeyLastFix)
        if (token){
            return true;
        }else{
            return false;
        }
    },

    IsLoginBk(){
        let token=session.getTokenByKey(SessionKeyPrefix_Bk + TokenKeyLastFix)
        if (token){
            return true;
        }else{
            return false;
        }
    },

    IsLoginShop(){
        let token=session.getTokenByKey(SessionKeyPrefix_Shop + TokenKeyLastFix)
        if (token){
            return true;
        }else{
            return false;
        }
    },

    IsLoginForm(){
        let token=session.getTokenByKey(SessionKeyPrefix_Form + TokenKeyLastFix)
        if (token){
            return true;
        }else{
            return false;
        }
    },
    //---------------------------------------
    baseUserAfterLogin(sessionKeyPrefix,data){
        let jwt=data.tag01;
        let loginid=data.tag02;
        let name=data.tag03;
        session.setTokenByKey(sessionKeyPrefix + TokenKeyLastFix,jwt)
        session.setLoginId(sessionKeyPrefix,loginid)
        session.setUserName(sessionKeyPrefix,name)

        console.log("loginId:" + loginid);
        console.log("jwt:" + jwt);
    },

    baseUserLogout(sessionKeyPrefix){
        session.setTokenByKey(sessionKeyPrefix + TokenKeyLastFix,"")
        session.setLoginId(sessionKeyPrefix,"")
        session.setUserName(sessionKeyPrefix,"")
    },

    //---------------------------------------
    memberAfterLogin(vue,data){
        this.baseUserAfterLogin(SessionKeyPrefix_Shop,data)
        vue.$store.dispatch('Cart/initStore')
    },

    memberLogout(vue){
        this.baseUserLogout(SessionKeyPrefix_Shop)
        vue.$store.dispatch('Cart/resetStore')
    },

    memberLoginGuard(router){
        if (!this.IsLoginShop()) {
            router.push({ name: 'Login'})
            return false;
        }
        return true;
    },
    //---------------------------------------
    BkAfterLogin(vue,data){// eslint-disable-line
        this.baseUserAfterLogin(SessionKeyPrefix_Bk,data)
    },

    BkLogout(vue){ // eslint-disable-line
        this.baseUserLogout(SessionKeyPrefix_Bk)
    },

    BkLoginGuard(router){
        if (!this.IsLoginBk()) {
            router.push({ name: 'Login'})
            return false;
        }
        return true;
    },

    //---------------------------------------
    AdminAfterLogin(vue,data){// eslint-disable-line
        this.baseUserAfterLogin(SessionKeyPrefix_Adm,data)
    },

    AdminLogout(vue){ // eslint-disable-line
        this.baseUserLogout(SessionKeyPrefix_Adm)
    },

    AdminLoginGuard(router){
        if (!this.IsLoginAdm()) {
            router.push({ name: 'Login'})
            return false;
        }
        return true;
    },

}
