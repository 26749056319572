<template>
  <div class="" >

<!--    <div v-for="(image,index) in webPart.PartImages" :key="index">-->
<!--      <img :src="image.ImageUrl" style="width: 1024px">-->
<!--    </div>-->

<!--mobile-->
<!--desktop-->
      <div class="">
        <div class="row">
<!--          class="col-sm-12"-->
          <div :class="colClass"  v-for="(item,index) in webPart.Cards" :key="index">
            <div class="card">
              <img :src="toDownloadLink(false,item.ImageUrl)"  class="card-img-top" alt="">
              <div class="card-body">
                <h5 class="card-title">
                  <div>
                    <span v-html="item.Title"></span>
                  </div>
                </h5>
                <div class="card-text">
                  <div>
                    <span v-html="item.Text"></span>
                  </div>
                </div>
                <!--                        <a href="#" class="btn btn-primary">Go somewhere</a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>

</template>

<script>


import apiServerUtil from "@/pages/common/apiServerUtil";
import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";

export default {
  components: {},
  props: {
    webPart: {},
  },
  data: function() {
    return {
      /** @type {JWebPartImage} */
      propsWebPart: this.webPart,
      colClass:"col-md-3",
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
      this.colClass=this.calColClass();
    },
    calColClass(){
      return onePageUtil.Card_getClass(this.webPart.PerRowCardNum);
    },
    toDownloadLink(isMobile,imageFileUrl){
      return apiServerUtil.getPicLinkOnePage(isMobile,imageFileUrl);
    }
  },
  mounted() {
    this.loadData()
  },
}

</script>

<style scoped>
/*.crop {*/
/*  width: 600px;*/
/*  height: 200px;*/
/*  overflow: hidden;*/
/*  !*     margin: 10px; *!*/
/*  position: relative;*/
/*}*/
/*.crop img {*/
/*  position: absolute;*/
/*  left: -1000%;*/
/*  right: -1000%;*/
/*  top: -1000%;*/
/*  bottom: -1000%;*/
/*  margin: auto;*/
/*  min-height: 100%;*/
/*  min-width: 100%;*/
/*}*/
</style>
