export const AccountingCatalog= [
    {
        Id:"資產類",
        Text:"資產類",
    },
    {
        Id:"負債類",
        Text:"負債類",
    },
    {
        Id:"淨值類",
        Text:"淨值類",
    },
    {
        Id:"收入類",
        Text:"收入類",
    },
    {
        Id:"支出類",
        Text:"支出類",
    },
]

export const AccountingItem=[
    {
        CatalogId:"資產類",
        SubCatalogId:"流動資產",
        ItemId:"用品盤存",
        Text:"用品盤存",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"流動資產",
        ItemId:"暫付款",
        Text:"暫付款",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"流動資產",
        ItemId:"預付費用",
        Text:"預付費用",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"流動資產",
        ItemId:"進項稅額",
        Text:"進項稅額",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"流動資產",
        ItemId:"應退稅款",
        Text:"應退稅款",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"流動資產",
        ItemId:"代付款",
        Text:"代付款",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"流動資產",
        ItemId:"其他流動資產",
        Text:"其他流動資產",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"流動資產",
        ItemId:"基金及投資",
        Text:"基金及投資",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"流動資產",
        ItemId:"備抵壞帳",
        Text:"備抵壞帳",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"固定資產",
        ItemId:"土地",
        Text:"土地",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"固定資產",
        ItemId:"房屋及建築",
        Text:"房屋及建築",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"固定資產",
        ItemId:"運輸設備",
        Text:"運輸設備",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"固定資產",
        ItemId:"辦公設備",
        Text:"辦公設備",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"固定資產",
        ItemId:"其他設備",
        Text:"其他設備",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"固定資產",
        ItemId:"累積折舊",
        Text:"累積折舊",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"其他資產",
        ItemId:"存出保證金",
        Text:"存出保證金",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"其他資產",
        ItemId:"遞延費用",
        Text:"遞延費用",
    },

    {
        CatalogId:"資產類",
        SubCatalogId:"其他資產",
        ItemId:"其他",
        Text:"其他",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"流動負債",
        ItemId:"應付票據",
        Text:"應付票據",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"流動負債",
        ItemId:"應付款",
        Text:"應付款",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"流動負債",
        ItemId:"應付費用",
        Text:"應付費用",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"流動負債",
        ItemId:"應付稅款",
        Text:"應付稅款",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"流動負債",
        ItemId:"其他應付款",
        Text:"其他應付款",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"流動負債",
        ItemId:"短期借款",
        Text:"短期借款",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"流動負債",
        ItemId:"長期借款",
        Text:"長期借款",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"流動負債",
        ItemId:"暫收款",
        Text:"暫收款",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"流動負債",
        ItemId:"銷項稅額",
        Text:"銷項稅額",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"流動負債",
        ItemId:"代收款項",
        Text:"代收款項",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"流動負債",
        ItemId:"其他流動負債",
        Text:"其他流動負債",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"流動負債",
        ItemId:"存入保證金",
        Text:"存入保證金",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"其他負債",
        ItemId:"預收款項",
        Text:"預收款項",
    },

    {
        CatalogId:"負債類",
        SubCatalogId:"其他負債",
        ItemId:"其他",
        Text:"其他",
    },

    {
        CatalogId:"淨值類",
        SubCatalogId:"受限淨資產",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"淨值類",
        SubCatalogId:"準備金",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"淨值類",
        SubCatalogId:"餘絀",
        ItemId:"累計餘絀",
        Text:"累計餘絀",
    },

    {
        CatalogId:"淨值類",
        SubCatalogId:"餘絀",
        ItemId:"本期餘絀",
        Text:"本期餘絀",
    },

    {
        CatalogId:"收入類",
        SubCatalogId:"會費收入",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"收入類",
        SubCatalogId:"捐贈收入",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"收入類",
        SubCatalogId:"撥入經費",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"收入類",
        SubCatalogId:"補助款收入",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"收入類",
        SubCatalogId:"會員代辦收入",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"收入類",
        SubCatalogId:"代辦收入",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"收入類",
        SubCatalogId:"孳息收入",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"收入類",
        SubCatalogId:"服務收入",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"收入類",
        SubCatalogId:"銷售收入類",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"收入類",
        SubCatalogId:"其他收入",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"支出類",
        SubCatalogId:"人事費",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"支出類",
        SubCatalogId:"辦公費",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"支出類",
        SubCatalogId:"事業費",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"支出類",
        SubCatalogId:"雜項購置",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"支出類",
        SubCatalogId:"特別費",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"支出類",
        SubCatalogId:"退休撫恤費",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"支出類",
        SubCatalogId:"預備金",
        ItemId:"",
        Text:"",
    },

    {
        CatalogId:"支出類",
        SubCatalogId:"其他支出",
        ItemId:"",
        Text:"",
    },
]

export const AccountingDiaryDemo=[
    {
        CatalogId:"資產類",
        SubCatalogId:"流動資產",
        ItemId:"用品盤存",
        Text:"用品盤存",

        InvoiceNo:"",
        Amount:"1000",
        Memo:"年初盤點",
    },
]

export const DebugOnePage=false

export default {
    test(){

    }
}