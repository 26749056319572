<template>
  <div>
    <table class="table table-hover">
      <thead class="thead-light">
      <tr>
        <th class="text-center">科目</th>
        <th class="text-center">日期</th>
        <th>金額</th>
        <th>備註</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="text-left">
          營業收入
        </td>
        <td class="text-left">
          <input type="date" class="form-control" id="name" name="name" placeholder="">
        </td>
        <td class="text-left">
          <input type="number" class="form-control" id="name" name="name" placeholder="">
        </td>
        <td class="text-left">
          <input type="text" class="form-control" id="name" name="name" placeholder="">
        </td>
      </tr>

      </tbody>
    </table>

    <div v-for="(item,index) in itemsDemo" :key="index">
      <a class="btn btn-primary" @click="add"><i class="fa fa-plus-circle"></i> 新增</a>
      <a class="btn btn-danger" ><i class="fa fa-trash"></i> 刪除</a>
    </div>

    <AccountCatalogM ref="accCatalog"></AccountCatalogM>
  </div>
</template>

<script>

import AccountCatalogM from "./AccountCatalogM.vue";
export default {
  components: {AccountCatalogM},
  //name: "AccountIndex",
  // props: [""],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      itemsDemo:[
        {
          Date:"",
          Type:"",
          Amount:"",
        }
      ]
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    add(){
      // this.itemsDemo.push({})
      this.$refs.accCatalog.showModal()
    }
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>

</style>
