<template>
    <div>
        <OnePageMain v-if="SystemId || onePageAdvId" :design-time="false" :one-page-id="SystemId" :layout-type="layoutType" :one-page-adv-id="onePageAdvId" :cname-system-id="cnameSystemId"></OnePageMain>
    </div>
</template>

<script>/**

 **/
import OnePageMain from "@/pages/featureOnePage/OnePageMain.vue";


export default {
    components: {OnePageMain},
    props: {
        value: {
            type: Array,
            default() {
                return []
            }
        },
        layoutType:{
          type: String,
          default() {
            return "1"
          }
        }
    },
    data: function () {
        return {
            SystemId: "",
            onePageAdvId: "",
            cnameSystemId: "",
        };
    },
    methods: {
        async loadData() {
            // let data=await apiUtil.api_SupplierTODO("12138114-01")

            // this.data=data
        },
    },
    mounted() {
        this.SystemId=this.$route.query.systemId
        this.onePageAdvId=this.$route.query.onePageAdvId
        this.cnameSystemId=this.$route.query.cnameSystemId

        // this.initValue()
        //this.loadData()
    },
}
</script>

<style scoped>

</style>
