// noinspection NonAsciiCharacters

export const UBossDocHomeUrl = "/doc/#/%E5%B8%B8%E7%94%A8%E9%80%A3%E7%B5%90"

export const UBossDefaultLogo = "Data/Data/Global/logo.png"
export const UBossDefaultImage = "Data/UBoss/Global/defaultImage.png"


export const UBossUploadType_Shop = "Shop"
export const UBossUploadType_OnePage = "OnePage"
export const UBossUploadType_Form = "Form"
export const UBossUploadType_UTube = "UTube"
export const UBossUploadType_Ticket = "Ticket"

export const UBossProductShowType_Grid = "Grid"
export const UBossProductShowType_List = "List"

export const UBossPageMode_OnePage = "OnePage"
export const UBossPageMode_Form = "Form"

export const UBossEvent_FormWebPartInputReg="FormWebPartInputReg"       //表單input webpart啟動時註冊 (用來知道一個onepage 頁面上有幾個form input webpart)
export const UBossEvent_FormSubmiting="formSubmiting"                   //表單 submit時發出，請各form input webpart 將使用者的資料傳回，收集後送回server


export const UBossOnePageHeaderType_OnePage="OnePage"
export const UBossOnePageHeaderType_ShopCart="ShopCart"
export const UBossOnePageHeaderType_OnePagePro="OnePagePro"


export const UBossFileUseType_OnePageWebPartFormShop="Ckeditor_OnePageWebPartFormShop"
export const UBossFileUseType_OnePageWebPartHtml="Ckeditor_OnePageWebPartHtml"
export const UBossFileUseType_OnePageFooter="Ckeditor_OnePageFooter"
export const UBossFileUseType_ProductSummary="Ckeditor_ProductSummary"
export const UBossFileUseType_ProductDesc="Ckeditor_ProductDesc"
export const UBossFileUseType_ShopHeader="Ckeditor_ShopHeader"
export const UBossFileUseType_ShopFooter="Ckeditor_ShopFooter"

export const UTubeMediaPlayDirEnum_LeftToRight=1
export const UTubeMediaPlayDirEnum_RightToLeft=2

export const UTubeMediaPlayTypeEnum_Sequence=1
export const UTubeMediaPlayTypeEnum_Random=2

export const UTubeMediaTypeEnum_ImageUpload=1
export const UTubeMediaTypeEnum_ImageUrl=2
export const UTubeMediaTypeEnum_VideoUrl=3




export const UCode_CLInvoiceType=
    {
        個人電子發票:"person",
        公司戶電子發票:"company",
        捐贈發票:"donation",
        寄送實體發票:"paper",
    };



export const UCode_CLOrderStatus=
    {
        尚未成立:"unsetup",
        成立:"setup",
        完成:"finish",
        取消:"cancel",
    };



export const UCode_CLPayStatus=
    {
        付款成功:"success",
        付款失敗:"fail",
        付款等待中:"wait",
        等待退款:"waitRefund",
        已退款:"refund",
        交易爭執:"dispute",
    };



export const UCode_CLPayType=
    {
        現金:"CASH",
        信用卡:"CREDIT",
        WEBATM:"WEBATM",
        ATM:"VACC",
    };


//
// export const UCode_CLPayWay=[
//     {
//         Text:"宅配1+貨到付款(運費$80)",
//         Value:"1"
//     },
//     {
//         Text:"宅配2+貨到付款(運費$70)",
//         Value:"2"
//     },
//     {
//         Text:"取貨點A+付現",
//         Value:"5"
//     },
//     {
//         Text:"取貨點B+付現",
//         Value:"6"
//     },
//     {
//         Text:"取貨點C+付現",
//         Value:"7"
//     },
// ]


export const UCode_CLShipStatus=
    {
        尚未出貨:"unship",
        運送中:"shipping",
        到達:"arrival",
    };


export const UCode_CLShipType=
    {
        自取:"self",
        宅配:"ship",
        店到店:"storeToStore",
    };


export const UCode_CLSourceType=
    {
        Shop:"Shop",
        Form:"Form",
        POS:"POS",
        Simple:"Simple",
    };




export const UCode_CLProductStatus=
    {
        上架:"on",
        下架:"off",
    };

export const UCode_YesNo=
    {
        是:true,
        否:false,
    };







// export const UBossEvent_FormSubmited="formSubmited"

// const DaysEnum = Object.freeze({"monday":1, "tuesday":2, "wednesday":3})
//
// const seasons = {
//     SUMMER: {
//         BEGINNING: "summer.beginning",
//         ENDING: "summer.ending"
//     },
//     WINTER: "winter",
//     SPRING: "spring",
//     AUTUMN: "autumn"
// };
//
// let season = seasons.SUMMER.BEGINNING;

export const DebugOnePage = false

export default {

    // UTubeMediaTypeEnum_ToToDic(){
    //     return ud.UTubeMediaTypeEnum_ToDict();
    // },
    UTubeMediaTypeEnum_ToDict() {
        return    [
            {
                "value":UTubeMediaTypeEnum_ImageUpload,
                "text":"圖片(上傳)"
            },
            {
                "value":UTubeMediaTypeEnum_ImageUrl,
                "text":"圖片(網址)"
            },
            {
                "value":UTubeMediaTypeEnum_VideoUrl,
                "text":"影片"
            },
        ];
    },
    /**
     UTubeMediaTypeEnum_ToText(value){
      return ud.UTubeMediaTypeEnum_ToText(value);
    }
     */
    UTubeMediaTypeEnum_ToText(value) {
        if (value===UTubeMediaTypeEnum_ImageUpload){
            return "圖片(上傳)";
        }if (value===UTubeMediaTypeEnum_ImageUrl){
            return "圖片(網址)";
        }if (value===UTubeMediaTypeEnum_VideoUrl){
            return "影片";
        }else{
            return "X:"+value;
        }
    }
}
