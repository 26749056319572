import cookieUtil from "@/pages/common/cookieUtil";
import * as dec  from "@/pages/common/declare";


export default {
    //---------------------------------------
    getAccUid(){
        return this.getValue("AccUid");
    },

    setAccUid(value) {
        this.setValue("AccUid",value);
    },
    getAccUidTemp(){
        return this.getValue("AccUidTemp");
    },

    setAccUidTemp(value) {
        this.setValue("AccUidTemp",value);
    },

    getAccUidNew(){
        return this.getValue(dec.LoginUserIdCookieName);
    },

    getAccType(){
        return this.getValue(dec.LoginUserTypeCookieName);
    },

    setAccType(value) {
        this.setValue(dec.LoginUserTypeCookieName,value);
    },

    //-------------biz client--------------------------
    getToken(){
        return this.getValue("token");
    },

    setToken(value) {
        this.setValue("token",value);
    },

    getTokenByKey(key){
        return this.getValue(key);
    },

    setTokenByKey(key,value) {
        this.setValueWithExpireDay(key,value);
    },
    //------------User Info---------------------------

    getLoginId(prefix){
        return this.getValue(prefix+"LoginId");
    },

    setLoginId(prefix,value) {
        this.setValue(prefix+"LoginId",value);
    },
    getUserName(prefix){
        return this.getValue(prefix+"UserName");
    },

    setUserName(prefix,value) {
        this.setValue(prefix+"UserName",value);
    },
    getLoginRole(prefix){
        return this.getValue(prefix+"LoginRole");
    },


    //---------------------------------------
    getIsRemember(){
        return this.getValue("IsRemember");
    },

    setIsRemember(value) {
        this.setValue("IsRemember",value);
    },
    //---------------------------------------
    getRememberLoginId(){
        return this.getValue("RememberLoginId");
    },

    setRememberLoginId(value) {
        this.setValue("RememberLoginId",value);
    },
    //---------------------------------------
    getIsAcceptCookie(){
        return this.getValue("IsAcceptCookie");
    },

    setIsAcceptCookie() {
        this.setValueWithExpireDay("IsAcceptCookie","1",7);//7天有效
    },
    //---------------------------------------
    isLogined(){
        var flag=this.getAccUid();

        if (flag ===""){
            return false;
        }else{
            return true;
        }
    },

    logout(router,vue){
        this.setAccUid("")
        this.setToken("")
        router.push('/Login')

        try {
            vue.$session.destroy()
        } catch (e) {
            console.log(e);
        }

        location.reload();//QQ
    },
    login_info_accUid(){
        //QQT
        // return "12138114-01";
        return this.getAccUid();
    },
    login_info_accUidTemp(){
        return this.getAccUidTemp();
    },

    login_info_accUidTemp_set(loginid){
        return this.setAccUidTemp(loginid);
    },

    RememberLoginId_Save(isRemember,loginId){
        if (isRemember){
            this.setIsRemember("1");
            this.setRememberLoginId(loginId);
        }else{
            this.setIsRemember("0")
            this.setRememberLoginId("");
        }
    },


    IsRemember_Get(){
        return this.getIsRemember();
    },

    RememberLoginId_Get(){
        return this.getRememberLoginId();
    },
    //---------------------------------------
    getValue(key){
        let value=cookieUtil.getCookie(key);

        if (value===undefined || value===null){
            return ""
        }else{
            return value;
        }
    },
    setValue(key,value){
        cookieUtil.setCookie(key,value);
    },
    setValueWithExpireMin(key,value,expireMin){
        let expireValue=expireMin * 60
        cookieUtil.setCookie(key,value,expireValue);
    },
    setValueWithExpireDay(key,value,expireDay){
        let expireValue=expireDay * 24 * 60*60
        cookieUtil.setCookie(key,value,expireValue);
    },


    getBiz_ShowType(){
        let value= this.getValue("Biz_ShowType");

        if (!value){
            return "1";
        }
        return value;
    },

    setBiz_ShowType(value) {
        this.setValue("Biz_ShowType",value);
    },

}
