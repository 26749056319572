<template>

<div class="" style="" :style=" this.$onePageUtil.DebugOnePage ? 'border-color: red;border-style: solid' : '' " >
  <div v-if="this.$onePageUtil.DebugOnePage">
    Id:{{webPart.Id}}<br>
    Title:{{webPart.Title}}<br>
    WebPartType:{{webPart.WebPartType}}<br>
  </div>

  <div v-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_Html">
    <WebPartHtmlRunner  :web-part="webPart"></WebPartHtmlRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_Image">
    <WebPartImageRunner :web-part="webPart"></WebPartImageRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_SliderImage">
    <WebPartSlickerImageRunner :web-part="webPart"></WebPartSlickerImageRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_SliderImage2">
    <WebPartSlickerImage2Runner :web-part="webPart"></WebPartSlickerImage2Runner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_SliderImage3">
    <WebPartSlickerImage3Runner :web-part="webPart"></WebPartSlickerImage3Runner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_Video">
    <WebPartVideo :design-time="designTime" :web-part="webPart"></WebPartVideo>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_FormLink">
    <WebPartFormLinkRunner :web-part="webPart" ></WebPartFormLinkRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_Form">
    <WebPartFormRunner :web-part="webPart" ></WebPartFormRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_FormSubmit">
    <WebPartFormSubmitRunner :web-part="webPart" :rel-form-system-id="pageId"  ></WebPartFormSubmitRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_FormShop">
    <WebPartFormShopRunner :web-part="webPart"  ></WebPartFormShopRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_ListData">
    <WebPartListDataRunner :web-part="webPart"  ></WebPartListDataRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_FormShopWithProduct">
    <WebPartFormShopWithProductRunner :web-part="webPart"  ></WebPartFormShopWithProductRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_FormShopWithProductCalendar">
    <WebPartFormShopWithProductCalendarRunner :web-part="webPart"  ></WebPartFormShopWithProductCalendarRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_HR">
    <WebPartHRRunner :web-part="webPart"  ></WebPartHRRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_PricePlan">
    <WebPartPricePlanRunner :web-part="webPart"  ></WebPartPricePlanRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_HotProducts">
    <WebPartHotProductsRunner :web-part="webPart"  ></WebPartHotProductsRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_CallToAction">
    <WebPartCallToActionRunner :web-part="webPart"  ></WebPartCallToActionRunner>
  </div>
  <div v-else-if="this.webPart.WebPartType===this.$onePageUtil.WebPartType_Card">
    <WebPartCardRunner :web-part="webPart"  ></WebPartCardRunner>
  </div>
  <div v-else>

  </div>

</div>


</template>

<script>
import WebPartHtmlRunner from "@/pages/featureOnePage/webParts/WebPartHtmlRunner.vue";
import WebPartImageRunner from "@/pages/featureOnePage/webParts/WebPartImageRunner.vue";
import WebPartSlickerImageRunner from "@/pages/featureOnePage/webParts/WebPartSlickerImageRunner.vue";
import WebPartSlickerImage2Runner from "@/pages/featureOnePage/webParts/WebPartSlickerImage2Runner.vue";
import WebPartSlickerImage3Runner from "@/pages/featureOnePage/webParts/WebPartSlickerImage3Runner.vue";
import WebPartVideo from "@/pages/featureOnePage/webParts/WebPartVideo.vue";
import WebPartFormRunner from "@/pages/featureOnePage/webParts/WebPartFormRunner.vue";
import WebPartFormLinkRunner from "@/pages/featureOnePage/webParts/WebPartFormLinkRunner.vue";
import WebPartFormSubmitRunner from "@/pages/featureOnePage/webParts/WebPartFormSubmitRunner.vue";
import WebPartFormShopRunner from "@/pages/featureOnePage/webParts/WebPartFormShopRunner.vue";
import WebPartListDataRunner from "@/pages/featureOnePage/webParts/WebPartListDataRunner.vue";
import WebPartFormShopWithProductRunner from "@/pages/featureOnePage/webParts/WebPartFormShopWithProductRunner.vue";
import WebPartFormShopWithProductCalendarRunner from "@/pages/featureOnePage/webParts/WebPartFormShopWithProductCalendarRunner.vue";
import WebPartHRRunner from "@/pages/featureOnePage/webParts/WebPartHRRunner.vue";
import WebPartPricePlanRunner from "@/pages/featureOnePage/webParts/WebPartPricePlanRunner.vue";
import WebPartHotProductsRunner from "@/pages/featureOnePage/webParts/WebPartHotProductsRunner.vue";
import WebPartCallToActionRunner from "@/pages/featureOnePage/webParts/WebPartCallToActionRunner.vue";
import DesignTimeSupport from "@/pages/featureOnePage/base/DesignTimeSupport.vue";
import WebPartCardRunner from "@/pages/featureOnePage/webParts/WebPartCardRunner.vue";
// import HelloWorld from '@/components/HelloWorld.vue'
//
export default {
  components: {
    WebPartCardRunner,
    WebPartCallToActionRunner,
    WebPartFormSubmitRunner,WebPartFormLinkRunner,
    WebPartFormRunner, WebPartVideo, WebPartSlickerImageRunner,WebPartSlickerImage2Runner,WebPartSlickerImage3Runner, WebPartImageRunner, WebPartHtmlRunner,WebPartFormShopRunner,WebPartListDataRunner,WebPartFormShopWithProductRunner,WebPartFormShopWithProductCalendarRunner,WebPartHRRunner,WebPartPricePlanRunner,WebPartHotProductsRunner},
    mixins: [DesignTimeSupport],
  props:["webPart","pageId"],
  // components: {
  //   HelloWorld
  // }

  data: function() {
    return {
      layoutType:1,
      submitted: false
    };
  },
  methods: {
    showModel(){
      this.$refs.selectorWebPart.showModal();
    },
    changeLayoutType(value){
      this.layoutType=value
    },
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data


    },
  },
  mounted() {


    // this.loadData()
  },
}


</script>
