<template>
  <div class="">
<!--    <span  v-for="n in myWebPart.PerRowCardNum">-->
<!--    </span>-->
    <div class="row">
        <div :class="colClass" v-for="(item,index) in webPart.Cards" :key="index">
            <div class="card">
                  <div class="blockToolbarFixRight">
                    <div class="btn-group blockToolbarFixRight" role="group" aria-label="">
                      <FileUploadButton :emmit-back-param="item" v-on:uploadSuccess="uploadSuccess" :upload-type="$u.UBossUploadType_OnePage"></FileUploadButton>
                      <a class="btn btn-primary btn-sm " @click="showDialog(item,'Title')"  ><i class="fa fa-pen"></i></a>
                      <a class="btn btn-primary btn-sm " @click="showDialog(item,'Text')"  ><i class="fa fa-pen"></i></a>
                      <a class="btn btn-danger btn-sm " @click="remove(item)"  ><i class="fa fa-trash"></i></a>
                      <a class="btn btn-primary" @click="moveUp(item)"><i class="fa fa-angle-left"></i> </a>
                      <a class="btn btn-primary" @click="moveDown(item)"><i class="fa fa-angle-right"></i> </a>
                    </div>
                  </div>

                  <img :src="toDownloadLink(false,item.ImageUrl)"  class="card-img-top" alt="">
                <div class="card-body">
                  <h5 class="card-title">
                    <div>
                      <span v-html="item.Title"></span>
                    </div>
                  </h5>
                  <div class="card-text">
                    <div>
                      <span v-html="item.Text"></span>
                    </div>
                  </div>
<!--                        <a href="#" class="btn btn-primary">Go somewhere</a>-->
                </div>
            </div>
        </div>
    </div>
    <div class="toolbar" style="">
      <div class="btn-group-vertical sectionToolbarFloatRight" role="group" aria-label="">
        <a class="btn btn-primary" @click="add()"><i class="fa fa-plus-circle"></i></a>
        <a class="btn btn-primary" @click="setting(image)"><i class="fa fa-cog"></i> </a>
        <select class="form-control" style="width:120px" v-model="webPart.PerRowCardNum">
          <option :value="4">每列4個</option>
          <option :value="3">每列3個</option>
          <option :value="2">每列2個</option>
          <option :value="1">每列1個</option>
        </select>

        <a class="btn btn-primary" @click="setting2()"><i class="fa fa-cog"></i> </a>
      </div>
<!--      PerRowCardNum:{{webPart.PerRowCardNum}}-->
<!--      colClass:{{colClass}}-->
    </div>




    <WebPartCardSetting ref="setting" ></WebPartCardSetting>

    <MyCkEditorDialog ref="editorDialog" v-model="currentHtml" v-on:returnOk="eventEditorDialogOK"></MyCkEditorDialog>

<!--    <MyCSSPicker ref="cssDialog" v-model="css" :setting="cssPickerSetting"></MyCSSPicker>-->
<!--    v-on:returnOk="dialogReturnOK"-->
    <MyCSSStyle ref="cssDialog" v-model="css" :setting="cssPickerSetting" ></MyCSSStyle>
  </div>

</template>

<script>
import FileUploadButton from "@/pages/commonComponent/FileUploadButton";
import util from "@/pages/common/util";
import WebPartCardSetting from "./WebPartCardSetting";
import apiServerUtil from "@/pages/common/apiServerUtil";
import MyCkEditorDialog from "@/pages/commonComponent/MyCkEditorDialog.vue";
import arrayUtil from "@/pages/common/arrayUtil";
import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";
import MyCSSStyle from "@/pages/commonComponent/MyCSSStyle.vue";

export default {
  components: {MyCSSStyle, MyCkEditorDialog, WebPartCardSetting,FileUploadButton},
  props: {
    webPart: {},
  },
  data: function() {
    return {
      /** @type {JWebPartCard} */
      // webPart:this.webPart,
      colClass:"col-md-3",
      currentHtml:"",
      currentEditorItem:null,
      currentPropertyName:"",
      css:"",
      cssPickerSetting:{
        UI_fontSizeTextbox:false,
        UI_fontSizeSelect:true,
      }
    };
  },
  computed:{
    myWebPart() {
      return this.webPart;
    },

  },
  watch:{
    webPart:{
      handler(val,oldVal){  // eslint-disable-line
        this.colClass= onePageUtil.Card_getClass(val.PerRowCardNum);
      },
      deep:true
    }
  },
  methods: {
    showDialog(item,propertyName){
      this.currentEditorItem=item;
      this.currentPropertyName=propertyName;
      this.currentHtml=item[this.currentPropertyName];
      this.$refs.editorDialog.showModal();
    },
    eventEditorDialogOK(value){
      this.currentEditorItem[this.currentPropertyName]=value;
    },
    async loadData(){
      if (!this.webPart.Cards || this.webPart.Cards.length===0){
        this.add(); //預設一張卡片
      }
      this.colClass=onePageUtil.Card_getClass(this.webPart.PerRowCardNum);
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    upload(){
      // onePageUtil.NewOnePage_Delete(id)
    },
    uploadSuccess(card,result){
      //result
      //  count
      //  size
      //  newFileNames
      //  fileUrls
      if (result && result.FileCount>0){
        let fileUrls=result.FileUrls

        card.ImageUrl=fileUrls[0]
      }else{
        util.ShowMessage("檔案上傳失敗")
      }
    },
    add(){
      let card={
        Id:"",
        ImageUrl:"/img/empty.png",
        ImageHref:"",
        Title:"",
        TitleHref:"",
        Text:"",
        TextHref:"",
        OrderKey:"",
      }
      card.Id=util.uuid();

      this.webPart.Cards.push(card);
    },
    remove(item){
      this.webPart.Cards=this.webPart.Cards.filter(s => s.Id !==item.Id);
    },
    moveUp(id){
      arrayUtil.orderMove(false,id,this.webPart.Cards,"Id","OrderKey")
    },
    moveDown(id){
      arrayUtil.orderMove(true,id,this.webPart.Cards,"Id","OrderKey")
    },
    setting(partImage){
      this.$refs.setting.partImage=partImage
      this.$refs.setting.showModal();

    },
    setting2(){
      // this.$refs.setting.partImage=partImage
      this.$refs.cssDialog.showModal();

    },
    toDownloadLink(isMobile,imageFileUrl){
      if (imageFileUrl !=="/img/empty.png"){
        return apiServerUtil.getPicLinkOnePage(isMobile,imageFileUrl);
      }else{
        return "/img/empty.png";
      }
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
.designerContainer {
  position: relative !important;
  text-align: center;
  color: white;
}

.toolbar {
  position: absolute;
  top: 50%;
  left: 104%;
  transform: translate(-50%, -50%);
}



/* Opacity #2 */
.hover12 figure {
  background: #1abc9c;
}
.hover12 figure img {
  opacity: 1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
.hover12 figure:hover img {
  opacity: .5;
}

.sectionToolbarFloatRight{
  position: absolute;
  left: -40px;
  //transform: translate(-50%, -50%);
}
.sectionToolbarFloatRight>.btn{
  color: white !important;
  margin: 5px;
  width: 40px;
}

</style>
