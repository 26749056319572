<template>
  <div>

    <section class="h-100 gradient-form" style="background-color: #eee;">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-xl-10">
            <div class="card rounded-3 text-black">
              <div class="row g-0">
                <div class="col-lg-12">
                  <div class="card-body p-md-5 mx-md-4">


                    <table class="table table-hover">
                      <thead class="thead-light">
                      <tr>
                        <th>表單名稱</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="text-left">
                          產品一
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">
                          產品二
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">
                          產品三
                        </td>
                      </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
<!--                <div class="col-lg-6 d-flex align-items-center gradient-custom-2">-->
<!--                  <div class="text-white px-3 py-4 p-md-5 mx-md-4">-->
<!--                    <h4 class="mb-4">We are more than just a company</h4>-->
<!--                    <p class="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod-->
<!--                      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud-->
<!--                      exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>
</template>

<script>
/**

 **/
// import util from "@/pages/common/util";

export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      propsValue: this.value,
      formData: {
        name: "",
      },
      password:"",
    };
  },
  watch: {
    propsValue(newVal) {
      //this.('input', newVal);
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.propsValue = newVal
    }
  },
  methods: {
    //給予value屬性預設值
    initValue() {
      const isMyObjectEmpty = !Object.keys(this.value).length;

      if (isMyObjectEmpty) {
        let template = {...this.datasDemo};
        this.value = template;
      }
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    login(){
      if (this.password==="1234"){//QQ
        //redirect to
        this.$router.push({ name: 'Index2', query: { id: 2 }})
      }else{
        // util.showErrorMessage("您的密碼不正確")
      }
    }
  },
  mounted() {
    // this.initValue()
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
