<template>
  <div>
    <ckeditor ref="ckEditor" editorUrl="https://cdn.ckeditor.com/4.14.0/full-all/ckeditor.js" :config="editorConfig"  v-model="propsValue"></ckeditor>


    <div style="margin-top: 10px" v-if="uploadImage_feature_isEnable">
    <FileUploadListLite ref="fileUploadListLite" emmit-back-param="undefined" :owner-system-id="fileUploadListLite_ownerSystemId" :file-use-memo="fileUploadListLite_fileUseMemo" v-on:imageClick="imageClick"></FileUploadListLite>
    </div>

    <div style="margin-top: 10px" v-if="template_feature_isEnable">
      <p style="display: inline;">插入範本</p>
      <select class="form-control aselect" style="display: inline;" v-model="templateType">
        <option value="">請選擇</option>
        <option value="textAlignLeft">文字靠左</option>
        <option value="textAlignRight">文字靠右</option>
        <option value="textAlignCenter">文字置中</option>
        <option value="tableAlignCenter">表格置中</option>
      </select>
    </div>

  </div>
</template>

<script>
/**
 with image

 <MyCkEditor ref="myCkEditor" v-model="webPart.Desc"  height="200" width="100%" :file-upload-list-lite_is-show="true" :file-upload-list-lite_owner-system-id="systemId"  :file-upload-list-lite_file-use-memo="this.$u.UBossFileUseType_Ckeditor"></MyCkEditor>

 simple
 <MyCkEditor ref="myCkEditor" v-model="Header.HtmlContent"  height="200" width="100%" :file-upload-list-lite_is-show="false"></MyCkEditor>
 **/
import {CKEditor4Config} from "@/pages/common/packageConfig";
import FileUploadListLite from "@/pages/commonComponent/FileUploadListLite";
import apiServerUtil from "@/pages/common/apiServerUtil";

export default {
  //name: "MyCkEditor",
  props: {
    value:{
      type: String,
      required: true,
      default: ""
    },
    height: {
      type: String,
      required: true,
      default: "200"
    },
    width: {
      type: String,
      required: true,
      default: "100%"
    },
    fileUploadListLite_isShow: {
      type: Boolean,
      required: true,
      default: false
    },
    fileUploadListLite_ownerSystemId:{
      type: String,
      required: false,
      default: ""
    },
    fileUploadListLite_fileUseMemo:{
      type: String,
      required: false,
      default: "",
    },
    template_feature_isEnable:{
      type: Boolean,
      required: false,
      default: false
    },
    uploadImage_feature_isEnable:{
      type: Boolean,
      required: false,
      default: true
    }
  },
  components:{FileUploadListLite},
  data: function () {
    return {
      propsValue:this.value,
      editorConfig: {...CKEditor4Config,...{
          height:this.height,
          width:this.width,
        }},
      templateType:"",
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    },
    templateType(newValue){
      let html="";
      if (newValue==="textAlignLeft"){
        html="<div class=\"uckeditor_left\">文字置左</div>";
      }else if (newValue==="textAlignRight"){
        html="<div class=\"uckeditor_right\">文字置右</div>";
      }else if (newValue==="textAlignCenter"){
        html="<div class=\"uckeditor_center\">文字置中</div>";
      }else if (newValue==="tableAlignCenter"){
        html=`<div class="uckeditor_table_center">表格置中
<table border="1" cellpadding="1" cellspacing="1" style="width:500px;">
\t<tbody>
\t\t<tr>
\t\t\t<td>&nbsp;</td>
\t\t\t<td>&nbsp;</td>
\t\t\t<td>&nbsp;</td>
\t\t</tr>
\t\t<tr>
\t\t\t<td>&nbsp;</td>
\t\t\t<td>&nbsp;</td>
\t\t\t<td>&nbsp;</td>
\t\t</tr>
\t\t<tr>
\t\t\t<td>&nbsp;</td>
\t\t\t<td>&nbsp;</td>
\t\t\t<td>&nbsp;</td>
\t\t</tr>
\t</tbody>
</table>
</div>`;
      }

      this.$refs.ckEditor.instance.insertHtml(html)
    }
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    imageClick(url){
      let link=apiServerUtil.toDownloadLink(url);
      this.$refs.ckEditor.instance.insertHtml("<img src='"+link+"'>")
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
