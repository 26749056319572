import * as ubossDeclare from "@/pages/commonUBoss/ubossDeclare";   // eslint-disable-line
// initial state
// shape: [{ id, quantity }]
const state = () => ({
    formData: [],
    isValidateFail:false,
    errorsBag:[],
    subscribeWebPartIds:[]
})


// getters
const getters = {
    subscribeWebPartIdsCount: (state, getters) => {   // eslint-disable-line
        if (state.subscribeWebPartIds){
            return state.subscribeWebPartIds.length;
        }
        return 0;
    }
}

// actions
const actions = {
    initStore ({ commit }) {    // eslint-disable-line
        commit('clearFormData')
    },
    resetStore({ commit }){     // eslint-disable-line
        // commit('setCart', undefined)
    },
    formDataSave ({ state, commit }, formDataObj) {     // eslint-disable-line
        commit('setFormData', formDataObj)
    },
    validateFail({ commit }){
        commit('setValidateFail')
    },
    errorsBag({ commit },data){
        commit('setErrorsBag',data)
    },
    addSubscribe({ commit },webPartId){
        commit('setSubscribeWebPartIds',webPartId)
    },
}

// mutations
const mutations = {
    clearFormData (state) {// eslint-disable-line
        state.formData=[]
        state.errorsBag=[]
        state.webPartId=[]
    },
    setFormData (state, formDataObj) {// eslint-disable-line
        // if (!Array.isArray(state.formData)){
        //     state.formData=[]
        // }
        let formDataArr= Object.entries(formDataObj).map(([key, value]) => ({key,value}))
        state.formData=state.formData.concat(formDataArr);
    },
    setValidateFail (state) {// eslint-disable-line
        state.isValidateFail =true
    },
    setErrorsBag(state,data){
        state.errorsBag=state.errorsBag.concat(data);
    },
    setSubscribeWebPartIds(state,webPartId){
        state.subscribeWebPartIds=state.subscribeWebPartIds.push(webPartId);
    }
}

//---------------------------------------

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
