<template>
  <div class="" >
    <b-modal ref="my-modal" hide-footer title="請輸入要連結的網址">
      <div class="d-block text-center">
        <!--        <h3>Hello From My Modal!</h3>-->
      </div>

      <div class="form-group row">
        <label for="" class="col-sm-2">連結</label>
        <div class="col-sm-10">
<!--          //CC-->
          <input type="text" class="form-control" placeholder="" v-model="partImage.LinkUrl" >
          <label class="checkbox-inline"><input type="checkbox" v-model="partImage.LinkTarget" value="_blank">開新視窗</label>
          <!--          <div class="message">{{ validation.firstError('formData.CONTENT') }}</div>-->
        </div>
      </div>
      <b-button class="mt-2" variant="btn btn-primary" block @click="toggleModal()">儲存</b-button>
      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>
  </div>

</template>

<script>

//use this.$refs.settingSliderImage.showModal();
//use < ref="" :webPart="" v-on:returnOk="blockWebPartSelectorReturnOk">
//import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";

export default {
  // props:["partImage"],
  // components: {
  //   HelloWorld
  // }

  props: {
    partImage: {
      type: Object,
      default () {  //因為這個是 WebPartImageDesigner 是單體，而mounted 時，需要給一個空的物件，以免上面在bind時，會有錯誤訊息
        return {
          LinkUrl:"",
          LinkTarget:""
        }
      }
    },
  },
  data: function() {
    return {
      showThis:false,
    };
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')

      // onePageUtil.webPartHtml_Save(this.areaId,this.blockId,this.formData.content)

      this.$emit('returnOk');
    },
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
