<template>
  <div class="" >
    <b-modal ref="my-modal" hide-footer title="設定" size="xl">
      <div class="d-block text-center">
        <!--        <h3>Hello From My Modal!</h3>-->
      </div>

      <div class="form-group row">
        <label for="" class="col-sm-2">網頁內容</label>
        <div class="col-sm-10">
          <MyCkEditor ref="myCkEditor" v-model="propsValue"  height="400" width="800" :file-upload-list-lite_is-show="false" ></MyCkEditor>
        </div>
      </div>
      <b-button class="mt-2" variant="btn btn-primary" block @click="toggleModal()">儲存</b-button>
      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>
  </div>

</template>

<script>
//簡易
//<a role="button" class="btn btn-primary editButton" @click="$refs.editorDialog.showModal()">Edit</a>

//多個物件，共用一個editor

// <a class="btn btn-primary btn-sm " @click="showDialog(item)"  ><i class="fa fa-pen"></i></a>
//
//
// <MyCkEditorDialog ref="editorDialog" v-model="currentHtml" v-on:returnOk="eventEditorDialogOK"></MyCkEditorDialog>
//
//
// data
// currentHtml:"",
//     currentEditorItem:null,
//
//     method
// showDialog(item){
//   this.currentEditorItem=item;
//   this.currentHtml=item.Title;
//   this.$refs.editorDialog.showModal();
// },
// eventEditorDialogOK(value){
//   this.currentEditorItem.Title=value;
// },

//use this.$refs.settingSliderImage.showModal();
//use < ref="" :webPart="" v-on:returnOk="blockWebPartSelectorReturnOk">
//import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";
import MyCkEditor from "@/pages/commonComponent/MyCkEditor";

export default {
  props: ["value"],
  components: {
    MyCkEditor
  },

  data: function() {
    return {
      showThis:false,
      propsValue:this.value,
    };
  },
  watch: {
    propsValue(newVal){
      this.$emit('input', newVal);
    },
    value(newVal){
      this.propsValue = newVal
    }
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    toggleModal() {
      this.$emit('returnOk',this.value);
      this.$refs['my-modal'].toggle('#toggle-btn')

      // onePageUtil.webPartHtml_Save(this.areaId,this.blockId,this.formData.content)

    },
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
