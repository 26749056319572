<template>
  <div class="">
    <hr/>

    <WebPartHRSetting ref="setting" ></WebPartHRSetting>

  </div>

</template>

<script>
// import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";
// import util from "@/pages/common/util";
import apiServerUtil from "@/pages/common/apiServerUtil";
import WebPartHRSetting from "@/pages/featureOnePage/webParts/WebPartHRSetting";

export default {
  components: {WebPartHRSetting},
  props:["webPart"],
  data: function() {
    return {

    };
  },
  computed:{
    myWebPart() {
      return this.webPart;
    },
  },
  methods: {

    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    setting(partImage){
      this.$refs.setting.partImage=partImage
      this.$refs.setting.showModal();

    },
    toDownloadLink(isMobile,imageFileUrl){
      return apiServerUtil.getPicLinkOnePage(isMobile,imageFileUrl);
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
