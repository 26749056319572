<template>
  <div class="" >

    <div v-if="this.timeType=== this.$onePageUtil.OnePage_TimeType_Design">

      <div class="form-group row">
        <label for="" class="col-sm-2">影片網址</label>
        <div class="col-sm-10">
          <input type="url" class="form-control" placeholder="" v-model="propsWebPart.VideoLink">
        </div>
      </div>
      <div class="form-group row">
        <youtube :video-id="propsWebPart.VideoId" player-width="100%" max-width="1140" player-height="641"></youtube>
      </div>

    </div>
    <div v-else>

      <!--mobile-->
      <div class="d-block d-sm-none">
        <youtube :video-id="propsWebPart.VideoId" player-width="100%"></youtube>
      </div>

      <!--desktop-->
      <div class="d-none d-sm-block">
        <youtube :video-id="propsWebPart.VideoId" player-width="100%" max-width="1140" player-height="641" :width="1140" :height="500"></youtube>
      </div>


    </div>

  </div>

</template>

<script>
import { getIdFromURL } from 'vue-youtube-embed'
import DesignTimeSupport from "@/pages/featureOnePage/base/DesignTimeSupport";

export default {
  props: {
    webPart: {},
  },
  mixins: [DesignTimeSupport],
  data: function() {
    return {
      /** @type {JWebPartVideo} */
      propsWebPart: this.webPart,
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  watch: {
    'webPart.VideoLink'() {
      let videoId = getIdFromURL(this.propsWebPart.VideoLink)

      this.propsWebPart.VideoId=videoId;
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
