import { render, staticRenderFns } from "./BlockListView2.vue?vue&type=template&id=7ca3c688&scoped=true&"
import script from "./BlockListView2.vue?vue&type=script&lang=js&"
export * from "./BlockListView2.vue?vue&type=script&lang=js&"
import style0 from "./BlockListView2.vue?vue&type=style&index=0&id=7ca3c688&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ca3c688",
  null
  
)

export default component.exports