<template>
  <div class="" >
    <b-modal ref="my-modal" hide-footer title="設定" size="lg">
      <div class="d-block text-center">
        <!--        <h3>Hello From My Modal!</h3>-->
      </div>
      <div class="form-group row">
        <label for="" class="col-sm-2">網站標題</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" placeholder="" v-model="propsCommon.SiteTitle" >
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="col-sm-2">網站圖示(.ico)</label>
        <div class="col-sm-8">
          <FileUploadButton :emmit-back-param="undefined" v-on:uploadSuccess="uploadSuccessIcon" :upload-type="$u.UBossUploadType_OnePage"></FileUploadButton>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="col-sm-2">網站Logo</label>
        <div class="col-sm-10">
          <FileUploadButton :emmit-back-param="undefined" v-on:uploadSuccess="uploadSuccessLogo" :upload-type="$u.UBossUploadType_OnePage"></FileUploadButton>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="col-sm-2">對齊</label>
        <div class="col-sm-10">
          Logo對齊
          <select class="form-control aselect" v-model="propsHeader.LogoAlign">
            <option value="center">置中</option>
            <option value="left">靠左</option>
          </select>
          選單對齊
          <select class="form-control aselect" v-model="propsHeader.MenuAlign">
            <option value="left">置左</option>
            <option value="right">靠右</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="" class="col-sm-2">文字顏色</label>
        <div class="col-sm-10">
          <MyColorPicker v-model="propsHeader.FontColor"></MyColorPicker>
        </div>
      </div>

      <div class="form-group row">
        <label for="" class="col-sm-2">標題選單顏色</label>
        <div class="col-sm-10">
          <MyColorPicker v-model="propsHeader.BackgroundColor"></MyColorPicker>
        </div>
      </div>

      <div class="form-group row">
        <label for="" class="col-sm-2">背景設定</label>
        <div class="col-sm-10">
          <MyCSSBackground :common="propsCommon"></MyCSSBackground>
        </div>
      </div>


<!--      <div class="form-group row">-->
<!--        <label for="" class="col-sm-2">背景圖片</label>-->
<!--        <div class="col-sm-10">-->
<!--          <input type="text" class="form-control"  placeholder="" v-model="propsHeader.BackgroundImageUrl">-->
<!--        </div>-->
<!--      </div>-->

      <div class="form-group row">
        <label for="" class="col-sm-2">連結</label>
        <div class="col-sm-10">
          <MyListUrls v-model="propsHeader.MenuLinks"></MyListUrls>
        </div>
      </div>

      <b-button class="mt-2" variant="btn btn-primary" block @click="toggleModal()">儲存</b-button>
      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>
  </div>

</template>

<script>
import FileUploadButton from "@/pages/commonComponent/FileUploadButton.vue";
import util from "@/pages/common/util";
import MyColorPicker from "@/pages/commonComponent/MyColorPicker.vue";
import MyListUrls from "@/pages/commonComponent/MyListUrls.vue";
import MyCSSBackground from "@/pages/commonComponent/MyCSSBackground.vue";


export default {
  components: {MyCSSBackground, MyListUrls, MyColorPicker, FileUploadButton},
  props: {
    Header: {},
    Common: {},
  },
  data: function() {
    return {
      /** @type {JOnePageHeader} */
      propsHeader: this.Header,
      /** @type {JOnePageCommon} */
      propsCommon: this.Header,
      showThis:false,
    };
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')

      this.$emit('returnOk');
    },
    uploadSuccessIcon(image,result){
      if (result && result.FileCount>0){
        this.propsCommon.FavIcon=result.FileUrls[0]
      }else{
        util.ShowMessage("檔案上傳失敗")
      }

    },
    uploadSuccessLogo(image,result){
      if (result && result.FileCount>0){
        this.propsHeader.LogoUrl=result.FileUrls[0]
      }else{
        util.ShowMessage("檔案上傳失敗")
      }

    },
    async loadData(){

    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
