// export const UTubeMediaTypeEnum_VideoUrl=3



/**

 <span v-if="$c.getCustomizeConfig().ProductBooking_Enable">

 </span>
 <span v-else>

 </span>

 # 多顯示一整個 vue 元件
 ```
 <span v-if="$c.getCustomizeConfig().ProductOptions_IsCustomize">
 <ProductOptionSelector :product-option="$c[$c.getCustomizeConfig().ProductOptions_DS]"></ProductOptionSelector>
 </span>
 <span v-else>

 </span>
 ```

 # 多顯示一個客製的欄位，供輸入
 ```
 <span v-if="$c.getCustomizeConfig().ProductExtValue_Enable">
 <div class="form-group row">
 <label for="" class="a_thinlabel col-sm-2">{{ $c.getCustomizeConfig().ProductExtValue_LabelText }}</label>
 <div class="a_thinInputDiv col-sm-10">
 <textarea class="form-control" rows="3" ></textarea>
 <!--                    //v-model="formData.Memo"-->
 </div>
 </div>
 </span>
 ```
 */
const CustomizeTemplate=
    {

        ProductExtValue_Enable:false,
        ProductExtValue_LabelText:"",

        ProductOptions_Enable:true,    //先關閉 先不要有商品選項

        ProductOptions_IsCustomize:false,
        // ProductOptions_VueCompoent:"ProductOptionSelectorPpd",

        ProductBooking_Enable:false,        //預約

        ShopCart_EnableCustomize:false,
        ShopCart_HideShipFee:false,

        ShopList_EnableAddCartButton:true,

        Member_HideShipAddress:false,

        EInvoice_Enable:false,      //目前沒有用
    };


const PPD_ProductOptions= { // eslint-disable-line
    IsEnableOptionTable: true,
        OptionSets: {
        Items: [
            {
                SystemId: "",
                OrderKey: "1",
                Text: "溫度",
                Options: [
                    {
                        SystemId: "",
                        OrderKey: "1",
                        Text: "冰飲",
                    },
                    {
                        SystemId: "",
                        OrderKey: "2",
                        Text: "熱飲",
                    },
                ],
            },
            {
                SystemId: "",
                OrderKey: "1",
                Text: "甜度",
                Options: [
                    {
                        SystemId: "",
                        OrderKey: "1",
                        Text: "原味甜",
                    },
                    {
                        SystemId: "",
                        OrderKey: "2",
                        Text: "三分",
                    },
                    {
                        SystemId: "",
                        OrderKey: "2",
                        Text: "五分",
                    },
                    {
                        SystemId: "",
                        OrderKey: "2",
                        Text: "七分",
                    },
                    {
                        SystemId: "",
                        OrderKey: "2",
                        Text: "正常",
                    },
                ],
            },
            {
                SystemId: "",
                OrderKey: "2",
                Text: "冰塊",
                Options: [
                    {
                        SystemId: "",
                        OrderKey: "1",
                        Text: "去冰",
                    },
                    {
                        SystemId: "",
                        OrderKey: "1",
                        Text: "微冰",
                    },
                    {
                        SystemId: "",
                        OrderKey: "1",
                        Text: "少冰",
                    },
                    {
                        SystemId: "",
                        OrderKey: "1",
                        Text: "正常",
                    },
                ],
            },
        ]
    },
    OptionTable: {
        Items: [
            // {
            //     OptionText: "顏色",
            //     OptionItem1Text: "白",
            //     OptionItem2Text: "大",
            //     Price: 100,
            //     Quantity: 0,
            //     ProductNo: "",
            // },
            // {
            //     OptionText: "顏色",
            //     OptionItem1Text: "白",
            //     OptionItem2Text: "中",
            //     Price: 90,
            //     Quantity: 0,
            //     ProductNo: "",
            // },
            // {
            //     OptionText: "顏色",
            //     OptionItem1Text: "白",
            //     OptionItem2Text: "小",
            //     Price: 80,
            //     Quantity: 0,
            //     ProductNo: "",
            // },
        ]
    }
}

import ubossCommon from "@/pages/commonUBoss/ubossCommon";

export default class {
    constructor() {

    }

    getCustomizeConfig() {
        let customeCode=ubossCommon.getCustomeCode();
        if (customeCode==="paypaydrink" || customeCode==="paypaydrink2") {
            let myConfig = {...CustomizeTemplate};

            myConfig.ProductExtValue_Enable = true;
            myConfig.ProductExtValue_LabelText = "產品配方";

            myConfig.ProductOptions_Enable = false;

            myConfig.ProductOptions_IsCustomize = true;

            myConfig.ShopCart_EnableCustomize = true;
            myConfig.ShopCart_HideShipFee = true;

            myConfig.ShopList_EnableAddCartButton = false;

            myConfig.Member_HideShipAddress = true;

            return myConfig;
        }else if (customeCode==="storyteller1111"){
            let myConfig = {...CustomizeTemplate};

            myConfig.ProductBooking_Enable = false;

            return myConfig;
        }else if (customeCode==="bodytalk"){
            let myConfig = {...CustomizeTemplate};

            myConfig.ProductBooking_Enable = true;

            return myConfig;
        }else{
            return CustomizeTemplate;
        }
    }
}
