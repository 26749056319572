var apiUrl=process.env.VUE_APP_apiUrl;
var apiPathBase="api/UBossOnePageApi/";
import AxiosManager from "@/pages/common/axiosLib/axiosManager";
import * as ubossCommon from "@/pages/commonUBoss/ubossCommon";
import ubossCrudApiManager from "@/pages/commonUBoss/ubossCrudApiManager";

export default {
    //---------------axios------------------------
    getAxiosManager(){
        return new AxiosManager(ubossCommon.SessionKeyPrefix_Bk + ubossCommon.TokenKeyLastFix)
    },
    //---------------------------------------
    getApiManager_General() {
        return new ubossCrudApiManager(apiUrl,apiPathBase,"", this.getAxiosManager(),null)
    },
    //---------------------------------------
    //SystemId為空白則由api自動判斷
    async api_GetOnePage(SystemId) {
        var sendData={
            SystemId:SystemId,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("GetOnePage",sendData);
    },

    async api_GetOnePage_NoDefaultPage(SystemId) {
        var sendData={
            SystemId:SystemId,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("GetOnePage_NoDefaultPage",sendData);
    },

    async api_Get_OnePageAdv_ById(onePageAdvSystemId,cnameSystemId) {
        var sendData={
            onePageAdvSystemId:onePageAdvSystemId,
            cnameSystemId:cnameSystemId,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("Get_OnePageAdv_ById",sendData);
    },

    //SystemId為空白則由api自動判斷
    async api_Save_OnePage(systemId,json) {
        var method="Save_OnePage?SystemId=" + systemId ;
        var sendData={
            json:json,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },
    //SystemId為空白則由api自動判斷
    async api_GetOnePagePro(SystemId) {
        var sendData={
            SystemId:SystemId,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("GetOnePagePro",sendData);
    },

    //SystemId為空白則由api自動判斷
    async api_Save_OnePagePro(systemId,json) {
        var method="Save_OnePagePro?SystemId=" + systemId ;
        var sendData={
            json:json,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },

    async api_OnePage_Init() {
        var method="OnePage_Init";
        var sendData={

        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },


    async api_OnePage_ClonePage(json) {
        var method="OnePage_ClonePage";
        var sendData={
            json:json,
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj(method,sendData);
    },

    async api_Get_OnePageAdv() {
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("Get_OnePageAdv",sendData);
    },

    async api_Get_OnePageAdvMenu() {
        var sendData={
        };

        return this.getApiManager_General().api_CallMethod_ByParamObj("Get_OnePageAdvMenu",sendData);
    },

}
