<template>
  <div>
<!--    <input type="text" className="form-control" placeholder="" v-model="propsValue">-->

<!--    <kendo-listbox id="optional"-->
<!--                   :draggable="true"-->
<!--                   :connect-with="'selected'"-->
<!--                   :drop-sources="['selected']"-->
<!--                   :toolbar-tools="['moveUp', 'moveDown', 'transferTo', 'transferFrom', 'transferAllTo', 'transferAllFrom', 'remove']"-->
<!--    >-->
<!--      <option>Steven White</option>-->
<!--      <option>Nancy King</option>-->
<!--      <option>Nancy Davolio</option>-->
<!--      <option>Robert Davolio</option>-->
<!--      <option>Michael Leverling</option>-->
<!--      <option>Andrew Callahan</option>-->
<!--      <option>Michael Suyama</option>-->
<!--    </kendo-listbox>-->
<!--    <kendo-listbox id="selected"-->
<!--                   :draggable="true"-->
<!--                   :connect-with="'optional'"-->
<!--                   :drop-sources="['optional']"-->
<!--                   style="height:280px;">-->
<!--      <option>Michael Suyama</option>-->
<!--    </kendo-listbox>-->


    <kendo-listbox id="lstSelected" style="width:300px;height:400px"
                   :draggable="true"
                   :connect-with="'lstAll'"
                   :drop-sources="['lstAll']"
                   :toolbar-tools="['moveUp', 'moveDown', 'transferTo', 'transferFrom', 'transferAllTo', 'transferAllFrom', 'remove']"
    >
      <option v-for="(item,index) in value" :key="index">{{ item }}</option>
    </kendo-listbox>


    <kendo-listbox id="lstAll"
                   :draggable="true"
                   :connect-with="'lstSelected'"
                   :drop-sources="['lstSelected']"
                   style="width:300px;height:400px">

      <option v-for="(item,index) in datasourceLeft" :key="index">{{ item }}</option>
<!--      <option>又一春青茶x</option>-->
<!--      <option>緹拉兔系列文青包</option>-->
<!--      <option>緹拉兔口罩</option>-->
<!--      <option>測試商品</option>-->
<!--      <option>Robert Davolio</option>-->
<!--      <option>Michael Leverling</option>-->
<!--      <option>Andrew Callahan</option>-->
<!--      <option>Michael Suyama</option>-->
    </kendo-listbox>
  </div>
</template>

<script>
/**

 **/

import { ListBox } from '@progress/kendo-listbox-vue-wrapper';
// import apiUBossShop from "@/pages/ubossShop/util/apiUBossShop";

export default {
  components: {
    'kendo-listbox': ListBox
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    datasourceLeft: {
      type: Array,
      default() {
        return {
          // rightOptions:[],
        }
      }
    },
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      propsValue: this.value,
      formData: {
        name: "",
      },
      // datasourceLeft:[]
    };
  },
  watch: {
    propsValue(newVal) {
      this.$emit('input', newVal);
      // this.('input', newVal);
    },
    value(newVal) {
      this.propsValue = newVal
    }
  },
  methods: {
    //給予value屬性預設值
    initValue() {
      const isMyObjectEmpty = !Object.keys(this.value).length;

      if (isMyObjectEmpty) {
        let template = {...this.datasDemo};
        this.value = template;
      }
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data

      // let that=this;
      //
      // apiUBossShop.api_Product_ClientQuery(null).then((data)=>{
      //   that.datasourceLeft=data;
      // })

      this.datasourceLeft=['茶23','又一春青茶x']
    },
  },
  mounted() {
    // this.initValue()
    this.loadData()
  },
}
</script>

<style scoped>

</style>
