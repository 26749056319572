<template>
  <div>
<!--Debug 資訊panel-->
<!--    <div>-->
<!--      <span>總共有幾個form input webPart:</span>-->
<!--      {{this.subscribeWebPartIds.length}} 個 ,Detail:{{this.subscribeWebPartIds}}-->

<!--      <br>-->
<!--      <span>isValidateFail:</span>-->
<!--      {{isValidateFail}}-->
<!--      <br>-->
<!--      <span>Data</span>-->
<!--      <ul>-->
<!--        <li v-for="(item,index) in this.formDataAll" :key="index">-->
<!--          {{item.key}} &#45;&#45; {{item.value}}-->
<!--        </li>-->

<!--      </ul>-->
<!--      <br>-->
<!--    </div>-->

    <div class="d-flex justify-content-center" >
      <a role="button" class="btn btn-block btn-lg btn-primary" @click="submit">送出</a>
    </div>

  </div>

</template>

<script>
import veeHelper from "@/pages/common/veeHelper";
import util from "@/pages/common/util";
import apiUBossForm from "@/pages/featureOnePage/util/apiUBossForm";

export default {
  props:["relFormSystemId"],
  data: function() {
    return {
      formDataAll: [],
      errorsBag:[],
      subscribeWebPartIds:[],
      isValidateFail:false,

      subscribeWebPartIdsSendedCount:0,
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    onComplete(){
      let errorMsg = veeHelper.VeeGetErrorMsgAllStr(this.errorsBag);
      util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
    },
    submit(){
      this.formDataAll=[]
      this.errorsBag=[]
      // this.subscribeWebPartIds=[]    不可清除
      this.isValidateFail=false;

      this.subscribeWebPartIdsSendedCount=0;

      this.$bus.$emit(this.$u.UBossEvent_FormSubmiting,this.submited);
    },
    submited(isValid,errors,formDataObj){
      // this.errorsBag=this.errorsBag.concat(errors);

      this.errorsBag = {
        ...this.errorsBag,
        ...errors
      };

      let formDataArr= Object.entries(formDataObj).map(([key, value]) => ({key,value}))
      this.formDataAll=this.formDataAll.concat(formDataArr);

      if (!isValid){
        this.isValidateFail=true;
      }
      //---------------------------------------
      this.subscribeWebPartIdsSendedCount=this.subscribeWebPartIdsSendedCount+1
      if (this.subscribeWebPartIdsSendedCount===this.subscribeWebPartIds.length){
        if (!isValid) {
          let errorMsg = veeHelper.VeeGetErrorMsgAllStr(this.errorsBag);
          util.ShowMessage('部分資料未填寫完整' + "<br/>" + errorMsg)
          return;
        } else {
          //---------------------------------------
          let record={
            SystemId:"",
            RelFormSystemId:this.relFormSystemId,
            FormData:{
              Fields:this.formDataAll
            }
          }

          apiUBossForm.api_FormData_Save_Web(record).then(()=>{
            util.ShowMessage("儲存成功","訊息")
          })
          //---------------------------------------
        }
      }
    }
  },
  created() {
    // let that=this;
    this.$bus.$on(this.$u.UBossEvent_FormWebPartInputReg, (webPartId) => {
      // if (!Array.isArray(that.subscribeWebPartIds)){
      //   that.subscribeWebPartIds=[]
      // }
      this.subscribeWebPartIds.push(webPartId)
    });

    this.loadData()
  },
  beforeDestroy: function() {
    this.$bus.$off(this.$u.UBossEvent_FormSubmited);
  },
}
</script>
