<template>
  <div class="" >
    <span v-html="webPart.PartHtmlContent"></span>
  </div>

</template>

<script>

export default {
  props:["webPart"],
  data: function() {
    return {
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
