<template>
  <div class="" >

    <h1>預約商品</h1>


  </div>

</template>

<script>


import apiServerUtil from "@/pages/common/apiServerUtil";

export default {
  props:["webPart"],
  data: function() {
    return {
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    toDownloadLink(isMobile,imageFileUrl){
      return apiServerUtil.getPicLinkOnePage(isMobile,imageFileUrl);
    }
  },
  mounted() {
    this.loadData()
  },
}

</script>

<style scoped>
/*.crop {*/
/*  width: 600px;*/
/*  height: 200px;*/
/*  overflow: hidden;*/
/*  !*     margin: 10px; *!*/
/*  position: relative;*/
/*}*/
/*.crop img {*/
/*  position: absolute;*/
/*  left: -1000%;*/
/*  right: -1000%;*/
/*  top: -1000%;*/
/*  bottom: -1000%;*/
/*  margin: auto;*/
/*  min-height: 100%;*/
/*  min-width: 100%;*/
/*}*/
</style>
