export const DB_FormDemo = {
    "Common": {
        "SiteTitle": "紹瑄",
        "FavIcon": "/Data/OnePage/shouldbio/0.ico",
        "BackgroundColor": "#EBACACFF",
        "CssStyle": ""
    },
    "Header": {
        "BackgroundColor": "#BE6666FF"
    },
    "Footer": {
        "HtmlContent": "<div class=\"bg-light py-4\">\n<div class=\"container text-center\">\n<p class=\"text-muted mb-0 py-2\">&copy; 2021 紹瑄生技&nbsp;All rights reserved&nbsp;</p>\n</div>\n</div>\n"
    },
    "WebParts": [
        {
            Id:"855b9b3d-ee44-4862-bfa3-010248f0d96b",
            Title:"form",
            WebPartType:"form",
            OrderKey:"",
            Fields:[
                {
                    Id:"a",
                    SpecifyId:"",
                    Title:"text",
                    InputType:"text",
                    Values:"",
                    DefaultValue:"",
                    ValidateRule:"",
                    Visible:"",
                    OrderKey:"",
                    InputValue:"",
                },
                {
                    Id:"b",
                    SpecifyId:"",
                    Title:"textArea",
                    InputType:"textArea",
                    Values:"",
                    DefaultValue:"",
                    ValidateRule:"",
                    Visible:"",
                    OrderKey:"",
                    InputValue:"",
                },
                {
                    Id:"c",
                    SpecifyId:"",
                    Title:"select",
                    InputType:"select",
                    Values:"",
                    DefaultValue:"",
                    ValidateRule:"",
                    Visible:"",
                    OrderKey:"",
                    InputValue:"",
                },
                {
                    Id:"d",
                    SpecifyId:"",
                    Title:"checkbox",
                    InputType:"checkbox",
                    Values:"",
                    DefaultValue:"",
                    ValidateRule:"",
                    Visible:"",
                    OrderKey:"",
                    InputValue:"",
                },
                {
                    Id:"e",
                    SpecifyId:"",
                    Title:"radio",
                    InputType:"radio",
                    Values:"",
                    DefaultValue:"",
                    ValidateRule:"",
                    Visible:"",
                    OrderKey:"",
                    InputValue:"",
                },
            ]
        },
    ]
}

export const InputType_Text = "text"
export const InputType_TextArea = "textarea"
export const InputType_Select = "select"
export const InputType_CheckBox = "checkbox"
export const InputType_Radio = "radio"
export const InputType_Shop= "shop"

export default {
    test() {

    }
}
