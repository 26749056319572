<template>
  <div class="noMarginForForm">
    <div class="row" v-if="this.webPart.DescText">
      <h3 for="" class="col-sm-3 formFieldLabel">{{this.webPart.DescText}}</h3>
    </div>
    <div class="row" v-if="this.webPart.Desc">
      <span class="col-sm-1"></span>
      <span  class="col-sm-11" v-html="this.webPart.Desc">

      </span>
    </div>
    <div>
      <div class="row">
        <div class="col-sm-12 formFieldInputBlock"  >
          <ValidationObserver ref="form">
            <validation-provider rules="required" v-slot="{ errors }" :name="webPart.DescText">
              <div class="form-check" v-for="(item,index) in webPart.ShopItems" :key="index">
                  <!--              需要設定name,否則vee會無法運作-->
                  <input class="form-check-input" type="radio" :value="item.Text" :id="'rdo'+item.Id + index" name="shopItem" v-model="formData.SelectItem">
                  <label class="form-check-label" :for="'rdo'+item.Id+index" >
                    {{item.Text}}
                  </label>
              </div>
              <span class="error_msg">{{ errors[0] }}</span>
            </validation-provider>
          </ValidationObserver>
        </div>
      </div>

    </div>
  </div>

</template>

<script>

export default {
  props:["webPart"],
  data: function() {
    return {
      formData:{
        SelectItem:"",
      }
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    let that=this
    this.$bus.$emit(this.$u.UBossEvent_FormWebPartInputReg,this.webPart.Id);

    this.$bus.$on(this.$u.UBossEvent_FormSubmiting, callback => { // eslint-disable-line

      that.$refs.form.validateWithInfo().then(({ isValid, errors }) => {  // eslint-disable-line
        let formDataToSave=[];

        formDataToSave[that.webPart.Id]=this.formData.SelectItem;

        callback(isValid,errors,formDataToSave);
      });

    });

    this.loadData()
  },
  beforeDestroy: function() {
    this.$bus.$off(this.$u.UBossEvent_FormSubmiting);
  },
}
</script>
<style scoped>
.noMarginForForm{
  padding:25px !important;
}

</style>