<template>
  <div>
    <div class="form-group row" style="">
      <label class="col-sm-2 labelClass" style="color: #2c9faf">填寫標題</label>
      <div class="col-sm-10">
        <input type="text" class="form-control"  placeholder="" v-model="webPart.DescText">
      </div>
    </div>
    <div class="form-group row" style="">
      <label class="col-sm-2 labelClass" style="color: #2c9faf">填寫說明文字</label>
      <div class="col-sm-10">
        <MyCkEditor ref="myCkEditor" v-model="webPart.Desc"  height="200" width="100%" :file-upload-list-lite_is-show="true" :file-upload-list-lite_owner-system-id="webPart.Id"  :file-upload-list-lite_file-use-memo="this.$u.UBossFileUseType_OnePageWebPartFormShop"></MyCkEditor>
      </div>
    </div>
    <a class="btn btn-primary" @click="DataItem_AddLast"><i class="fas fa-plus-circle"></i></a>
    <table class="table table-hover" id="tblMain">
      <thead class="thead-light">
      <tr>
        <th class="text-center">功能</th>
        <th>名稱</th>
        <th>價格</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) in this.webPart.ShopItems" :key="index">
        <td class="text-center" width="200px">
          <a class="btn btn-primary" @click="DataItem_Insert(item)"><i class="fas fa-plus-circle"></i></a>
          <a class="btn btn-danger" @click="DataItem_Remove(item)"><i class="far fa-trash-alt"></i></a>
          <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>
          <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>
        </td>
        <td >
          <input type="text" class="form-control" placeholder="" v-model="item.Text" >
        </td>
        <td >
          <input type="number" class="form-control" placeholder="" v-model="item.Price">
        </td>
      </tr>

      </tbody>
    </table>

  </div>
</template>

<script>

import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";
import MyCkEditor from "@/pages/commonComponent/MyCkEditor";

export default {
  components: {MyCkEditor},
  props:["webPart"],
  data: function() {
    return {
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.Id,this.webPart.ShopItems,"Id","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.Id,this.webPart.ShopItems,"Id","OrderKey")
    },
    DataItem_AddLast(){
      let newItem={
        Id:util.uuid(),
        Text:"",
        Price:"",
      }

      arrayUtil.orderAppendLast(newItem,this.webPart.ShopItems,"OrderKey")
    },
    DataItem_Insert(currentItem){
      let newItem={
        Id:util.uuid(),
        Text:"",
        Price:"",
      }

      arrayUtil.orderInsert(newItem,currentItem,this.webPart.ShopItems,"Id","OrderKey")
    },
    DataItem_Remove(item){
      this.webPart.ShopItems= this.webPart.ShopItems.filter(s => s.Id !==item.Id);
    },

  },
  mounted() {
    this.loadData()
  },
}
</script>
