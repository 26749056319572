<template>
  <div>
    <component :is="componentType" :field="field"></component>
  </div>
</template>

<script>
import LabelDesigner from "./LabelDesigner"
import SelectDesigner from "./SelectDesigner"
import TextBoxDesigner from "./TextBoxDesigner"
import TextAreaDesigner from "./TextAreaDesigner"
import CheckBoxDesigner from "./CheckBoxDesigner"
import RadioDesigner from "./RadioDesigner"


export default {
  //name: "FormFieldDesigner",
  props: ["field"],
  data: function () {
    return {
      id: "",
      formData: {
        name: "",
      },
      componentType:{}
    };
  },
  components:{ LabelDesigner,SelectDesigner,TextBoxDesigner,RadioDesigner,CheckBoxDesigner,TextAreaDesigner},
  computed:{
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  created() {
    if (this.field.InputType===this.$formUtil.InputType_Text){
      this.componentType= "TextBoxDesigner"
    }else if (this.field.InputType===this.$formUtil.InputType_TextArea){
      this.componentType= "TextAreaDesigner"
    }else if (this.field.InputType===this.$formUtil.InputType_Select){
      this.componentType= "SelectDesigner"
    }else if (this.field.InputType===this.$formUtil.InputType_CheckBox){
      this.componentType= "CheckBoxDesigner"
    }else if (this.field.InputType===this.$formUtil.InputType_Radio){
      this.componentType= "RadioDesigner"
    }else if (this.field.InputType===this.$formUtil.InputType_Shop){
      this.componentType= "ShopDesigner"
    }
  },
  mounted() {


    //this.loadData()

  },
}
</script>

<style scoped>

</style>
