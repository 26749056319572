
export default {
    VeeGetErrorMsgAllStr(errors){
        let errorsMsg=this.VeeGetErrorMsgAll(errors)

        return errorsMsg.join("<br/>")
    },
    VeeGetErrorMsgAll(errors){
        let errorsMsg=[]

        for (let error in errors) {
            if (errors[error].length>0){
                errorsMsg.push("「"+error+"」"+errors[error])
            }
        }

        return errorsMsg
    }

}