<template>
  <div class="noMarginForForm" >
    <div class="row" v-if="this.webPart.DescText">
      <h3 for="" class="col-sm-3 formFieldLabel">{{this.webPart.DescText}}</h3>
    </div>
    <div class="row" v-if="this.webPart.Desc">
      <span class="col-sm-1"></span>
      <span  class="col-sm-11" v-html="this.webPart.Desc">

      </span>
    </div>
<!--    <FormFieldRunner :field="this.webPart.Fields[4]" :field-value.sync="formData.a" ></FormFieldRunner>-->

    <ValidationObserver ref="form">
    <div v-for="(item,index) in this.webPart.Fields" :key="index">
      <FormFieldRunner :field="item" :field-value.sync="formData[item.Id]" ></FormFieldRunner>
    </div>
    </ValidationObserver>

  </div>

</template>

<script>
import FormFieldRunner from "./formField/FormFieldRunner";
// import veeHelper from "@/pages/common/veeHelper";
// import util from "@/pages/common/util";
// import apiUBossOnePage from "@/pages/featureOnePage/util/apiUBossOnePage";

export default {
  props:["webPart"],
  components:{FormFieldRunner},
  data: function() {
    return {
      formData:[],
      webPartDemo:{
        Id:"855b9b3d-ee44-4862-bfa3-010248f0d96b",
        Title:"form",
        WebPartType:"form",
        OrderKey:"",
        Fields:[
          {
            Id:"a",
            SpecifyId:"",
            Title:"",
            inputType:"",
            Values:"",
            DefaultValue:"",
            ValidateRule:"",
            Visible:"",
            OrderKey:"",
          },
        ]
      },
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data

      //產生一個預設的object
      if (!this.formData){
        this.formData={
        }
      }

      for (let field of this.webPart.Fields) {
        //formData的所有property,就是field的Id (GUID)
        //如果formData ,少了某個屬性，就新增之
        if (!Object.prototype.hasOwnProperty.call(this.formData, field.Id)){
          if (field.InputType===this.$formUtil.InputType_CheckBox){
            this.formData[field.Id]=[];
          }else{
            this.formData[field.Id]="";
          }

        }
      }
    },

  },
  mounted() {
    let that=this
    this.$bus.$emit(this.$u.UBossEvent_FormWebPartInputReg,this.webPart.Id);

    this.$bus.$on(this.$u.UBossEvent_FormSubmiting, callback => { // eslint-disable-line

      that.$refs.form.validateWithInfo().then(({ isValid, errors }) => {  // eslint-disable-line
        callback(isValid,errors,that.formData);
      });

    });
  },
  beforeDestroy: function() {
    this.$bus.$off(this.$u.UBossEvent_FormSubmiting);
  },
}
</script>

<style scoped>
.noMarginForForm{
  padding:25px !important;
}

</style>
