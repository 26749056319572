<template>
  <div>
    <div class="btn-toolbar pull-right">
    <a class="btn btn-primary" @click="DataItem_AddLast"><i class="fas fa-plus-circle"></i></a>
    </div>
    <table class="table table-hover" id="tblMain">
      <thead class="thead-light">
      <tr>
        <th class="text-center">功能</th>
        <th>{{configDefault.Column1Text}}</th>
        <th>{{configDefault.Column2Text}}</th>
        <th>{{configDefault.Column3Text}}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) in this.datas" :key="index">
        <td class="text-center">
          <a class="btn btn-primary" @click="DataItem_Insert(item)"><i class="fas fa-plus-circle"></i></a>
          <a class="btn btn-danger" @click="DataItem_Remove(item)"><i class="far fa-trash-alt"></i></a>
          <a class="btn btn-primary" @click="DataItem_MoveUp(item)"><i class="fa fa-angle-up"></i> </a>
          <a class="btn btn-primary" @click="DataItem_MoveDown(item)"><i class="fa fa-angle-down"></i> </a>
        </td>
        <td width="120px">
          <input type="text" class="form-control" placeholder="" v-model="item.FieldText">
        </td>
        <td>
          <select class="form-control" v-model="item.ControlType">
            <option></option>
            <option value="text">文字</option>
            <option value="number">數字</option>
            <option value="date">日期</option>
            <option value="tel">電話</option>
          </select>
        </td>
        <td>
          <div class="form-group">
            <label class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" checked="" v-model="item.MustInput">
              <div class="custom-control-label">必填 </div>
            </label>
          </div>
        </td>
      </tr>

      </tbody>
    </table>

  </div>
</template>

<script>
/**

 **/

import arrayUtil from "@/pages/common/arrayUtil";
import util from "@/pages/common/util";


export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    config:{
      type: Object,
    },
  },
  data: function () {
    return {
      id: "",
      dataReady: false,
      propsValue: this.value,
      formData: {
        name: "",
      },
      configDefault:{
        Column1Text:"問題",
        Column2Text:"回答",
        Column3Text:"必填",
      },
      datas:[
        {
          SystemId:"",
          FieldText:"",
          ControlType:"",
          MustInput:false,
          OrderKey:"999",
        }
      ]
    };
  },
  watch: {
    propsValue(newVal) {
      //this.('input', newVal);
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.propsValue = newVal
    }
  },
  methods: {
    //給予value屬性預設值
    initValue() {
      const isMyObjectEmpty = !Object.keys(this.value).length;

      if (isMyObjectEmpty) {
        let template = {...this.datasDemo};
        this.value = template;
      }
    },
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },

    submit(){
      for (let i = 0; i < this.datas.length; i++) {
        let item = this.datas[i];

        if (item.Year===""){
          util.showErrorMessage("第"+i+1+" 行，「年」為必填欄位")
          return;
        }
        if (item.Month===""){
          util.showErrorMessage("第"+i+1+" 行，「月」為必填欄位")
          return;
        }
        if (item.Type===""){
          util.showErrorMessage("第"+i+1+" 行，「幣別」為必填欄位")
          return;
        }
        if (item.Rate===""){
          util.showErrorMessage("第"+i+1+" 行，「匯率」為必填欄位")
          return;
        }
      }

      // apiGPK.api_Save_ExchangeRate(this.exchangeRate.SystemId,"",this.exchangeRate).then((data)=>{
      //   util.ShowMessage('儲存成功')
      // })
    },

    DataItem_MoveUp(item){
      arrayUtil.orderMove(false,item.SystemId,this.datas,"SystemId","OrderKey")
    },
    DataItem_MoveDown(item){
      arrayUtil.orderMove(true,item.SystemId,this.datas,"SystemId","OrderKey")
    },
    DataItem_AddLast(){
      let newItem={
        SystemId:"",
        FieldText:"",
        ControlType:"",
        MustInput:false,
        OrderKey:"999",
      };
      newItem.SystemId=util.uuid();

      arrayUtil.orderAppendLast(newItem,this.datas,"OrderKey")
    },
    DataItem_Insert(currentItem){
      let newItem={
        SystemId:"",
        FieldText:"",
        ControlType:"",
        MustInput:false,
        OrderKey:"999",
      };
      newItem.SystemId=util.uuid();

      arrayUtil.orderInsert(newItem,currentItem,this.datas,"SystemId","OrderKey")
    },
    DataItem_Remove(item){
      this.datas= this.datas.filter(s => s.SystemId !==item.SystemId);
    },



  },
  mounted() {
    util.ObjectOverride(this.configDefault,this.config);
    this.datas=this.propsValue;
    // this.initValue()
    //this.loadData()
  },
}
</script>

<style scoped>
#tblMain{
  width: 1000px;
}
.btn{
  color: white !important;
  margin: 5px;
  width: 40px;
}
</style>
