<template>
  <div class="" >

<!--    <div v-for="(image,index) in webPart.PartImages" :key="index">-->
<!--      <img :src="image.ImageUrl" style="width: 1024px">-->
<!--    </div>-->
    <div class="crop" v-for="(image,index) in propsWebPart.PartImages" :key="index">


<!--mobile-->
      <div class="d-block d-sm-none">
        <div v-if="image.LinkUrl">
          <a :href="image.LinkUrl" :target="image.LinkTarget">
            <img v-lazy="toDownloadLink(true,image.ImageUrl)" style="width: 100%">
          </a>
        </div>
        <div v-else>
          <img v-lazy="toDownloadLink(true,image.ImageUrl)" style="width: 100%">
        </div>

      </div>
<!--desktop-->
      <div class="d-none d-sm-block">
        <div v-if="image.LinkUrl">
          <a :href="image.LinkUrl" :target="image.LinkTarget">
            <img v-lazy="toDownloadLink(false,image.ImageUrl)" style="width: 100%">
          </a>
        </div>
        <div v-else>
          <img v-lazy="toDownloadLink(false,image.ImageUrl)" style="width: 100%">
        </div>

        <MyLibDragLineTester :image="image" :is-design-time="false"></MyLibDragLineTester>
      </div>
    </div>
  </div>

</template>

<script>


import apiServerUtil from "@/pages/common/apiServerUtil";
import MyLibDragLineTester from "@/pages/commonComponent/LibDragLine/MyLibDragLineTester.vue";

export default {
  components: {MyLibDragLineTester},
  props: {
    webPart: {},
  },
  data: function() {
    return {
      /** @type {JWebPartImage} */
      propsWebPart: this.webPart,
    };
  },
  methods: {
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    toDownloadLink(isMobile,imageFileUrl){
      return apiServerUtil.getPicLinkOnePage(isMobile,imageFileUrl);
    }
  },
  mounted() {
    this.loadData()
  },
}

</script>

<style scoped>
/*.crop {*/
/*  width: 600px;*/
/*  height: 200px;*/
/*  overflow: hidden;*/
/*  !*     margin: 10px; *!*/
/*  position: relative;*/
/*}*/
/*.crop img {*/
/*  position: absolute;*/
/*  left: -1000%;*/
/*  right: -1000%;*/
/*  top: -1000%;*/
/*  bottom: -1000%;*/
/*  margin: auto;*/
/*  min-height: 100%;*/
/*  min-width: 100%;*/
/*}*/
</style>
