<template>
  <div class="" >
    <b-modal ref="my-modal" hide-footer title="選項">
      <FormFieldDesigner :field="formItem"></FormFieldDesigner>
      <b-button class="mt-2" variant="btn btn-primary" block @click="toggleModal()">儲存</b-button>
      <b-button class="mt-3" variant="btn btn-danger" block @click="hideModal">關閉</b-button>
    </b-modal>
  </div>

</template>

<script>

//use this.$refs.settingSliderImage.showModal();
//use < ref="" :webPart="" v-on:returnOk="blockWebPartSelectorReturnOk">
//import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";

import FormFieldDesigner from "./FormFieldDesigner";
export default {
  components: {FormFieldDesigner},
  props:["formItem"],
  // components: {
  //   HelloWorld
  // }

  data: function() {
    return {
      showThis:false,
    };
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
      this.$emit('closeModel');
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')

      // onePageUtil.webPartHtml_Save(this.areaId,this.blockId,this.formData.content)

      this.$emit('returnOk');
    },
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
