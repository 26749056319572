import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import md5 from 'js-md5';

import jQuery from 'jquery'
import vuetify from '../../plugins/vuetify';
import DatetimePicker from 'vuetify-datetime-picker'
import * as onePageUtil from "@/pages/featureOnePage/util/onePageUtil";
import * as formUtil from "@/pages/featureOnePage/util/formUtil";

import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import $ from 'jquery' //main.js導入(全域)
import SimpleVueValidation from 'simple-vue-validator';
import CKEditor from 'ckeditor4-vue';
// import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'
import vueKeepScroll from 'vue-keep-scroll'
import VueLazyload from 'vue-lazyload'
import vuedragline from 'vue-dragline'

window.jQuery = jQuery
window.$ = jQuery



Vue.config.productionTip = false

// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
Vue.prototype.$md5 = md5;
Vue.prototype.$onePageUtil=onePageUtil
Vue.prototype.$formUtil=formUtil

import * as ubossDeclare from "@/pages/commonUBoss/ubossDeclare";
Vue.prototype.$u=ubossDeclare;

//event bus
Vue.prototype.$bus = new Vue();

Vue.use(SimpleVueValidation);

// Vue.use(Vuetify)

Vue.use(CKEditor);
// Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(DatetimePicker)
Vue.use(vuedragline)

//vue-loading-overlay
// Vue.use(VueLoading); //Use default options
Vue.use(VueLoading, {
  canCancel: true,
  color: '#000000',
  loader: 'dots', //spinner/dots/bars
  width: 50,
  height: 50,
  backgroundColor: '#ffffff',
  isFullPage: true,
  opacity: 0.8
});

Vue.use(vueKeepScroll)

Vue.use(VueLazyload)
// // 或者新增VueLazyload 選項
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error: 'dist/error.png',
//   loading: 'dist/loading.gif',
//   attempt: 1
// })
//---------------------------------------
import ubossCustomize from "@/pages/commonUBossCustomize/ubossCustomize";
Vue.prototype.$c=new ubossCustomize();
//---------------------------------------
import { ValidationObserver,ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { localize } from 'vee-validate';
import zh_TW from 'vee-validate/dist/locale/zh_TW.json';


Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('zh_TW',zh_TW);

localize({
  zh_TW: {
    messages: {
      required: '必填',
      email:'須為有效的電子信箱',
      min: 'this field must have no less than {length} characters',
      max: (_, { length }) => `this field must have no more than ${length} characters`
    }
  }
});

//---------------------------------------
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//---------------------------------------

import vSelect from 'vue-select'


Vue.component('vue-select', vSelect)



//---------------------------------------
// import Cleave from 'vue-cleave-component';
// Vue.use(Cleave)
// Vue.component('Cleave', Cleave)
//---------------------------------------
import VueNumeric from 'vue-numeric'

Vue.use(VueNumeric)
//---------------------------------------
import VueMeta from "vue-meta";
Vue.use(VueMeta)
//---------------------------------------
import VueYouTubeEmbed from 'vue-youtube-embed'
// Vue.use(VueYouTubeEmbed)
Vue.use(VueYouTubeEmbed, { global: true, componentId: "youtube" })
//
// import VueYoutube from 'vue-youtube'
//
// Vue.use(VueYoutube)
//---------------------------------------
import KPop from "@ckienle/k-pop"
import "@ckienle/k-pop/themes/clean.css"
Vue.use(KPop);

//---------------------------------------
import InputColorPicker from "vue-native-color-picker";
Vue.use(InputColorPicker);
//---------------------------------------
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

//---------------------------------------
//Import Froala Editor
import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)
//---------------------------------------
import VueSplide from '@splidejs/vue-splide';

Vue.use( VueSplide );
// //---------------------------------------

//---------------------------------------
//<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
import VueFab from 'vue-float-action-button'
Vue.use(VueFab )
// Vue.use(VueFab,  {
//   iconType: 'iconfont'    //iconfont ,MaterialDesign
// } )

// Vue.use(VueFab, /* {
//   ----------------------
//   // opitons 可选iconfont图标或MaterialIcons
//   iconType: 'MaterialDesign'
//   // iconType: 'iconfont'
// } */)
//---------------------------------------

Vue.filter('fmtDate', function (value) {
  if (!value) return ''

  return value.substr(0,19).replaceAll("T"," ")
})
Vue.filter('fmtTitle', function (value) {
  if (!value) return ''

  if (value.length<25) return value

  return value.substr(0,22) +"..."
})

Vue.directive('uppercase', {
  update (el) {
    el.value = el.value.toUpperCase()
  },
})

Vue.directive('numericOrEmpty', {
  bind(el) {
    el.addEventListener('keyup', () => {
      let regex = /^[0-9]*$/
      if (!regex.test(el.value)) {
        let regex2 = /\s/
        if (!regex2.test(el.value)) {
          el.value = el.value.slice(0, -1)
        }
      }
    })
  }
})

Vue.directive('numericOnly', {
  bind(el) {
    el.addEventListener('keyup', () => {
      let regex = /^[0-9]*$/
      if (!regex.test(el.value)) {
        el.value = el.value.slice(0, -1)
      }
    })
  }
})



// Vue.prototype.COMM_Update_OrderKey = function(id,tablename,orderkey){
//   apiAdmin.COMM_Update_OrderKey(id,tablename,orderkey)
// }
//
//
// Vue.prototype.COMM_Update_Status = function(id,tablename,status){
//   apiAdmin.COMM_Update_Status(id,tablename,status)
// }



new Vue({
  data: function() {
    return {
      data:"aa"
    };
  },
  computed: {
    bodyClass() {
      return this.$route.name;
    }
  },
  router,
  vuetify,
  store,
  render: h => h(App),
  created: function() {
  },
  mounted () {
      if (this.$route.name==="Index"){
        document.body.classList.add('path-front')
      }else{
        document.body.classList.add('path-content')
      }

  },
}).$mount('#wrapper')


// app.use(Cleave)
