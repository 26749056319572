import AxiosManager from "@/pages/common/axiosLib/axiosManager";

export default class ubossCrudApiManager {

    constructor(apiUrl,apiPathBase,methodPrefix, axiosManager,tokenCookieKey) {
        this._apiUrl = apiUrl;
        this._apiPathBase = apiPathBase;
        this._methodPrefix = methodPrefix;
        this._axiosManager=axiosManager;
        this._tokenCookieKey = tokenCookieKey;

        if (!(this._axiosManager) && !(this._tokenCookieKey)){
            alert('must assign once(ubossCrudApiManager)');
        }
    }

    getAxiosManager(){
        if (this._axiosManager==null){
            return new AxiosManager(this._tokenCookieKey)
        }else{
            return this._axiosManager;
        }
    }

    async api_ListQuery ( queryObject)
    {
        var apiPath=this._apiPathBase +this._methodPrefix +'_ListQuery';
        var data=queryObject;

        var dataReturn=  await this.getAxiosManager().post_form(this._apiUrl +apiPath,data)
        return dataReturn;
    }
    async api_Get ( systemId)
    {
        var apiPath=this._apiPathBase +this._methodPrefix +'_Get';
        var data={
            systemId:systemId,
        };

        var dataReturn=  await this.getAxiosManager().post_json(this._apiUrl +apiPath,data)
        return dataReturn;
    }

    async api_Save ( record)
    {
        var apiPath=this._apiPathBase +this._methodPrefix +'_Save';
        var data=record;

        var dataReturn=  await this.getAxiosManager().post_form(this._apiUrl +apiPath,data)
        return dataReturn;
    }

    async api_Remove ( systemId)
    {
        var apiPath=this._apiPathBase +this._methodPrefix +'_Remove';
        var data={
            systemId:systemId,
        };

        var dataReturn=  await this.getAxiosManager().post_form(this._apiUrl +apiPath,data)
        return dataReturn;
    }

    async api_RemoveMulti ( systemIds)
    {
        var apiPath=this._apiPathBase +this._methodPrefix +'_RemoveMulti';
        var data={
            systemIds:systemIds,
        };

        var dataReturn=  await this.getAxiosManager().post_form(this._apiUrl +apiPath,data)
        return dataReturn;
    }
    //---------------------------------------

    async api_CallMethod_SendObj (methodName, objToSend)
    {
        var apiPath=this._apiPathBase +methodName;
        var data=objToSend;

        var dataReturn=  await this.getAxiosManager().post_form(this._apiUrl +apiPath,data)
        return dataReturn;
    }

    async api_CallMethod_ByParamObj (methodName,paramObject)
    {
        var apiPath=this._apiPathBase +methodName;
        var data=paramObject

        var dataReturn=  await this.getAxiosManager().post_json(this._apiUrl +apiPath,data)
        return dataReturn;
    }

    async api_CallMethod_BySystemId (methodName,systemId)
    {
        var apiPath=this._apiPathBase +methodName;
        var data= {
            systemId:systemId
        }

        var dataReturn=  await this.getAxiosManager().post_json(this._apiUrl +apiPath,data)
        return dataReturn;
    }

    async api_CallMethod(methodName)
    {
        var apiPath=this._apiPathBase +methodName;
        var data= {
        }

        var dataReturn=  await this.getAxiosManager().post_json(this._apiUrl +apiPath,data)
        return dataReturn;
    }

    async api_UpdateFile (methodName, files, paramObject)
    {
        var apiPath=this._apiPathBase +methodName;
        var data=paramObject

        var dataReturn=  await this.getAxiosManager().post_files(this._apiUrl +apiPath,files,data)
        return dataReturn;
    }
}
