<template>

  <div class="" style="">

    <div v-if="timeType===this.$onePageUtil.OnePage_TimeType_Design">
        <div class="container MyNavSecondBar" style="background-color: #8fd19e;">
          <div class="row" style="">
            <a class="btn btn-primary" @click="save"><i class="fa fa-save"></i>儲存</a>
            <a class="btn btn-primary" @click="preview"><i class="fa fa-eye"></i>預覽</a>
<!--            <a class="btn btn-primary" @click="commonSetting"><i class="fa fa-cog"></i> 設定</a>-->
<!--            <a class="btn btn-primary" v-if="isFormMode" @click="shareForm"><i class="fa fa-share-alt"></i> 分享</a>-->
          </div>
        </div>
<!--        <CommonSetting ref="commonSetting" :common="OnePage.Common" v-if="OnePage.Common"></CommonSetting>-->
      <ShareForm ref="shareForm" :page-id="this.pageId"></ShareForm>
        <!--      <a class="btn btn-primary"  @click="show_selectorWebPart()">元件類型</a>-->
        <!--      <a class="btn btn-primary"  @click="show_settingWebPart()" v-if="webPartType">設定</a>-->

        <section class="section-intro" >
          <div class="container" >
            <div class="row" >
              <div class="col-md-12 anchorAdjust">
                <div class="btn-toolbar pull-right">

                  <a class="btn btn-primary" @click="addImage('')"><i class="fa fa-plus-circle"></i>圖片</a>
                  <a class="btn btn-primary" @click="addHtml('')"><i class="fa fa-plus-circle"></i>文字</a>
                  <!--                    <a class="btn btn-primary" @click="addSliderImage(webPart.Id)"><i class="fa fa-plus-circle"></i>廣告區</a>-->
                  <div class="btn-group" role="group">
                    <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      廣告區
                    </button>
                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                      <a class="dropdown-item" @click="addSliderImage('')">大圖輪播</a>
                      <a class="dropdown-item" @click="addSliderImage2('')">小圖並列</a>
                      <!--                        <a class="dropdown-item" @click="addSliderImage3(webPart.Id)">小圖並列</a>-->
                    </div>
                  </div>
                  <a class="btn btn-primary" @click="addVideo('')"><i class="fa fa-plus-circle"></i>影片</a>
                  <!--                    <div class="dropdown">-->
                  <!--                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
                  <!--                        表單-->
                  <!--                      </button>-->
                  <!--                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">-->
                  <!--                        <a class="dropdown-item" href="#">2021-05 報名表</a>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                    //UU-->
                  <!--                    <a class="btn btn-primary" v-if="!isFormMode" @click="addUFormLink(webPart.Id)"><i class="fa fa-plus-circle"></i>表單</a>-->
                  <a class="btn btn-primary" v-if="!IsProd()" @click="addUForm('')"><i class="fa fa-plus-circle"></i>表單</a>
                  <!--                    <a class="btn btn-primary" v-if="!IsProd()" @click="addUFormShop(webPart.Id)"><i class="fa fa-plus-circle"></i>購買</a>-->
                  <!--                    <a class="btn btn-primary" v-if="!IsProd()" @click="addListData(webPart.Id)"><i class="fa fa-plus-circle"></i>資料</a>-->
                  <!--                    <a class="btn btn-primary" v-if="!IsProd()" @click="addPricePlan(webPart.Id)"><i class="fa fa-plus-circle"></i>面板</a>-->
                  <a class="btn btn-primary" v-if="!IsProd()" @click="addCallToAction('')"><i class="fa fa-plus-circle"></i>與我連繫</a>
                  <a class="btn btn-primary" v-if="!IsProd()" @click="addHR('')"><i class="fa fa-plus-circle"></i>分隔</a>
                </div>
              </div>
              </div>
            </div>
        </section>
        <section class="section-intro" >
          <div class="container" >
            <div :class="sectionClass" class="row" v-for="(webPart,index) in WebParts" :key="index">
              <div class="col-md-12 anchorAdjust">
                <span :id="webPart.Id"></span>
                <h3>{{blockTitle(webPart)}}</h3>
                <BlockDesigner :design-time="designTime" :web-part="webPart"></BlockDesigner>


                <div class="" style="">
                  <!--      <a class="btn btn-primary"  @click="show_selectorWebPart()">元件類型</a>-->
                  <!--      <a class="btn btn-primary"  @click="show_settingWebPart()" v-if="webPartType">設定</a>-->

                  <div class="btn-toolbar pull-right">
                    <a class="btn btn-danger" v-if="canRemove(webPart)" @click="remove(webPart.Id)"><i class="fa fa-trash"></i></a>
                    <a class="btn btn-primary" @click="moveUp(webPart.Id)"><i class="fa fa-angle-up"></i> </a>
                    <a class="btn btn-primary" @click="moveDown(webPart.Id)"><i class="fa fa-angle-down"></i> </a>

                  </div>
                </div>

                <hr>
              </div><!-- col // -->
            </div> <!-- row.// -->
          </div> <!-- container.// -->
        </section>

    </div>
    <div v-else-if="timeType===this.$onePageUtil.OnePage_TimeType_Run">
        <section class="section-intro" style="">
          <div class="container noMarginNoPadding" style="">

            <div :class="sectionClass" class="row noMargin" v-for="(webPart,index) in WebParts" :key="index">
              <div class="col-md-12 noMargin anchorAdjust" >
                <span :id="webPart.Id"></span>
                <BlockRunner :design-time="designTime" :web-part="webPart" :page-id="pageId" ></BlockRunner>
              </div><!-- col // -->
            </div> <!-- row.// -->

          </div> <!-- container.// -->
        </section>

    </div>
    <div v-else>

    </div>



  </div>

</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'
//
import BlockDesigner from "./BlockDesigner.vue";
import BlockRunner from "./BlockRunner.vue";
import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";
import DesignTimeSupport from "@/pages/featureOnePage/base/DesignTimeSupport.vue";
import ShareForm from "./ShareForm.vue"
import ubossCommon from "@/pages/commonUBoss/ubossCommon";

export default {
  name: 'BlockArea',
  components: { BlockDesigner,BlockRunner,ShareForm},
  props:["areaId","WebParts","pageMode","pageId"],
  // components: {
  //   HelloWorld
  // }
  mixins: [DesignTimeSupport],

  data: function() {
    return {
      sectionClass:"",
      isFormMode:false,
    };
  },
  computed:{

  },
  methods: {
    async save(){
      this.$emit('Save')
    },
    preview(){
      this.$emit('Preview')
    },
    commonSetting(){
      this.$refs.commonSetting.showModal();
    },
    blockTitle(webPart){
      return onePageUtil.getWebPartTypeText(webPart.WebPartType)
    },
    async loadData(){
      this.ensureOneWebPart()

      if (this.pageMode===this.$u.UBossPageMode_OnePage){
        this.sectionClass="";
        this.isFormMode=false;
      }else if (this.pageMode===this.$u.UBossPageMode_Form){
        this.sectionClass="card";
        this.isFormMode=true;
      }

    },
    addImage(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_Image);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)

    },
    addHtml(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_Html);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)

    },
    addSliderImage(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_SliderImage);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)

    },
    addSliderImage2(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_SliderImage2);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)

    },
    addSliderImage3(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_SliderImage3);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)

    },
    addUFormLink(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_FormLink);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    addUForm(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_Form);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    addVideo(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_Video);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    addUFormSubmit(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_FormSubmit);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    addUFormShop(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_FormShop);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    addListData(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_ListData);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    addFormShopWithProduct(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_FormShopWithProduct);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    addFormShopWithProductCalendar(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_FormShopWithProductCalendar);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    addHR(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_HR);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    addPricePlan(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_PricePlan);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    addHotProducts(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_HotProducts);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    addCallToAction(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_CallToAction);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    addCard(id){
      let newWebPart=onePageUtil.NewOnePage_GetAdd("","",this.$onePageUtil.WebPartType_Card);
      onePageUtil.NewOnePage_Push(id,newWebPart,this.WebParts)
    },
    remove(id){
      this.WebParts=onePageUtil.NewOnePage_Delete(id,this.WebParts)
      this.ensureOneWebPart()
    },
    moveUp(id){
      // this.WebParts
      onePageUtil.NewOnePage_MoveUp(id,this.WebParts);
      // arrayUtil.orderMove(false,id,this.WebParts,"Id","OrderKey")
    },
    moveDown(id){
      onePageUtil.NewOnePage_MoveDown(id,this.WebParts);
      // arrayUtil.orderMove(true,id,this.WebParts,"Id","OrderKey")
    },
    ensureOneWebPart(){
      if (!this.WebParts){  //空字串 or null
        this.WebParts=[]
      }
      if (this.WebParts.length===0){
        this.addImage("")
      }
    },
    canRemove(webPart){
      if (webPart.WebPartType===this.$onePageUtil.WebPartType_FormSubmit){
        //表單的送出區塊一定要存在，不允許刪除
        return false;
      }else{
        return true;
      }
    },
    shareForm(){
      this.$refs.shareForm.showModal();
    },
    IsProd(){
      return ubossCommon.IsAppProduction();
    }
  },
  mounted() {


    this.loadData()
  },
}


</script>

<style scoped>
.btn{
  color: white !important;
  margin: 5px;
  /*width: 100px;*/
}
.btnAddWebPart{
  color: white !important;
  margin: 5px;
}

.noMargin{
  margin: 0px !important;
  /*org*/
  padding: 0px !important;
  /*padding-top: 20px !important;*/
  /*padding: 15px !important;*/
}
.noMarginTop{
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.noMarginNoPadding{
  margin-top: 0px !important;
  padding: 0px !important;
}




.anchorAdjust{
  position:relative;
}
.anchorAdjust span{
  position:absolute; top:-60px;
}

.MyNavSecondBar{
  /*top: 52px; !*scrollmenu_height*!*/
}
</style>
