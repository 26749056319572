<template>
<!--  QQ CSS Hard code-->
  <div :style="cssVars" >
    <div class="d-block d-sm-none">
      <!--    mobile-->
      <div class="header scrollmenu hidden-md-up" :class="style.classApply">
<!--       ------------------------------------- -->
        <span v-if="isDesignTime()">
          <a class="btn btn-primary" @click="headerSetting"><i class="fa fa-cog"></i> </a>
        </span>
        <span v-if="propsHeader.LogoUrl">
          <a :href="webPartUrlFirst()" ><img :src="toDownloadLink(propsHeader.LogoUrl)" style="height: 100%"/></a>
        </span>
        <span v-for="(webPart,index) in this.WebParts" :key="'p'+index">
          <span v-if="webPart.Title">
            <a class="scrollMenuLink"  :href="webPartUrl(webPart)" >{{webPart.Title}}</a>
            <span v-if="isDesignTime()">
              <a class="btn btn-primary" @click="moveLeft(webPart)" style="padding: 1px;margin:1px"><i class="fa fa-arrow-left"></i>  </a>
              <a class="btn btn-primary" @click="moveRight(webPart)" style="padding: 1px;margin:1px"><i class="fa fa-arrow-right"></i>  </a>
            </span>
            <span class="scrollmenuDiv">|</span>
          </span>
        </span>
        <span v-for="(item,index) in propsHeader.MenuLinks" :key="index">
          <a class="scrollMenuLink"   :href="item.LinkUrl" :target="item.LinkTarget">{{item.LinkText}}</a>
          <span class="scrollmenuDiv">|</span>
        </span>
        <span v-for="(item,index) in getAdvPages()" :key="index">
          <a class="scrollMenuLink" href="#"  @click="gotoPage(item.SystemId)">{{item.Name}}</a>
          <span class="scrollmenuDiv">|</span>
        </span>
        <!--       ------------------------------------- -->

      </div>

    </div>

    <div class="d-none d-sm-block ">
      <!--    desktop-->
      <div class="header scrollmenu d-flex justify-content-center hidden-md-down" :class="style.classApply">
        <!--       ------------------------------------- -->
        <div class="headerAlignControl">
          <span class="headerAlignLogo">
            <span v-if="isDesignTime()">
              <a class="btn btn-primary" @click="headerSetting"><i class="fa fa-cog"></i> </a>
            </span>
            <span v-if="propsHeader.LogoUrl">
              <a :href="webPartUrlFirst()" ><img :src="toDownloadLink(propsHeader.LogoUrl)" style="height: 100%"/></a>
            </span>
          </span>
          <span class="headerAlignMenu">
            <span v-for="(webPart,index) in this.WebParts" :key="'p'+index">
              <span v-if="webPart.Title">
                <a class="scrollMenuLink"  :href="webPartUrl(webPart)" >{{webPart.Title}}</a>
                <span v-if="isDesignTime()">
                  <a class="btn btn-primary" @click="moveLeft(webPart)" style="padding: 1px;margin:1px"><i class="fa fa-arrow-left"></i>  </a>
                  <a class="btn btn-primary" @click="moveRight(webPart)" style="padding: 1px;margin:1px"><i class="fa fa-arrow-right"></i>  </a>
                </span>
                <span class="scrollmenuDiv">|</span>
              </span>
            </span>
            <span v-for="(item,index) in propsHeader.MenuLinks" :key="index">
              <a class="scrollMenuLink"   :href="item.LinkUrl" :target="item.LinkTarget">{{item.LinkText}}</a>
              <span class="scrollmenuDiv">|</span>
            </span>

            <span v-if="this.propsOnePageAdvCname">
              <select class="form-control" style="display:inline" v-model="PageId" @change="gotoPage(PageId)">
                <option v-for="(item,index) in getAdvPages()" v-bind:key="index" :value="item.SystemId">{{ item.Name }}</option>
              </select>
            </span>
<!--            <span v-for="(item,index) in getAdvPages()" :key="index">-->
<!--              <a class="scrollMenuLink" href="#"  @click="gotoPage(item.SystemId)">{{item.Name}}</a>-->
<!--              <span class="scrollmenuDiv">|</span>-->
<!--            </span>-->
          </span>
        </div>
        <!--       ------------------------------------- -->

      </div>
    </div>

    <COM_Header_Setting ref="headerSetting" :Header="Header" :common="Common" v-if="Header && Common"></COM_Header_Setting>


  </div>
</template>

<script>
import session from "../../common/session";
import COM_Header_Setting from "./COM_Header_Setting.vue";
import DesignTimeSupport from "@/pages/featureOnePage/base/DesignTimeSupport.vue";
import arrayUtil from "@/pages/common/arrayUtil";
import apiServerUtil from "@/pages/common/apiServerUtil";
import ubossHostManager from "@/pages/commonUBoss/ubossHostManager";

export default {
  name: 'COM_Header',
  components: {COM_Header_Setting},
  props: {
    Header: {},
    Common: {},
    WebParts:Array,
    OnePageAdv:{},
    OnePageAdvCname:{},
    PageId:String,
    type:String,//type 1 onePage   2 shopCart 3 onePagePro
  },
  mixins: [DesignTimeSupport],
  data: function() {
    return {
      /** @type {JOnePageHeader} */
      propsHeader:this.Header,
      /** @type {JOnePageCommon} */
      propsCommon:this.Common,
      /** @type {JOnePageAdv_RecordSimples} */
      propsOnePageAdv:this.OnePageAdv,
      propsOnePageAdvCname:this.OnePageAdvCname,
      showDialogLogin: false,
      sectionData:{},
      cssStyle:{
        FontColor: '',
        BackgroundColor: '',
        BackgroundImageUrl: '',
        LogoAlignWidth:"",
        LogoAlignMaxWidth:"",
        MenuAlignFloat:"",
      },
      style:{
        classApply:"",
      }
    };
  },
  computed: {
    cssVars() {



      return {
        '--fontColor': this.cssStyle.FontColor ,
        '--bgColor': this.cssStyle.BackgroundColor ,
        '--bgImageUrl': this.cssStyle.BackgroundImageUrl ,
        '--headerAlignControlWidth': this.cssStyle.HeaderAlignControlWidth ,
        '--logoAlignWidth': this.cssStyle.LogoAlignWidth ,
        '--logoAlignMaxWidth': this.cssStyle.LogoAlignMaxWidth ,
        '--menuAlignFloat': this.cssStyle.MenuAlignFloat ,
      }
    },

  },
  watch:{
    Header(value){
      this.applyHeaderStyle(value)
    },
  },
  methods: {
    isDesignTime(){
      return this.timeType=== this.$onePageUtil.OnePage_TimeType_Design;
    },
    test:function(){
    },
    showLogin: function () {
      this.showDialogLogin = true;
    },
    isLogined: function() {
      return session.isLogined();
    },
    goto_shopCart(){
      this.$router.push({ name: 'ShoppingCart', query: { ID: 0 }})
    },
    headerSetting(){
      this.$refs.headerSetting.showModal();
    },
    moveLeft(item){
      arrayUtil.orderMove(false,item.Id,this.WebParts,"Id","OrderKey")
    },
    moveRight(item){
      arrayUtil.orderMove(true,item.Id,this.WebParts,"Id","OrderKey")
    },
    webPartUrl(webPart){
      if (this.type===this.$u.UBossOnePageHeaderType_OnePage || this.type===this.$u.UBossOnePageHeaderType_OnePagePro){
        //onepage頁面上點 link
        return '#'+webPart.Id;
      }else if (this.type===this.$u.UBossOnePageHeaderType_ShopCart){
        //shop頁面上點 link
        let ubossHost=new ubossHostManager();
        let url=ubossHost.bizOnePageUrlWithSubDomain();
        return url + '/#'+webPart.Id;
      }
    },
    webPartUrlFirst(){
      if (this.WebParts.length>0){
        return '#'+this.WebParts[0].Id;
      }
    },
    async loadData(){
      if (this.type===this.$u.UBossOnePageHeaderType_OnePage){
        this.style.classApply="fixed-top";
      }else if (this.type===this.$u.UBossOnePageHeaderType_ShopCart){
        this.style.classApply="fixed-top";
      }else if (this.type===this.$u.UBossOnePageHeaderType_OnePagePro){//PRO
        this.style.classApply="";
      }

      if (this.isDesignTime()){
          //不要fix top ,因為此時往往最上面有toolbar了(例biz)
          this.style.classApply="";
      }

      this.applyHeaderStyle(this.propsHeader);
    },
    toDownloadLink(imageFileUrl){
      return apiServerUtil.toDownloadLink(imageFileUrl);
    },
    applyHeaderStyle(header){
      this.cssStyle.FontColor=header.FontColor;
      this.cssStyle.BackgroundColor=header.BackgroundColor;
      this.cssStyle.BackgroundImageUrl=header.BackgroundImageUrl;

      let headerAlignControlWidth="";
      let logoAlignWidth="";
      let logoAlignMaxWidth="";
      let menuAlignFloat="";

      if (header.LogoAlign==="left"){
        headerAlignControlWidth="1140px"
        logoAlignWidth="1140px";
        logoAlignMaxWidth="auto";
      }else if (header.LogoAlign==="center"){
        headerAlignControlWidth="auto";
        logoAlignWidth="auto";
        logoAlignMaxWidth="1140px";
      }else{
        headerAlignControlWidth="auto";
        logoAlignWidth="1140px";
        logoAlignMaxWidth="auto";
      }

      if (header.MenuAlign==="left"){
        menuAlignFloat="center";
      }else if (header.MenuAlign==="right"){
        menuAlignFloat="right";
      }else{
        menuAlignFloat="center";
      }



      this.cssStyle.HeaderAlignControlWidth=headerAlignControlWidth;
      this.cssStyle.LogoAlignWidth=logoAlignWidth;
      this.cssStyle.LogoAlignMaxWidth=logoAlignMaxWidth;
      this.cssStyle.MenuAlignFloat=menuAlignFloat;
    },
    gotoPage(id){
      this.PageId=id;
      this.$emit('changePage',id)
      // this.$router.push({name: 'Index', query: {id: id}})
    },
    getAdvPages(){
      if (this.propsOnePageAdvCname){
        //filter by isVisible

        return this.propsOnePageAdvCname.Pages.filter(s=>s.IsVisible);
      }
    }
  },
  mounted() {
    this.loadData()

  }
}
</script>

<style scoped>
.header{
  background-image: var(--bgImageUrl);
  /*height: 100px;*/
  background-repeat:no-repeat;
  background-position: center;
  /*background-size:contain;*/
}
/*.navOnePage{*/
/*  background-color:#a044ff !important;*/
/*  color: white;*/
/*  height: 70px;*/
/*  font-size: large;*/
/*}*/

.scrollmenu {

  background-color: var(--bgColor);
  overflow: auto;
  white-space: nowrap;
  padding: 5px;
  /*//關聯修改 fixtop*/
  height: 52px; /*scrollmenu_height*/
}

/*.scrollmenu a:not (.notApplyScrollMenuCSS) {*/
.scrollMenuLink {
  /*background-color: #a044ff !important;*/
  display: inline-block;
  color: var(--fontColor);
  text-align: center;
  /*padding: 14px;*/
  text-decoration: none;
  /*border-left: 2px solid #a044ff;*/
  /*border-right: 2px solid white;*/
  font-size: 15px;
  padding-left:  10px;
  padding-right:  10px;
  margin: 10px;
}

.scrollMenuLink:hover {
  background-color: #777;

}
.scrollmenuDiv{
  color: white;
}

.headerAlignControl {
  width: var(--headerAlignControlWidth);
  max-width:1140px;
  margin-right:auto;
  margin-left:auto
}
.headerAlignLogo{
  width:var(--logoAlignWidth);
  max-width:var(--logoAlignMaxWidth);
}
.headerAlignMenu{
  float:var(--menuAlignFloat);
}
</style>
