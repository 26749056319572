<template>
  <div class="" >


    <span  v-for="(image,index) in myWebPart.PartImages" :key="index">
      <div class="designerContainer hover12">
        <figure>
        <!--        Image Loader相同-->
        <img v-lazy="toDownloadLink(false,image.ImageUrl)" style="width: 100%">
        </figure>

        <div class="toolbar" style="">
          <FileUploadButton :emmit-back-param="image" v-on:uploadSuccess="uploadSuccess" :upload-type="$u.UBossUploadType_OnePage"></FileUploadButton>
          <!--          <a class="btn btn-primary" @click="upload(propsWebPart.Id)"><i class="fa fa-upload"></i></a>-->
          <a class="btn btn-danger" @click="remove(image.ImageUrl)"><i class="fa fa-trash"></i></a>
          <a class="btn btn-primary" @click="moveUp(image.ImageUrl)"><i class="fa fa-angle-up"></i> </a>
          <a class="btn btn-primary" @click="moveDown(image.ImageUrl)"><i class="fa fa-angle-down"></i> </a>
          <input type="text" class="form-control" placeholder="更換間隔(秒)預設5秒" v-model="image.Interval">
        </div>

      </div>
    </span>


    <!--    新增-->
    <span>
      <div class="designerContainer hover12">
        <figure>
        <!--        Image Loader相同-->
        <img src="/ubossOnePage/_imageOnePage/1024x700.png" style="width: 100%">
        </figure>

        <div class="toolbar" style="">
          <FileUploadButton :emmit-back-param="undefined" v-on:uploadSuccess="uploadSuccess" :upload-type="$u.UBossUploadType_OnePage"></FileUploadButton>
        </div>

      </div>
    </span>




  </div>

</template>

<script>
import util from "@/pages/common/util";
import FileUploadButton from "@/pages/commonComponent/FileUploadButton";
import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";
import apiServerUtil from "@/pages/common/apiServerUtil";

export default {
  components: {FileUploadButton},
  props: {
    webPart: {},
  },
  data: function() {
    return {
      /** @type {JWebPartSliderImage} */
      propsWebPart:this.webPart,
    };
  },
  computed:{
    myWebPart() {
      return this.webPart;
    },
  },
  methods: {
    uploadSuccess(image,result){
      //result
      //  count
      //  size
      //  newFileNames
      //  fileUrls
      if (result && result.FileCount>0){
        let fileUrls=result.FileUrls

        if (image){
          image.ImageUrl=fileUrls[0]
        }else{
          let imageNew={
            ImageName:"",
            ImageUrl:"",
            OrderKey:"",
            Interval:"",
          }
          imageNew.ImageUrl=fileUrls[0]

          if (!this.propsWebPart.PartImages){
            this.propsWebPart.PartImages=[]
            this.propsWebPart.PartImages.push(imageNew)
          }else{
            this.propsWebPart.PartImages.push(imageNew)
          }
          this.$forceUpdate()

        }
      }else{
        util.ShowMessage("檔案上傳失敗")
      }



    },
    remove(id){
      this.propsWebPart.PartImages=onePageUtil.NewOnePage_ImageDelete(id,this.propsWebPart.PartImages)
    },
    moveUp(id){
      onePageUtil.NewOnePage_ImageMoveUp(id,this.propsWebPart.PartImages);
    },
    moveDown(id){
      onePageUtil.NewOnePage_ImageMoveDown(id,this.propsWebPart.PartImages);
    },
    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    toDownloadLink(isMobile,imageFileUrl){
      return apiServerUtil.getPicLinkOnePage(isMobile,imageFileUrl);
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>
<style scoped>
.designerContainer {
  position: relative !important;
  text-align: center;
  color: white;
}

.toolbar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn{
  color: white !important;
  margin: 5px;
  width: 40px;
}


/* Opacity #2 */
.hover12 figure {
  background: #1abc9c;
}
.hover12 figure img {
  opacity: 1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
.hover12 figure:hover img {
  opacity: .5;
}
</style>
