export class JWebPartForm
{
    Id="";
    Title="";
    WebPartType="form";
    OrderKey="";

    DescText="";
    Desc="";
    Fields=[];//JOnePageFormField
}

export class JWebPartFormLink
{
    Id="";
    Title="";
    WebPartType="formLink";
    OrderKey="";

    FormLink="";
    PartHtmlContent="";
}


export class JWebPartFormSelector
{
    Id="";
    Title="";
    WebPartType="formLink";
    OrderKey="";

    RelFormSystemId="";
}

export class JWebPartFormShop
{
    Id="";
    Title="";
    WebPartType="formShop";
    OrderKey="";


    DescText="";
    Desc="";
    ShopItems=[];//ShopItem
}

export class ShopItem
{
    Id="";
    Text="";
    Price= 0;
    OrderKey="";
}

export class JWebPartFormSubmit
{
    Id="";
    Title="";
    WebPartType="formSubmit";
    OrderKey="";

    // PartHtmlContent="";
}

export class JWebPartHtml
{
    Id="";
    Title="";
    WebPartType="html";
    OrderKey="";

    PartHtmlContent="";
}

export class JWebPartImage
{
    Id="";
    Title="";
    WebPartType="image";
    OrderKey="";

    PartImages=[];//JImage
}

export class JWebPartSliderImage
{
    Id="";
    Title="";
    WebPartType="sliderImage";
    OrderKey="";

    PartImages=[];//JSliderImage
}


export class JWebPartSliderImage2
{
    Id="";
    Title="";
    WebPartType="sliderImage";
    OrderKey="";

    PartImages=[];//JSliderImage
}

export class JWebPartSliderImage3
{
    Id="";
    Title="";
    WebPartType="sliderImage3";
    OrderKey="";

    PartImages=[];//JSliderImage3
}


export class JWebPartVideo
{
    Id="";
    Title="";
    WebPartType="video";
    OrderKey="";

    VideoLink="";
    VideoId="";
}


export class JWebPartCard
{
    Id="";
    PerRowCardNum=1;
    Cards=[];
}

export class JWebPartCardItem
{
    Id="";
    ImageUrl="";
    ImageHref="";
    Title="";
    TitleHref="";
    Text="";
    TextHref="";
    OrderKey="";

}
//---------------------------------------
export class JHref
{
    Href="";
    Target="";
}

export class JImage
{
    ImageName="";
    ImageUrl="";
    OrderKey="";
    LinkUrl="";
    LinkTarget="";
}

export class JSliderImage
{
    ImageName="";
    ImageUrl="";
    OrderKey="";
    LinkUrl="";
    LinkTarget="";

    Interval="";
}



export class JSliderImage3
{
    ImageName="";
    ImageUrl="";
    OrderKey="";
    LinkUrl="";
    LinkTarget="";

    Interval="";
}



