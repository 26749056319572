/**
 import ubossHostManager from "@/pages/commonUBoss/ubossHostManager";

 let ubossHost=new ubossHostManager();
 let url=ubossHost.bizOnePageUrl();
 */

import ubossCommon from "@/pages/commonUBoss/ubossCommon";

export default class ubossHostManager {

    constructor() {
        this.baseHost="";
        this.isUbossDomain=true;

        let index=-1;

        let host=window.location.host;

        host=host.toLowerCase();

        index = host.indexOf("uboss.cc");
        if (index > -1){
            this.baseHost="uboss.cc";
        }
        index = host.indexOf("uboss.loc");
        if (index > -1){
            this.baseHost="uboss.loc";
        }
        index = host.indexOf("taylor.tw");
        if (index > -1){
            this.baseHost="taylor.tw";
        }

        //-------------測試完成後，這段移除--------------------------
        index = host.indexOf("shouldbio.taipei");
        if (index > -1){
            this.baseHost="shouldbio.taipei";
            this.isUbossDomain=false;
        }

        index = host.indexOf("shouldbio.uboss.loc");
        if (index > -1){
            this.baseHost="shouldbio.taipei";
            this.isUbossDomain=false;
        }

        index = host.indexOf("goodjob.biz");
        if (index > -1){
            this.baseHost="goodjob.biz";
            this.isUbossDomain=false;
        }

        index = host.indexOf("goodjob.uboss.loc");
        if (index > -1){
            this.baseHost="goodjob.biz";
            this.isUbossDomain=false;
        }


        index = host.indexOf("bodytalk.tw");
        if (index > -1){
            this.baseHost="bodytalk.tw";
            this.isUbossDomain=false;
        }

        index = host.indexOf("bodytalk.uboss.loc");
        if (index > -1){
            this.baseHost="bodytalk.tw";
            this.isUbossDomain=false;
        }
        //-------------測試完成後，這段移除--------------------------


        index = host.indexOf("localhost");
        if (index > -1){
            this.baseHost="localhost不支援此功能";
        }

        index = host.indexOf("127.0.0.1");
        if (index > -1){
            this.baseHost="localhost不支援此功能";
        }
    }

    getHttpType(){
        if (this.baseHost==="uboss.loc"){
            return "http://";
        }else{
            return "https://";
        }
    }

    admUrl(){
        return this.getHttpType() + "adm."+ this.baseHost
    }

    bizUrl(){
        return this.getHttpType() + "biz."+ this.baseHost
    }

    formUrl(){
        return this.getHttpType() + "form."+ this.baseHost
    }

    formShowFormUrl(formId){
        return this.getHttpType() + "form."+ this.baseHost + "/form/indexForm?id=" + formId
    }

    formShowFormUrlWithDesignMode(formId){
        return this.getHttpType() + "form."+ this.baseHost + "/form/indexForm?s=Sys5NEx5TmxBazZIbjhHRjBOUkdmdz09&id=" + formId
    }

    bizOnePageUrl(){
        if (this.isUbossDomain){
            return this.getHttpType() + "biz."+ this.baseHost + "/onepage/"
        }else{
            return this.getHttpType() + this.baseHost + "/"
        }
    }

    bizOnePageUrlWithSubDomain(){
        let url=this.bizOnePageUrl();

        return url + ubossCommon.getSubDomain();
    }

    bizOnePageShowPageUrl(onePageId){
        if (this.isUbossDomain){
            return this.getHttpType() + "biz."+ this.baseHost + "/onepage/?id=" + onePageId
        }else{
            return this.getHttpType() + this.baseHost + "/onepage/?id=" + onePageId
        }
    }

    bizShopUrl(){
        if (this.isUbossDomain){
            return this.getHttpType() + "biz."+ this.baseHost + "/shop/"
        }else{
            return this.getHttpType() + this.baseHost + "/shop/"
        }
    }


    bizShopUrlWithSubDomain(){
        let url=this.bizShopUrl();

        return url + ubossCommon.getSubDomain();
    }
}