import axios from "axios";
import session from "@/pages/common/session";

export default class AxiosManager {

    constructor(tokenCookieKey) {
        this._tokenCookieKey = tokenCookieKey;
    }

    async get (url, data) {
        let res=await this.axios_get(url,data);

        return res.data;
    }

    async post_json (url, data,postAuth) {
        let contentType="application/json; charset=utf-8";

        let formData = new FormData()
        Object.keys(data).forEach(key => {
            formData.append(key, data[key])
        })

        let res=await this.axios_post(url,formData,contentType,postAuth);

        return res.data;
    }

    async post_form (url, data) {
        let contentType="application/json; charset=utf-8";

        let res=await this.axios_post(url,data,contentType);

        return res.data;
    }

    async post_file (url,file, data) {
        // let contentType="";

        let formData = new FormData()
        Object.keys(data).forEach(key => {
            formData.append(key, data[key])
        })

        formData.append("file", file);

        let res=await this.axios_post(url,formData);

        return res.data;
    }

    async post_files (url,files, data) {
        // let contentType="application/x-www-form-urlencoded; charset=utf-8";
        let contentType="multipart/form-data";

        let formData = new FormData()
        Object.keys(data).forEach(key => {
            formData.append(key, data[key])
        })

        for (let file of files) {
            formData.append('files', file);
        }
        // formData.append("files", files);

        let res=await this.axios_post(url,formData,contentType);

        return res.data;
    }

    axios_get (url, data) {
        return new Promise((resolve,reject) => {
            axios.defaults.adapter = require('axios/lib/adapters/http')
            axios.defaults.withCredentials=true

            let axiosConfig={
                headers: {
                    withCredentials: true,
                },
            };

            let token=session.getTokenByKey(this._tokenCookieKey)
            if (token){
                axiosConfig.headers.Authorization="Bearer " +token
            }

            axiosConfig.headers.CC=process.env.VUE_APP_publicPath //    /shop/paypaydrink/ ..
            axiosConfig.headers.ST=process.env.VUE_APP_siteType //ubossShop..
            axiosConfig.headers.HH=location.host

            axios.get(url, {params: data,axiosConfig})
                .then((res)=>{
                    resolve(res)
                }).catch((err)=>{
                reject(err)
            })
        })
    }

    axios_post (url, formData,contentType,postAuth) {
        return new Promise((resolve,reject) => {
            let axiosConfig = undefined;

            axiosConfig={
                headers: {
                    'Content-Type': contentType,
                    // "Access-Control-Allow-Origin": "*",
                    // 'Authorization': "Bearer " + session.getToken(),
                },
            };

            let token=session.getTokenByKey(this._tokenCookieKey)
            if (token){
                if (postAuth ===undefined ){
                    axiosConfig.headers.Authorization="Bearer " +token      //一般用
                    // axiosConfig.headers['X-Token']=token        //asp.net core用
                }else{
                    if (postAuth){
                        //login時，不要帶jwt
                        axiosConfig.headers.Authorization="Bearer " +token//一般用
                        // axiosConfig.headers['X-Token']=token        //asp.net core用
                    }
                }
            }

            axiosConfig.headers.CC=process.env.VUE_APP_publicPath
            axiosConfig.headers.ST=process.env.VUE_APP_siteType
            axiosConfig.headers.HH=location.host

            axios.defaults.adapter = require('axios/lib/adapters/http')
            axios.defaults.withCredentials=true

            axios.post(url, formData,axiosConfig)
                .then((res)=>{
                    resolve(res)
                }).catch((err)=>{
                reject(err)
            })
        })
    }
}

