<template>
  <div>
    <b-button v-b-toggle.collapse-1 variant="primary">背景設定</b-button>
    <b-collapse id="collapse-1" class="mt-2">
      <b-card>
        <div class="form-group row">
          <label for="" class="col-sm-2">圖片</label>
          <div class="col-sm-10">
            <FileUploadButton :emmit-back-param="undefined" v-on:uploadSuccess="uploadSuccessBackground" :upload-type="$u.UBossUploadType_OnePage" ></FileUploadButton>
            <a class="btn btn-danger" @click="clearBackground"><i class="fa fa-trash"></i></a>
          </div>
        </div>
        <div class="form-group row">
          <label for="" class="col-sm-2">顏色</label>
          <div class="col-sm-10">
            <MyColorPicker v-model="Common.BackgroundColor"></MyColorPicker>
          </div>
        </div>
        <div class="form-group row">
          <label for="" class="col-sm-2">對齊</label>
          <div class="col-sm-10">
            <select class="form-control aselect" v-model="Common.BackgroundPosition">
              <option value="center">中</option>
              <option value="top">上</option>
              <option value="left">左</option>
              <option value="right">右</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="" class="col-sm-2">重覆</label>
          <div class="col-sm-10">
            <select class="form-control aselect" v-model="Common.BackgroundRepeat">
              <option value="no-repeat">不重覆</option>
              <option value="repeat">重覆</option>
              <option value="repeat-x">橫向重覆</option>
              <option value="space repeat">直向重覆</option>
            </select>
          </div>
        </div>

      </b-card>
    </b-collapse>
  </div>
</template>

<script>
/**

 **/

import MyColorPicker from "@/pages/commonComponent/MyColorPicker";
import FileUploadButton from "@/pages/commonComponent/FileUploadButton";
import util from "@/pages/common/util";

export default {
  props: ["Common"],
  components:{MyColorPicker,FileUploadButton},
  data: function () {
    return {
      id: "",
      dataReady: false,
      formData: {
        name: "",
      },
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    clearBackground(){
      this.Common.BackgroundImageUrl=""
      util.ShowMessage("已刪除")
    },
    uploadSuccessBackground(image,result){
      if (result && result.FileCount>0){
        this.Common.BackgroundImageUrl=result.FileUrls[0]
      }else{
        util.ShowMessage("檔案上傳失敗")
      }

    },
  },
  mounted() {
    //this.loadData()
  },
}
</script>

<style scoped>

</style>
