<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2">標題</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" placeholder="" v-model="field.Title">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">預設文字</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" placeholder="" v-model="field.DefaultValue">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">必填</label>
      <div class="col-sm-10">
        <label class="checkbox-inline"><input type="checkbox" v-model="field.ValidateRule" value="required">必填</label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  //name: "TextBox",
  props: ["field"],
  data: function () {
    return {
      id: "",
      fieldDemo:{
        Id:"",
        SpecifyId:"",
        Title:"",
        InputType:"",
        Values:"",
        DefaultValue:"",
        ValidateRule:"",
        Visible:"",
        OrderKey:"",
      },
    };
  },
  methods: {
    async loadData() {
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
  },
  mounted() {


    //this.loadData()
  },
}
</script>

<style scoped>

</style>