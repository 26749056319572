<template>
  <div class="">



    選擇商品
    <select class="form-control" id="selItems" name="selItems">
      <option></option>
      <option>1</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
    </select>

    <WebPartFormShopWithProductSetting ref="setting"></WebPartFormShopWithProductSetting>
  </div>

</template>

<script>
import onePageUtil from "@/pages/featureOnePage/util/onePageUtil";
import util from "@/pages/common/util";
import WebPartFormShopWithProductSetting from "./WebPartFormShopWithProductSetting";
import apiServerUtil from "@/pages/common/apiServerUtil";

export default {
  components: { WebPartFormShopWithProductSetting},
  props:["webPart"],
  data: function() {
    return {

    };
  },
  computed:{
    myWebPart() {
      return this.webPart;
    },
  },
  methods: {

    async loadData(){
      // let data=await apiUtil.api_SupplierTODO("12138114-01")

      // this.data=data
    },
    upload(){
      // onePageUtil.NewOnePage_Delete(id)
    },
    uploadSuccess(image,result){
      //result
      //  count
      //  size
      //  newFileNames
      //  fileUrls
      if (result && result.FileCount>0){
        let fileUrls=result.FileUrls

        if (image){
          image.ImageUrl=fileUrls[0]
        }else{
          let imageNew={
            ImageName:"",
            ImageUrl:"",
            OrderKey:"",
            LinkUrl: "",
            LinkTarget:""
          }
          imageNew.ImageUrl=fileUrls[0]

          if (!this.webPart.PartImages){
            // this.webPart.PartImages = Object.assign({}, this.webPart.PartImages, [imageNew])
            this.webPart.PartImages=[]
            // this.$set(this.webPart.PartImages, 0, imageNew)
            this.webPart.PartImages.push(imageNew)
          }else{
            this.webPart.PartImages.push(imageNew)
          }
          this.$forceUpdate()



        }
      }else{
        util.ShowMessage("檔案上傳失敗")
      }



    },
    remove(id){
      this.webPart.PartImages=onePageUtil.NewOnePage_ImageDelete(id,this.webPart.PartImages)
    },
    moveUp(id){
      // this.webParts
      onePageUtil.NewOnePage_ImageMoveUp(id,this.webPart.PartImages);
      // arrayUtil.orderMove(false,id,this.webParts,"Id","OrderKey")
    },
    moveDown(id){
      onePageUtil.NewOnePage_ImageMoveDown(id,this.webPart.PartImages);
      // arrayUtil.orderMove(true,id,this.webParts,"Id","OrderKey")
    },
    setting(partImage){
      this.$refs.setting.partImage=partImage
      this.$refs.setting.showModal();

    },
    toDownloadLink(isMobile,imageFileUrl){
      return apiServerUtil.getPicLinkOnePage(isMobile,imageFileUrl);
    }
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style scoped>
.designerContainer {
  position: relative !important;
  text-align: center;
  color: white;
}

.toolbar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn{
  color: white !important;
  margin: 5px;
  width: 40px;
}


/* Opacity #2 */
.hover12 figure {
  background: #1abc9c;
}
.hover12 figure img {
  opacity: 1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
.hover12 figure:hover img {
  opacity: .5;
}
</style>
